const notificationModule = {
  namespaced: true,
  state: {
    notifications: {},
    id: 0,
  },
  actions: {
    showSuccessNotification({ commit, state }, text) {
      commit('nextId');
      const id = state.id;
      const type = 'success';
      commit('addNotification', { id, type, text });
      setTimeout(() => {
        commit('removeNotification', id);
      }, 4000);
    },
    showErrorNotification({ commit, state }, text) {
      commit('nextId');
      const id = state.id;
      const type = 'error';
      commit('addNotification', { id, type, text });
      setTimeout(() => {
        commit('removeNotification', id);
      }, 4000);
    },
  },
  mutations: {
    addNotification(state, { id, type, text }) {
      const key = Math.random().toString(36).substr(2, 9);
      // Vue.set(state.notifications, id, { type, text, key });
      // this.$set(state.notifications, id, { type, text, key });
      state.notifications[id] = { type, text, key };
    },
    removeNotification(state, id) {
      // Vue.delete(state.notifications, id);
      // this.$delete(state.notifications, id);
      delete state.notifications[id];
    },
    nextId(state) {
      state.id += 1;
    },
  },
};

export default notificationModule;