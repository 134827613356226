import {onDomReady} from "../../components/dynamic/observer";
import {Autoplay, Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    const leftArrow = document.querySelector('[data-index-offer-slider-left]');
    const rightArrow = document.querySelector('[data-index-offer-slider-right]');

    const slider = new Swiper(document.querySelector('[data-index-offer-slider]'), {
        modules: [Navigation, Autoplay],
        slidesPerView: "1",
        speed: 700,
        loop: true,
        autoplay: {
            delay: 5000,
        },
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
    });
}

onDomReady(init);