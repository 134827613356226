<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.045 6.4c.378-.377.586-.88.586-1.413 0-.534-.208-1.036-.586-1.414l-1.586-1.586a1.986 1.986 0 0 0-1.414-.586c-.534 0-1.036.208-1.413.585L3 12.585V17h4.413L18.045 6.4Zm-3-3 1.587 1.586-1.59 1.584-1.586-1.585L15.045 3.4ZM5 15v-1.585l7.04-7.018 1.586 1.586L6.587 15H5Zm-2 4h16v2H3v-2Z" fill="#DADADF"/></svg>
</template>

<script>
  export default {
    name: "EditIcon"
  }
</script>

<style scoped>

</style>