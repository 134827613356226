<template>
  <div class="tinkoff-button-wrap _hidden" data-mask>
<!--    <tinkoff-create-button-->
<!--        size="M"-->
<!--        shopId="shlepakov"-->
<!--        showcaseId="shlepakov"-->
<!--        subtext=""-->
<!--        :payment-data="getPaymentData"-->
<!--    ></tinkoff-create-button>-->
  </div>
</template>

<script>
import '@tcb-web/create-credit/button';
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "TheCartCredit",
  computed: {
    ...mapGetters('cart', {
      'getPaymentData': 'getPaymentData'
    }),
  },
  // Во вью кнопка работает как то непонятно - после инициализации принудительно суем в нее атрибуты с размерами
  mounted() {
    setTimeout(() => {
      const button = document.querySelector('tinkoff-create-button');
      if (button) {
        button.setAttribute('size', 'M');
        button.setAttribute('subtext', '');
        document.querySelector('[data-mask]').classList.remove('_hidden');
      }
    }, 200)
  }
}
</script>

<style>
  .tinkoff-button-wrap{
    margin-top: 10px;
    &._hidden{
      visibility: hidden;
      height: 0;
    }
  }

  tinkoff-create-button{
    width: 100%;
    display: flex;
    justify-content: center;
  }

</style>