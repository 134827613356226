<template>
  <a href="/cart"
     title="Перейти в корзину"
     class="header-bottom__user-item header-bottom__cart"
     data-goal-yandex-click="go_to_the_shopping_cart"
     data-goal-google-click="cart#go"
     data-goal-vk-click="go_to_the_shopping_cart"
  >
      <span class="header-bottom__user-item-icon">
          <CartIcon/>
          <span class="header-bottom__user-item-badge text text__twelve">
            {{ count }}
          </span>
      </span>
      <span class="header-bottom__user-item-text text text__twelve">
          Корзина
      </span>
      <span class="header-bottom__cart-price text text__mini text__mini_medium">
          {{ total }} ₽
      </span>
  </a>
</template>

<script>
import CartIcon from "../icons/CartIcon";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "TheCartWidget",
  components: {
    CartIcon
  },
  methods: {
    ...mapActions({
      'loadCheckout': 'cart/loadCheckout'
    })
  },
  computed: {
    ...mapGetters('cart', {
      'total': 'total',
      'count': 'count'
    })
  },
  mounted() {
    this.loadCheckout();
  }
}
</script>