<template>
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" width="2" height="10" fill="#1D202C"/>
    <rect x="10" y="4" width="2" height="10" transform="rotate(90 10 4)" fill="#1D202C"/>
  </svg>
</template>

<script>
export default {
  name: "PlusIcon"
}
</script>
