import axios from 'axios';

const PriceManualService = {
  async fetchTyres(params) {
    const url = '/price_manual/api/tyre_list';
    return axios.post(url, params);
  },
  async fetchWidths() {
    const url = '/price_manual/api/widths';
    return axios.get(url, {});
  },
  async fetchHeights() {
    const url = '/price_manual/api/heights';
    return axios.get(url, {});
  },
  async fetchDiameters() {
    const url = '/price_manual/api/diameters';
    return axios.get(url, {});
  },
  async fetchSeasons() {
    const url = '/price_manual/api/seasons';
    return axios.get(url, {});
  },
  async fetchProducers() {
    const url = '/price_manual/api/producers';
    return axios.get(url, {});
  },
  async createPrice(data) {
    return axios.post('/price_manual/api/create_price', data);
  },
  async removePrice(data) {
    return axios.post('/price_manual/api/remove_price', data);
  },
};

export default PriceManualService;