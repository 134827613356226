<template>
  <div class="cart-order-block__pickup cart-order-pickup">
    <ItemErrors :errors="errors.pickupPointSelected"/>
    <ul class="cart-order-block__radio-list">
      <li class="cart-order-block__radio-item" v-for="(point) in points">
        <div class="cart-order-block__radio">
          <input
              type="radio"
              name="points"
              v-bind:id="'point-' + point.id"
              :value="point.id"
              :checked="pickupPoint === point.id"
              @click="setPickupPoint(point.id)"
          >
          <label v-bind:for="'point-' + point.id" class="cart-order-block__input-label">
              <span class="cart-order-block__label-title  text text__main text__main_medium">
                {{ point.address }}
              </span>
            <span class="cart-order-block__label-description text text__small">
                {{ schedule(point) }}
              </span>
          </label>
        </div>
      </li>
    </ul>

    <TheCartOrderMap/>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import ItemErrors from "./ItemErrors.vue";
import TheCartOrderMap from "./TheCartOrderMap.vue";

export default {
  name: "TheCartOrderReceivingPickup",
  components: {
    ItemErrors,
    TheCartOrderMap
  },
  computed: {
    ...mapState('pickupPoints', [
        'points'
    ]),
    ...mapState('order', [
        'pickupPoint'
    ]),
    errors: {
      get () {
        return this.$store.state.order.errors
      },
    }
  },
  methods: {
    ...mapActions('pickupPoints', {
      'fetch': 'fetch'
    }),
    ...mapMutations('order', [
        'setPickupPoint'
    ]),
    schedule(office) {
      let schedule = '';
      Array.from(office.openingHoursItems).forEach((scheduleItem) => {
        if (schedule) {
          schedule += ', ';
        }
        schedule += `${scheduleItem.title} ${scheduleItem.time}`;
      });
      return schedule;
    }
  },
  mounted() {
    this.fetch().then(() => {
      if (this.points.length === 1) {
        this.setPickupPoint(this.points[0].id);
      }
    });
  }
}
</script>
