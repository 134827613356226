<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.372 12.64a4.019 4.019 0 0 1-1.458 1.37 4.003 4.003 0 0 1-2.921.365 4.015 4.015 0 0 1-2.897-2.968 4 4 0 1 1 7.857-1.473 4.037 4.037 0 0 1-.22 1.988 3.986 3.986 0 0 1-.36.718Zm-.06 2.859 2.14 2.28a1 1 0 1 0 1.459-1.368l-2.14-2.281a5.978 5.978 0 0 0 .992-1.983l3.066.341a1 1 0 0 0 .22-1.988l-3.066-.34a5.973 5.973 0 0 0-.37-1.762l2.811-1.628A1 1 0 1 0 17.492 5L14.61 6.67a6.028 6.028 0 0 0-1.626-1.372l1.118-3.022a1 1 0 1 0-1.886-.666l-1.108 2.994a6.033 6.033 0 0 0-2.142-.016l-1.03-2.92a1 1 0 0 0-1.887.665l1.031 2.92A6.028 6.028 0 0 0 5.341 6.71L2.647 5.18a1 1 0 0 0-.987 1.74l2.692 1.528a5.974 5.974 0 0 0-.35 1.707L.89 10.5a1 1 0 1 0 .22 1.987l3.11-.345c.194.684.507 1.318.914 1.879l-2.22 2.215a1 1 0 1 0 1.412 1.416l2.235-2.23a5.967 5.967 0 0 0 2.431.994v3.094a1 1 0 0 0 2 0v-3.094a5.964 5.964 0 0 0 2.32-.918Z" fill="#E8C759"/></svg>
</template>

<script>
  export default {
    name: "Sunshine"
  }
</script>

<style scoped>

</style>