<template>
  <div class="price-inputs price-competitor-main__form price-competitor-main__price-form price-competitor-main__price-form_old">
    <div class="price-competitor-main__price-input-wrap">
      <label :for="inputId">{{ label }}</label>
      <input type="number" :id="inputId" :value="inputValue" :ref="inputRefName">
    </div>

    <div class="price-competitor-main__price-buttons">
      <button class="price-competitor-main__price-button price-competitor-main__price-submit"
              @click="submitFunction($event, tyreObject.id, priceType, inputRefName, buttonRefName)"
      >
        <GreenTick/>
      </button>
      <button class="price-competitor-main__price-button price-competitor-main__price-remove"
              :class="{_hidden: !inputValue}"
              @click="removeFunction($event, tyreObject.id, priceType, inputRefName, buttonRefName)"
              :ref="buttonRefName"
      >
        <RedCross/>
      </button>
    </div>
  </div>
</template>

<script>
import GreenTick from "../icons/GreenTick.vue";
import RedCross from "../icons/RedCross.vue";

export default {
  name: "PriceInputs",
  components: {RedCross, GreenTick},
  props: {
    tyreObject: {
      type: Object,
      required: true
    },
    inputValue: {
      type: Number,
      required: true
    },
    priceType: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    inputId: {
      type: String,
      required: true
    },
    inputRefName: {
      type: String,
      required: true
    },
    buttonRefName: {
      type: String,
      required: true
    },
    submitFunction: {
      type: Function,
      required: false
    },
    removeFunction: {
      type: Function,
      required: false
    },
  }
}
</script>

<style lang="scss">

</style>