import Vue from 'vue';
import ScheduleService from "../../../services/schedule.service";

function isEmpty(obj) {
  for (let key in obj) {
    return false;
  }
  return true;
}

function matchSlots(slots, matchSlot, postId) {
  let add = true; //Добавлять ли элемент

  matchSlot.postId = postId;

  if(slots.length === 0) {
    slots.push(matchSlot);
    return slots;
  }

  for (const slot in slots) {
    if(slots.hasOwnProperty(slot) && slots[slot].verbose === matchSlot.verbose) {
      if(slots[slot].freedom === false) {
        slots[slot].freedom = matchSlot.freedom;
        slots[slot].postId = matchSlot.postId;
      }
      add = false;
      break;
    }
  }

  if(add) {
    slots.push(matchSlot);
  }

  return slots;
}

const scheduleModule = {
  namespaced: true,
  state: {
    firstInit: false,
    loading: true,
    userId: null,
    graph: {},
    date: new Date(),
    services: [],
    activeService: {},
    selectedServiceCenterId: null,
    selectedPost: null,
    selectedSlot: null,
    carTypes: {},
    orderData: {},
    records: [],
    editableRecord: null,
    details: {
      formHeight: '',
      formWidth: '',
      formDiameter: '',
      detailsVariant: 10,
      inStorageVariant: '',
      orderNumber: '',
      saveOrderNumber: '',
      saveOrderName: '',
      errors: {
        heightError: [],
        diameterError: [],
        widthError: [],
        detailsVariantError: [],
        inStorageVariantError: [],
        orderNumberError: [],
        saveOrderNameError: [],
        saveOrderNumberError: [],
      }
    },
    fields: {
      width: [],
      height: [],
      diameter: [],
      detailsVariant: [
          'БУ на хранении',
          'Новые'
      ]
    },
  },
  getters: {
    timestamp: (state) => {
      return Math.round(state.date.getTime()/1000);
    },
    serviceCenters: (state) => {
      return state.graph.serviceCenters
    },
    selectedServiceCenter: (state, getters) => {
      let serviceCenterObject = {};

      for (const serviceCenter in getters.serviceCenters) {
        if(getters.serviceCenters.hasOwnProperty(serviceCenter) && getters.serviceCenters[serviceCenter].id === state.selectedServiceCenterId) {
          serviceCenterObject = getters.serviceCenters[serviceCenter];
        }
      }

      return serviceCenterObject;
    },
    currentSlots: (state, getters) => {
      let slots = [];

      if(isEmpty(getters.selectedServiceCenter)) {
        return slots;
      }

      const slotPosts = getters.selectedServiceCenter.slotPosts;

      slotPosts.forEach((slotPost) => {
        let postId = slotPost.id;
        let postSlots = slotPost.slots;

        postSlots.forEach((postSlot) => {
          slots = matchSlots(slots, postSlot, postId);
        })
      });

      slots.sort((a, b) => a.verbose > b.verbose ? 1 : -1);

      return slots;
    }
  },
  actions: {
    async init({ commit, state, dispatch }) {
      const services = await ScheduleService.getServices();
      commit('setServices', services.data);

      if(services.data[0]) {
        commit('setActiveService', services.data[0]);
      }
      dispatch('loadGraph');
      dispatch('loadCarTypes');
      dispatch('fetchWidths');
      dispatch('fetchHeights');
      dispatch('fetchDiameters');
    },
    async fetchWidths({ state, dispatch, commit, rootState }) {
      try {
        const result = await ScheduleService.fetchWidths();
        commit('setWidthValues', result.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchHeights({ state, dispatch, commit, rootState }) {
      try {
        const result = await ScheduleService.fetchHeights();
        commit('setHeightValues', result.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDiameters({ state, dispatch, commit, rootState }) {
      try {
        const result = await ScheduleService.fetchDiameters();
        commit('setDiameterValues', result.data);
      } catch (error) {
        console.log(error);
      }
    },
    setActiveService({commit, dispatch}, activeService) {
      commit('setActiveService', activeService);
      dispatch('loadGraph');
    },
    setDate({commit, dispatch}, date) {
      commit('setDate', date);
      dispatch('loadGraph');
    },
    async loadGraph({ commit, state, getters }) {
      commit('setLoading');

      const graph = await ScheduleService.getUserGraph(state.activeService.id, getters.timestamp);
      commit('setGraph', graph.data);

      if(graph.data && graph.data.serviceCenters) {
        commit('setSelectedServiceCenterId', graph.data.serviceCenters[0].id);
      }

      commit('unsetLoading');
    },
    async loadCarTypes({ commit }) {
      let carTypes = await ScheduleService.getCarTypes();
      commit('setCarTypes', carTypes.data);
    },
    async initEditableRecord({ state, getters, commit, dispatch }, data) {
      commit('setFirstInit', true);
      const services = await ScheduleService.getServices();
      commit('setServices', services.data);

      const result = await ScheduleService.getRecord(data);
      const record = result.data.data;

      if (record) {
        commit('setEditableRecord', record);
        commit('setActiveService', record.service);

        commit('setDate', new Date(record.date));

        const graph = await ScheduleService.getUserGraph(state.activeService.id, getters.timestamp);
        commit('setGraph', graph.data);

        if(graph.data && graph.data.serviceCenters) {
          commit('setSelectedServiceCenterId', record.serviceCenterId);
        }
      }
    },
    async getRecords({commit}, data) {
      try {
        const response = await ScheduleService.getRecords(data)
          .then((result) => {
          if (result.status === 200) {
            commit('setRecords', result.data.data);
          }
        });
      } catch (e) {
        commit('setRecords', []);
        console.log(e);
      }
    },
    async deleteRecord({commit, dispatch, state}, data) {
      try {
        const response = await ScheduleService.deleteRecord({
          recordId: data.recordId
        }).then((result) => {
          if (result.status === 200) {
            if (data.href) {
              window.location.href = data.href
            } else {
              dispatch('getRecords', {userId: data.userId})
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  mutations: {
    setServices(state, services) {
      state.services = services;
    },
    setActiveService(state, activeService) {
      state.activeService = activeService;
    },
    setDate: (state, date) => {
      state.date = date;
    },
    setGraph: (state, graph) => {
      state.selectedSlot = null;
      state.graph = graph;
    },
    setLoading: (state) => {
      state.loading = true;
    },
    unsetLoading: (state) => {
      state.loading = false;
    },
    setSelectedServiceCenterId: (state, serviceCenterId) => {
      state.selectedSlot = null;
      state.selectedServiceCenterId = serviceCenterId;
    },
    setSelectedSlot: (state, slot) => {
      state.selectedSlot = slot;
    },
    setSelectedPost: (state, post) => {
      state.selectedPost = post;
    },
    setCarTypes(state, carTypes) {
      state.carTypes = carTypes;
    },
    setOrderData: (state, data) => {
      state.orderData = data;
    },
    setUserId: (state, data) => {
      state.userId = data;
    },
    setEditableRecord: (state, data) => {
      state.editableRecord = data;
    },
    setFirstInit: (state, data) => {
      state.firstInit = data;
    },
    setRecords: (state, data) => {
      state.records = data;
    },
    setWidthValues(state, data) {
      state.fields.width = data;
    },
    setHeightValues(state, data) {
      state.fields.height = data;
    },
    setDiameterValues(state, data) {
      state.fields.diameter = data;
    },
    setDetailsVariant(state, value) {
      state.details.detailsVariant = value;
    },
    setWidth(state, value) {
      state.details.formWidth = value;
    },
    setHeight(state, value) {
      state.details.formHeight = value;
    },
    setDiameter(state, value) {
      state.details.formDiameter = value;
    },
    setInStorageVariant(state, value) {
      state.details.inStorageVariant = value;
    },
    setOrderNumber(state, value) {
      state.details.orderNumber = value;
    },
    setSaveOrderNumber(state, value) {
      state.details.saveOrderNumber = value;
    },
    setSaveOrderName(state, value) {
      state.details.saveOrderName = value;
    },
  },
};

export default scheduleModule;