import PriceManualService from "../../../services/priceManual.service";

const PriceManualFilter = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    isLastPage: false,
    settings: {
      width: '0',
      height: '0',
      diameter: '0',
      season: '0',
      producer: '0',
      id: '',
      page: 1,
      manualPrices: false,
      manualOldPrices: false,
      manualAvitoPrices: false,
      manualYandexPrices: false,
    },
    fields: {
      width: [],
      height: [],
      diameter: [],
      producer: [],
      season: [],
      id: ''
    },
  },
  getters: {},
  mutations: {
    setWidthValues(state, data) {
      state.fields.width = data;
    },
    setHeightValues(state, data) {
      state.fields.height = data;
    },
    setDiameterValues(state, data) {
      state.fields.diameter = data;
    },
    setSeasonValues(state, data) {
      state.fields.season = data;
    },
    setProducerValues(state, data) {
      state.fields.producer = data;
    },
    setPage(state, data) {
      state.settings.page = data;
    },
    setTrueIsLastPage(state) {
      state.isLastPage = true;
    },
    setFalseIsLastPage(state) {
      state.isLastPage = false;
    },
  },
  actions: {
    async fetchFilterParams({ state, dispatch, commit, rootState }) {
      try {
        dispatch('fetchWidths');
        dispatch('fetchHeights');
        dispatch('fetchDiameters');
        dispatch('fetchSeasons');
        dispatch('fetchProducers');
      } catch (error) {
        console.log(error);
      }
    },
    async fetchWidths({ state, dispatch, commit, rootState }) {
      try {
        const result = await PriceManualService.fetchWidths();
        commit('setWidthValues', result.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchHeights({ state, dispatch, commit, rootState }) {
      try {
        const result = await PriceManualService.fetchHeights();
        commit('setHeightValues', result.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDiameters({ state, dispatch, commit, rootState }) {
      try {
        const result = await PriceManualService.fetchDiameters();
        commit('setDiameterValues', result.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchSeasons({ state, dispatch, commit, rootState }) {
      try {
        const result = await PriceManualService.fetchSeasons();
        commit('setSeasonValues', result.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchProducers({ state, dispatch, commit, rootState }) {
      try {
        const result = await PriceManualService.fetchProducers();
        commit('setProducerValues', result.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
};

export default PriceManualFilter;