<template>
  <div class="profile-service">
    <div class="profile-service__table">
      <div class="profile-service__header">
        <h3 class="profile-service__title">
          Сервис
        </h3>

        <a href="/profile/service/record"
           title="Новая запись"
           class="profile-service__new-record"
        >
          <span class="profile-service__new-record-icon">
            <PlusIcon/>
          </span>
          <span class="profile-service__new-record-title">
              Новая запись
          </span>
        </a>
      </div>

      <ul class="profile-service__list">
        <li class="profile-service__item" v-for="record in records">
          <div class="profile-service__item-heading">
            <div class="profile-service__item-heading-main">
              <div class="profile-service__item-number text__mini">
                Запись №{{ record.recordId }}
              </div>

              <h4 class="profile-service__item-title">
                {{ record.service.name }}
              </h4>
            </div>

            <div class="profile-service__buttons">
              <a :href="`/profile/service/edit/${record.recordId}`"
                 title="edit"
                 class="profile-service__edit"
              >
                <EditIcon/>
              </a>
              <a href="javascript:void(0);"
                 title="remove"
                 class="profile-service__remove"
                 @click="remove(record)"
              >
                <CloseIcon/>
              </a>
            </div>
          </div>

          <ul class="profile-service__item-list">
            <li class="profile-service__item-param text__small">
              <div class="profile-service__item-param-name">
                Дата
              </div>
              <div class="profile-service__item-param-value">
                {{ date(record.date) }}
              </div>
            </li>
            <li class="profile-service__item-param text__small">
              <div class="profile-service__item-param-name">
                Адрес
              </div>
              <div class="profile-service__item-param-value">
                {{ record.serviceCenterAddress }}
              </div>
            </li>
            <li class="profile-service__item-param text__small">
              <div class="profile-service__item-param-name">
                Контактное лицо
              </div>
              <div class="profile-service__item-param-value">
                {{ record.name }}
              </div>
            </li>
            <li class="profile-service__item-param text__small">
              <div class="profile-service__item-param-name">
                Телефон
              </div>
              <div class="profile-service__item-param-value">
                {{ record.phone }}
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapMutations, mapState} from "vuex";
  import PlusIcon from "../../icons/PlusIcon";
  import CloseIcon from "../../icons/CloseIcon";
  import EditIcon from "../../icons/EditIcon";
  export default {
    name: "TheScheduleUserProfileServiceList",
    components: {EditIcon, CloseIcon, PlusIcon},
    props: {
      userId: null,
    },
    computed: {
      ...mapState('schedule', [
        'records',
        'graph'
      ]),
      serviceCenters() {
        if (this.graph.serviceCenters) {
          return this.graph.serviceCenters;
        }
        return [];
      },
    },
    methods: {
      ...mapActions('schedule', [
        'init',
        'getRecords',
        'deleteRecord'
      ]),
      date(recordDate) {
        const monthNames = ["января", "февраля", "марта", "апреля", "мая", "июня",
          "июля", "августа", "сентября", "октября", "ноября", "декабря"
        ];

        const [dateValues, timeWithGmt] = recordDate.split('T');
        const [year, month, day] = dateValues.split('-'); // год, месяц, день
        const [time, gmt] = timeWithGmt.split('+'); // время, gmt
        const [hours, minutes, seconds] = time.split(':'); // часы, минуты, секунды
        const date = new Date(+year, month - 1, +day, +hours, +minutes, +seconds);
        return `${date.getDate()} ${monthNames[date.getMonth()]} в ${date.getHours()}:${minutes}`;
      },
      serviceCenter(serviceCenterId) {
        if (this.serviceCenters) {
          return this.serviceCenters.find((item) => item.id === serviceCenterId);
        }
        return null;
      },
      remove(record) {
        this.deleteRecord({recordId: record.recordId, userId: this.userId})
      }
    },
    mounted() {
      this.init();
      this.getRecords({userId: this.userId});
    }
  }
</script>

<style scoped>

</style>