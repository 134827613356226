import {mapState, mapActions, mapWritableState} from 'pinia'
import { useFilterStore } from "../../stores/filter";

const mixin = {
  props: {
    filterKey: String,
    fieldsetKey: String,
    className: String
  },
  computed: {
    ...mapState(useFilterStore, [
      'fieldsByFieldset',
      'findFieldset',
      'getValues'
    ]),
    ...mapWritableState(useFilterStore, [
        'filtersValues'
    ]),
    fields() {
      return this.fieldsByFieldset(
        this.filterKey,
        this.fieldsetKey
      );
    },
    fieldset() {
      return this.findFieldset(
        this.filterKey,
        this.fieldsetKey
      );
    },
    blockClasses() {
      const classes = [];
      if (this.className) {
        classes.push(this.className);
      }
      if (this.fieldset && this.fieldset.class) {
        classes.push(this.fieldset.class);
      }
      classes.push('filter-fieldset');
      return classes;
    },
    values() {
      return this.getValues(this.filterKey);
    },
  },
  // Получение списка полей для рендера
  //
  methods: {
    elementClasses(element) {
      return this.blockClasses.map((className) => `${className}__${element}`);
    }
  }
};

export const fieldsetMixin = mixin;