<template>
  <div class="price-manual-main price-competitor-main" :ref="'main'">
    <div class="price-competitor-main__heading-title">
      Шина
    </div>

    <!--  Первый строчка в таблице -->
    <div class="price-competitor-main__heading-columns">
      <div class="price-competitor-main__heading-column">Цена на n.shlepakov.ru</div>
    </div>

    <!--  Первый столбик с названиями шин  -->
    <div class="price-competitor-main__heading-rows">
      <div class="price-competitor-main__heading-row"
           v-for="tyre in this.tyres"
      >
        <div class="price-competitor-main__heading-row-title">
          {{ tyre.name }} <br><br>
          id на сайте: {{ tyre.id }}
        </div>
      </div>
    </div>

    <!--  Таблица с шинами  -->
    <ul class="price-competitor-main__data">
      <li class="price-competitor-main__item"
          v-for="tyre in this.tyres"
      >
        <!--  Первый столбик  -->
        <div class="price-competitor-main__item-column">
          <div class="price-competitor-main__price">
            Текущая цена на сайте: {{ tyre.price }} ₽
          </div>
          <div class="price-competitor-main__coment">
            Комментарий: {{ tyre.comment }}
          </div>

          <div class="price-competitor-main__inputs">
            <PriceInputs
                :tyre-object="tyre"
                :input-value="tyre.manualPrice"
                price-type="manualPrice"
                label="Новая цена"
                input-id="new-price"
                :input-ref-name="'manualPriceInput-' + tyre.id"
                :button-ref-name="'removePriceButton-' + tyre.id"
                :submit-function="saveManualPrice"
                :remove-function="removeManualPrice"
                ref="childManualPrice"/>

            <PriceInputs
                :tyre-object="tyre"
                :input-value="tyre.oldManualPrice"
                price-type="oldManualPrice"
                label="Старая цена"
                input-id="old-price"
                :input-ref-name="'manualOldPriceInput-' + tyre.id"
                :button-ref-name="'removeOldPriceButton-' + tyre.id"
                :submit-function="saveManualPrice"
                :remove-function="removeManualPrice"
                ref="childOldManualPrice"/>

            <PriceInputs
                :tyre-object="tyre"
                :input-value="tyre.avitoManualPrice"
                price-type="avitoManualPrice"
                label="Авито цена"
                input-id="avito-price"
                :input-ref-name="'manualAvitoPriceInput-' + tyre.id"
                :button-ref-name="'removeAvitoPriceButton-' + tyre.id"
                :submit-function="saveManualPrice"
                :remove-function="removeManualPrice"
                ref="childAvitoManualPrice"/>

<!--            <PriceInputs-->
<!--                :tyre-object="tyre"-->
<!--                :input-value="tyre.yandexManualPrice"-->
<!--                price-type="yandexManualPrice"-->
<!--                label="Яндекс цена"-->
<!--                input-id="yandex-price"-->
<!--                :input-ref-name="'manualYandexPriceInput-' + tyre.id"-->
<!--                :button-ref-name="'removeYandexPriceButton-' + tyre.id"-->
<!--                :submit-function="saveManualPrice"-->
<!--                :remove-function="removeManualPrice"-->
<!--                ref="childYandexManualPrice"/>-->
          </div>

        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import RedCross from "../icons/RedCross.vue";
import GreenTick from "../icons/GreenTick.vue";
import PriceInputs from "./PriceInputs.vue";

export default {
  name: "ThePriceManualMain",
  components: {PriceInputs, GreenTick, RedCross},
  data() {
    return {
      isLoading: false,
      modalTitle: '',
    }
  },
  computed: {
    ...mapState('priceManualItems', [
        'tyres',
    ]),
    ...mapState('priceCompetitorFilter', [
        'settings',
        'isLastPage',
    ]),
    filteredCompetitors() {
      const competitorId = parseInt(this.settings.competitor, 10);
      if (competitorId) {
        return this.competitors.filter(competitor => competitor.id === competitorId);
      }
      return this.competitors;
    },
  },
  methods: {
    ...mapMutations('priceManualFilter', [
        'setPage'
    ]),
    ...mapActions('priceManualItems', [
        'fetchTyres',
        'debounceFetchTyres',
        'createPrice',
        'removePrice',
    ]),
    autoPagination() {
      window.addEventListener('scroll', (event) => {
        const bounds = this.$refs.main.getBoundingClientRect();
        if (bounds.bottom < window.innerHeight + window.innerHeight / 2) {
          if (!this.isLastPage) {
            this.debounceFetchTyres();
          }
        }
      });
    },
    saveManualPrice(e, tyreId, type, inputRefName, buttonRefName) {
      const childName = 'child' + type.charAt(0).toUpperCase() + type.slice(1);
      const child = this.$refs[childName].find((item) => item.tyreObject.id === tyreId);
      if (!child) {
        console.log(`Could not found old price inputs element. TyreId: ${tyreId}`);
      }

      const priceInput = child.$refs[inputRefName];
      const priceValue = parseInt(priceInput.value, 10);
      if (!priceInput.value) {
        priceInput.classList.add('_error');
      } else {
        priceInput.classList.remove('_error');

        this.createPrice({tyreId: tyreId, price: priceValue, priceType: type});

        child.$refs[buttonRefName].classList.remove('_hidden');
      }
    },
    removeManualPrice(e, tyreId, type, inputRefName, buttonRefName) {
      const childName = 'child' + type.charAt(0).toUpperCase() + type.slice(1);
      const child = this.$refs[childName].find((item) => item.tyreObject.id === tyreId);
      if (!child) {
        console.log(`Could not found old price inputs element. TyreId: ${tyreId}`);
      }

      const priceInput = child.$refs[inputRefName];
      const priceValue = parseInt(priceInput.value, 10);
      if (!priceInput.value) {
        priceInput.classList.add('_error');
      } else {
        priceInput.classList.remove('_error');

        this.removePrice({tyreId: tyreId, price: priceValue, priceType: type});
        priceInput.value = '';
        child.$refs[buttonRefName].classList.add('_hidden');
      }
    },
  },
  mounted() {
    this.autoPagination();
  }
}
</script>

<style scoped>

</style>