<template>
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="34" height="34" rx="2" fill="#0FC46D"/>
    <path d="M11 17L15 21L23 12" stroke="white" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: "GreenTick"
}
</script>

<style lang="scss">

</style>