<template>
  <div class="dropdown-field" :class="blockClasses">
    <label v-if="field.label"
           class="dropdown-field__label text__mini"
           :class="elementClasses('label')"
    >
      {{ field.label }}
    </label>

    <div class="dropdown-field__wrapper" :class="elementClasses('wrapper')">
      <select v-model="valueItem.value" :class="elementClasses('select')" :disabled="field.disabled">
        <option :value="defaultValue">
          Любая
        </option>
        <option v-for="(choice, index) in field.choices" :value="choice.key" :disabled="choice.disabled">
          {{ choice.label }}
        </option>
      </select>
      <div class="dropdown-field__preloader" v-show="isPreloaderVisible">
        <Preloader/>
      </div>
    </div>
  </div>
</template>

<script>
  import {fieldMixin} from "./fieldMixin";
  import Preloader from "../../components/parts/common/Preloader";

  export default {
    name: "DropdownField",
    components: {Preloader},
    mixins: [
      fieldMixin
    ],
    data() {
      return {
        defaultValue: 0,
      }
    },
    computed: {
      isFieldChoicesHasCurrentValue() {
        return this.field.choices.some((choice) => choice.key === this.valueItem.value);
      },
      isPreloaderVisible() {
        return this.isLoading[this.field.key] === true;
      }
    },
    methods: {
      checkValue() {
        if (this.field.disabled) {
          this.valueItem.value = this.defaultValue;
          return;
        }
        if (!this.valueItem.value || !this.field.choices.length || !this.isFieldChoicesHasCurrentValue) {
          this.valueItem.value = this.defaultValue;
        }
      },
    },
    mounted() {
      this.checkValue();
    },
    beforeUpdate() {
      this.checkValue();
    }
  }
</script>

<style lang="scss">

</style>