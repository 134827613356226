<template>
  <div class="record-for-service-record-info">
    <ul class="record-for-service-record-info__list">
      <li class="record-for-service-record-info__item text__small">
        <div class="record-for-service-record-info__item-name">
          Услуга
        </div>
        <div class="record-for-service-record-info__item-value">
          {{ serviceName }}
        </div>
      </li>
      <li class="record-for-service-record-info__item text__small">
        <div class="record-for-service-record-info__item-name">
          Сервисный центр
        </div>
        <div class="record-for-service-record-info__item-value">
          {{ serviceCenterAddress }}
        </div>
      </li>
      <li class="record-for-service-record-info__item text__small">
        <div class="record-for-service-record-info__item-name">
          Дата и время
        </div>
        <div class="record-for-service-record-info__item-value">
          {{ date }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';

  export default {
    name: "TheScheduleServiceRecordInfo",
    computed: {
      ...mapState('schedule', [
        'graph',
        'editableRecord'
      ]),
      serviceName() {
          return this.editableRecord ? this.editableRecord.service.name : '';
      },
      date() {
          if (this.editableRecord) {
            const monthNames = ["января", "февраля", "марта", "апреля", "мая", "июня",
              "июля", "августа", "сентября", "октября", "ноября", "декабря"
            ];

            if (this.editableRecord.date) {
              const [dateValues, timeWithGmt] = this.editableRecord.date.split('T');
              const [year, month, day] = dateValues.split('-'); // год, месяц, день
              const [time, gmt] = timeWithGmt.split('+'); // время, gmt
              const [hours, minutes, seconds] = time.split(':'); // часы, минуты, секунды
              const date = new Date(+year, month - 1, +day, +hours, +minutes, +seconds);
              return `${date.getDate()} ${monthNames[date.getMonth()]} в ${date.getHours()}:${minutes}`;
            }
          }
        return '';

      },
      serviceCenters() {
          if (this.graph.serviceCenters) {
              return this.graph.serviceCenters;
          }
          return [];
      },
      serviceCenter() {
          if (this.editableRecord) {
              return this.serviceCenters
                  .find((item) => item.id === this.editableRecord.serviceCenterId);
          }
          return null;
      },
      serviceCenterAddress() {
        return this.serviceCenter ? this.serviceCenter.address : '';
      }
    },
    mounted() {

    }
  }
</script>

<style lang="scss">

</style>