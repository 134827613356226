<template>
  <div class="policy-checkbox policy-checkbox_dark">
    <div class="policy-checkbox__inner">
      <input type="checkbox"
             :id="id + '-agreement'"
             required
             value="152"
             style="opacity: 0;"
             :checked="modelValue"
             @input="$emit('update:modelValue', $event.target.checked)"
      >
      <label :for="id + '-agreement'"
             class="small-label">
        Я&nbsp;даю свое согласие на&nbsp;обработку моих персональных данных и&nbsp;принимаю условия

        <a href="#"
           target="_blank"
           rel="nofollow noopener noreferrer"
           class="policy-checkbox__policy-link"
        >
          политики&nbsp;конфиденциальности
        </a>
      </label>

      <div class="policy-checkbox__error" :class="{_visible: showError}">
        <NotificationIcon/>
        <div class="policy-checkbox__error-title text__mini">
          Чтобы продолжить, установите этот флажок
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NotificationIcon from "../../icons/NotificationIcon.vue";

  export default {
    name: "policy-checkbox",
    components: {NotificationIcon},
    props: {
      id: {
        type: String,
        require: true,
      },
      modelValue: {
        type: Boolean,
        require: true,
      },
      showError: {
        type: Boolean,
        require: false,
      }
    },
  }
</script>

<style scoped>

</style>