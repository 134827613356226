<template>
  <div class="sidebar-fieldset" :class="blockClasses" v-if="isVisible">
    <div class="sidebar-fieldset__heading">
      <h4 class="sidebar-fieldset__title">
        Фильтры
      </h4>

      <a :href="currentUrl"
         title="Очистить"
         class="sidebar-fieldset__clear filter-clear-button"
      >
        Очистить
      </a>
    </div>

    <div class="sidebar-fieldset__main">
      <div class="sidebar-fieldset__switcher catalog-switcher" v-if="switcherUrl">
        <a :href="switcherUrl"
           title="switcher-button"
           class="sidebar-fieldset__link switcher"
           :class="{_active: switcherIsActive}"
        >
          <div class="switcher__inner"></div>
        </a>

        <div class="catalog-switcher__title">
          Размер в дюймах
        </div>
      </div>

      <div class="sidebar-fieldset__fields-wrap">
        <div class="sidebar-fieldset__fields" :class="elementClasses('fields')">
          <div :class="[...elementClasses('field'), ...elementClasses('field_' + field.key)]" v-for="field in fields">
            <Field v-if="field.visible" :fieldKey="field.key" :filterKey="filterKey" @change="showSubmitButton"/>
          </div>
        </div>

        <button type="submit"
                class="sidebar-fieldset__ninja-button"
                ref="ninjaSubmitButton"
                data-filter-submit
                :data-filter-key="filterKey"
        >
          <span class="sidebar-fieldset__ninja-title">
            Подобрать
          </span>
        </button>
      </div>
    </div>

    <button class="sidebar-fieldset__submit button button_black"
            data-filter-submit
            :data-filter-key="filterKey"
    >
      Применить
    </button>
  </div>
</template>

<script>
  import {fieldsetMixin} from "./fieldsetMixin";
  import Field from "../field/Field.vue";
  import Question from "../../components/icons/Question";

  export default {
    name: "SidebarFieldset",
    components: {Question, Field},
    mixins: [
      fieldsetMixin
    ],
    data() {
      return {
        ninjaButtonInit: false,
      }
    },
    computed: {
      isVisible() {
        return this.fields.some((field) => field.visible === true);
      },
      isDisabled() {
        if (this.fieldset) {
          return this.fields.some((field) => field.disabled === false);
        }
        return false;
      },
      currentUrl() {
        return window.location.href;
      },
      switcherUrl() {
        if (this.fieldset && this.fieldset.data.switcherUrl) {
          return this.fieldset.data.switcherUrl;
        }
        return false;
      },
      switcherIsActive() {
        if (this.fieldset && this.fieldset.data.switcherIsActive) {
          return this.fieldset.data.switcherIsActive;
        }
        return false;
      }
    },
    methods: {
      showSubmitButton(e) {
        const target = e.target;
        const parent = target.parentNode;
        const ninjaSubmitButton = this.$refs.ninjaSubmitButton;
        const ninjaParent = ninjaSubmitButton.parentElement;

        const ninjaParentTop = ninjaParent.getBoundingClientRect().top;
        const parentTop = parent.getBoundingClientRect().top;
        let diff = 0;

        if (ninjaParentTop < 0) {
          diff = Math.abs(ninjaParentTop) + Math.abs(parentTop);
        } else {
          diff = Math.abs(parentTop) - Math.abs(ninjaParentTop);
        }

        const offset = (parent.clientHeight / 3) + diff;
        ninjaSubmitButton.style.top = `${offset}px`;
        ninjaSubmitButton.classList.add('_show');

        this.ninjaButtonEventListeners(ninjaSubmitButton);

        clearTimeout(this.hideNinjaSubmitButton);
        this.hideNinjaSubmitButton = setTimeout(() => {
          ninjaSubmitButton.classList.remove('_show');
        }, 6000);
      },
      ninjaButtonEventListeners(ninjaSubmitButton) {
        if (this.ninjaButtonInit) {
          return;
        }

        ninjaSubmitButton.addEventListener('click', () => {
          setTimeout(() => {
            ninjaSubmitButton.classList.remove('_show');
          }, 1000)
        });
        this.ninjaButtonInit = true;
      }
    },
    mounted() {
      document.addEventListener('AccordionElementClose', () => {
        const ninjaSubmitButton = this.$refs.ninjaSubmitButton;
        if (ninjaSubmitButton) {
          ninjaSubmitButton.style.top = '';
          ninjaSubmitButton.classList.remove('_show');
        }
      });
    }
  }
</script>

<style lang="scss">

</style>