<template>
    <div class="record-services">
        <ul class="record-services__list smart-tabs tabs_choice" data-smart-tabs>
            <li class="smart-tab tabs__item"
                v-for="service in services"
                :class="{ _active: isActive(service) }"
            >
                <a :href="`#service-` + service.id"
                   :title="service.name"
                   class="tabs__link"
                   @click="setActive(service)"
                >
                    {{ service.name }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>

    import {mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: "TheScheduleServicesList",
        methods: {
            ...mapActions('schedule', [
                'setActiveService',
            ]),
            ...mapMutations('schedule', [
                'setFirstInit'
            ]),
            isActive(service) {
                return this.activeService.id === service.id;
            },
            setActive(service) {
                this.setFirstInit(false);
                if(service !== this.activeService) {
                    this.setActiveService(service);
                }
            },
        },
        computed: {
            ...mapState('schedule', [
                'services',
                'activeService',
                'editableRecord'
            ])
        },
    }
</script>

<style scoped>

</style>