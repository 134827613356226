/**
 * Исключает множественный биндинг события (например, при инициализации).
 * Reduces multiple bindings of single event.
 *
 * @param element
 * @param type
 * @param name
 * @param callback
 */
function singleBind(element, name, type, callback) {
  if (element.dataset[name]) {
    return;
  }
  element.dataset[name] = 'true';
  element.addEventListener(type, callback);
}

/**
 * Исключает множественные действия с объектом (например, инициализацию).
 * Reduces multiple handling.
 *
 * @param element
 * @param name
 * @param callback
 */
function singleInit(element, name, callback) {
  if (element.dataset[name]) {
    return;
  }
  element.dataset[name] = 'true';
  callback(element);
}

export {singleBind, singleInit};