import { createStore } from 'vuex'

import schedule from "./modules/scheduleService/schedule.module";
import priceCompetitorFilter from "./modules/priceCompetitor/priceCompetitorFilter.module";
import priceCompetitorItems from "./modules/priceCompetitor/priceCompetitorItems.module";
import priceManualFilter from "./modules/price-manual/priceManualFilter.module";
import priceManualItems from "./modules/price-manual/priceManualItems.module";
import favorites from "./modules/favorites.module";
import cart from './modules/cart.module';
import notification from './modules/notification.module';
import order from './modules/order.module';
import pickupPoints from './modules/pickupPoints.module';
import profile from './modules/profile.module';
import scheduleAdmin from "./modules/scheduleAdmin.module";

// Create a new store instance.
const store = createStore({
  modules: {
    schedule,
    scheduleAdmin,
    priceCompetitorFilter,
    priceCompetitorItems,
    priceManualFilter,
    priceManualItems,
    favorites,
    cart,
    notification,
    order,
    pickupPoints,
    profile
  },
  getters: {
    isLoading(state) {
      let loading = false;
      for (const key in state) {
        if (state.hasOwnProperty(key) && state[key].hasOwnProperty('loading')) {
          loading = loading || state[key].loading;
        }
      }
      return loading;
    },
  },
});

export default store;