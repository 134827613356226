<template>
  <div class="range-field" :class="blockClasses">
    <div class="range-field__heading">
      <label
          v-if="field.label"
          class="range-field__label"
          :class="elementClasses('label')"
      >
        {{ field.label }}
      </label>
      <span class="range-field__help filter-openable__help"
            :class="elementClasses('link-help')"
            v-if="field.data['help']"
      >
        <span class="range-field__help-icon filter-openable__help-icon">
          <QuestionBackground/>
        </span>
        <span class="range-field__help-description filter-openable__help-description">
          {{ field.data['help'] }}
        </span>
      </span>
    </div>

    <div
        class="range-field__content"
        :class="elementClasses('content')"
        v-if="valueItem.value"
    >
      <div
          class="range-field__values"
          :class="elementClasses('values')"
      >
        <input type="text" v-model="valueItem.value.from">
        <div class="range-openable-field__delimiter"></div>
        <input type="text" v-model="valueItem.value.to">
      </div>

      <slider
          class="range-field__slider"
          :model-value="valueModel"
          :min="field.data.min"
          :max="field.data.max"
          :step="field.data.step"
          @input="onSliderInput"
      />
    </div>
  </div>
</template>

<script>
  import {fieldMixin} from "./fieldMixin";
  import Slider from '@vueform/slider'
  import QuestionBackground from "../../components/icons/QuestionBackground";

  export default {
    name: "RangeField",
    mixins: [
      fieldMixin
    ],
    components: {
      Slider,
      QuestionBackground
    },
    computed: {
      valueModel() {
        if (!this.valueItem.value) {
          return [0, 0];
        }

        return [
          this.valueItem.value.from,
          this.valueItem.value.to
        ]
      }
    },
    methods: {
      checkValue() {
        if (!this.valueItem.value) {
          this.valueItem.value = {
            from: this.field.data.min,
            to: this.field.data.max
          };
        }
      },
      onSliderInput(value) {
        if (value[0] && value[1]) {
          this.valueItem.value.from = value[0];
          this.valueItem.value.to = value[1];
        }
      }
    },
    mounted() {
      this.checkValue();
    },
    beforeUpdate() {
      this.checkValue();
    }
  }
</script>

<style lang="scss">

</style>