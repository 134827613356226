<template>
  <div class="price-manual price-competitor price-competitor_main">
    <div class="price-competitor__filters-wrap">
      <ThePriceManualFilters class="price-competitor__filters"/>

      <div class="price-manual__total-title">
        Количество подобраных шин: {{ tyres.length }}
      </div>
    </div>

    <ThePriceManualMain class="price-competitor__main"/>
  </div>
</template>

<script>
import ThePriceManualMain from "./ThePriceManualMain.vue";
import {mapActions, mapState} from "vuex";
import ThePriceManualFilters from "./ThePriceManualFilters.vue";

export default {
  name: "ThePriceManual",
  components: {ThePriceManualFilters, ThePriceManualMain},
  computed: {
    ...mapState('priceManualItems', [
      'tyres'
    ])
  },
  methods: {
    ...mapActions('priceManualFilter', [
      'fetchFilterParams',
    ]),
    ...mapActions('priceManualItems', [
      'fetchTyres',
    ]),
  },
  mounted() {
    this.fetchFilterParams();
    this.fetchTyres();
  }
}
</script>

<style lang="scss">

</style>