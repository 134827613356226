import { onDomReady } from '../../components/dynamic/observer';
import {scrollToTarget} from "../../components/scroll/scroll";

class CustomScrollLink {
  constructor() {
    this.handleQueryString();
    this.getTargetFromQuery();
    this.scrollToTarget();
  }

  handleQueryString() {
    const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);
  }

  getTargetFromQuery() {
    this.targetId = this.urlParams.get('targetId');
    this.target = document.querySelector(`${this.targetId}`);
  }

  scrollToTarget() {
    if (!this.target) {
      return;
    }

    scrollToTarget(this.target, -140);
  }
}

onDomReady(() => {
  new CustomScrollLink();
});
