<template>
  <div class="date-picker" ref="Datepicker">
    <datepicker
        language="ru"
        :inline="true"
        :value="date"
        :mondayFirst="true"
        @selected="setDate"
    ></datepicker>
  </div>
</template>

<script>
  import {mapState, mapActions, mapGetters} from 'vuex';
  import Datepicker from 'vuejs3-datepicker';

  export default {
    name: "TheDatePicker",
    components: {
      Datepicker
    },
    data() {
      return {
        picked: new Date(),
      }
    },
    computed: {
      ...mapState('schedule', [
        'date'
      ]),
      ...mapGetters('schedule', [
        'timestamp'
      ]),
    },
    methods: {
      ...mapActions('schedule', [
        'setDate'
      ]),
    }
  }
</script>

<style lang="scss">

</style>