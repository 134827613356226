<template>
  <div class="service-center">
    <div class="service-center__name">
      {{ name }}
    </div>
    <ul class="service-center__slots">
      <li class="service-center__slot" v-for="slot in slots">
        <SlotItem
            :date-time="slot.dateTime"
            :verbose="slot.verbose"
            :freedom="slot.freedom"
            :slotPosts="slot.slotPosts"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import SlotItem from "../Slot/SlotItem.vue";

export default {
  name: "ServiceCenter",
  components: {SlotItem},
  props: {
    id: Number,
    name: String,
    address: String,
    addressLat: String,
    addressLng: String,
    posts: Object,
    slots: Array
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
.service-center {
  height: 100%;

  &__name {
    border-bottom: 1px solid var(--gray);
    font-size: 15px;
    line-height: calc(19 / 15);
    font-weight: 600;
    padding: 14px 22px;
    display: grid;
    align-items: center;
    color: #1F2129;
    min-height: 69px;
  }

  &__slots {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 22px 14px;
  }

  &__slot {
    flex: 0 0 48%;
    margin-bottom: 6px;
    transition: var(--default-transition);

    &:hover {
      background-color: var(--slot-bg);
    }
  }
}
</style>