<template>
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#DADADF" stroke-width="2"/><path d="m12 12 4 4" stroke="#DADADF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
</template>

<script>
  export default {
    name: "Search"
  }
</script>

<style scoped>

</style>