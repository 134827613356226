<template>
  <div class="vue-field vue-field_checkbox">
    <input
        @input="(event) => $emit('update:checked', event.target.checked)"
        type="checkbox"
        :disabled="disabled"
        :checked="checked"
        :id="fieldId"
    />
    <label :for="fieldId">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "BaseFieldCheckbox",
  emits: ["update:checked"],
  props: {
    label: {
      type: String,
      required: false
    },
    fieldId: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
}
</script>

<style lang="scss">

</style>