import ScheduleService from "../../../services/schedule.service";

const graphModule = {
  namespaced: true,
  state: {
    graph: {},
    loading: true
  },
  actions: {
    async loadGraph({ commit, rootState, rootGetters }) {
      commit('setLoading');
      const serviceId = rootState.scheduleAdmin.service.activeItemId;
      const dateTimestamp = rootGetters['scheduleAdmin/date/timestamp'];
      const graph = await ScheduleService.getAdminGraph(serviceId, dateTimestamp);
      commit('setGraph', graph.data);

      commit('unsetLoading');
    },
  },
  mutations: {
    setGraph(state, graph) {
      state.graph = graph;
    },
    setLoading: (state) => {
      state.loading = true;
    },
    unsetLoading: (state) => {
      state.loading = false;
    },
  }
};

export default graphModule;