<template>
  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.103.32.398 8l7.704 7.704 1.414-1.414-6.288-6.288 6.287-6.266L8.103.32Z" fill="#1F2129"/></svg>
</template>

<script>
  export default {
    name: "ArrowLeft"
  }
</script>

<style scoped>

</style>