<template>
  <form action="" class="record-form">
    <div class="form-input form-input_service">
      <label class="form-input__label">
        Услуга:
      </label>
      <input class="form-input__input" disabled="disabled" type="text" :value="service.name">
    </div>
    <div class="form-input form-input_name">
      <label class="form-input__label" :for="name.id">
        Имя:
      </label>
      <input class="form-input__input" type="text" v-model="name.value" :id="name.id" :name="name.fieldName">
      <ItemErrors :errors="name.errors"></ItemErrors>
    </div>
    <div class="form-input form-input_phone">
      <label class="form-input__label" :for="phone.id">
        Телефон:
      </label>
      <InputMask v-model="phone.value" mask="+7 (999) 999-99-99" maskChar=" "></InputMask>
      <ItemErrors :errors="phone.errors"></ItemErrors>
    </div>
    <div class="form-input form-input_number">
      <label class="form-input__label" :for="carAndLicensePlateNumber.id">
        Марка и гос. номер авто:
      </label>
      <input class="form-input__input" type="text" v-model="carAndLicensePlateNumber.value"
             :id="carAndLicensePlateNumber.id" :name="carAndLicensePlateNumber.fieldName">
      <ItemErrors :errors="carAndLicensePlateNumber.errors"></ItemErrors>
    </div>
    <div class="form-input form-input_car-type">
      <label class="form-input__label" :for="carType.id">
        Тип автомобиля:
      </label>
      <select :id="carType.id" :name="carType.fieldName" v-model="carType.value">
        <option
            v-for="(carType, key) in carTypes"
            :value="key"
        >
          {{ carType }}
        </option>
      </select>
    </div>
    <div class="form-input form-input_comment">
      <label class="form-input__label" :for="comment.id">
        Комментарий:
      </label>
      <textarea class="form-input__input" v-model="comment.value" :id="comment.id" :name="comment.fieldName"></textarea>
      <ItemErrors :errors="comment.errors"></ItemErrors>
    </div>
    <button v-if="id" type="button" class="record-form__button record-form__button_remove" @click="remove()">
      Удалить запись
    </button>
    <button type="button" class="record-form__button record-form__button_save" @click="save()">
      Сохранить
    </button>
  </form>
</template>

<script>
import {mapGetters, mapMutations, mapActions, mapState} from 'vuex';
import ItemErrors from "./ItemErrors.vue";
import ScheduleService from "../../../services/schedule.service";
// import InputMask from 'vue-input-mask';

export default {
  name: "RecordForm",
  components: {
    ItemErrors,
    // InputMask
  },
  props: {
    postId: Number,
    formName: String,
    dateTime: String,
    carTypes: Object,
    initialCarType: {
      type: Number,
      required: false,
      default: null
    },
    initialId: {
      type: [Number, null],
      required: false,
      default: null
    },
    initialName: {
      type: String,
      required: false,
      default: ''
    },
    initialPhone: {
      type: String,
      required: false,
      default: ''
    },
    initialCarAndLicensePlateNumber: {
      type: String,
      required: false,
      default: ''
    },
    initialComment: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      id: this.initialId,
      name: {
        value: this.initialName,
        fieldName: 'name',
        id: this.fieldName(this.formName, 'name'),
        errors: []
      },
      phone: {
        value: this.initialPhone,
        fieldName: 'phone',
        id: this.fieldName(this.formName, 'phone'),
        errors: []
      },
      carAndLicensePlateNumber: {
        value: this.initialCarAndLicensePlateNumber,
        fieldName: 'carAndLicensePlateNumber',
        id: this.fieldName(this.formName, 'carAndLicensePlateNumber'),
        errors: []
      },
      comment: {
        value: this.initialComment,
        fieldName: 'comment',
        id: this.fieldName(this.formName, 'comment'),
        errors: []
      },
      carType: {
        value: this.initialCarType,
        fieldName: 'carType',
        id: this.fieldName(this.formName, 'carType'),
        errors: []
      }
    }
  },
  computed: {
    ...mapGetters('scheduleAdmin/service', {
      service: 'activeService'
    })
  },
  methods: {
    ...mapActions({
      'showErrorNotification': 'notification/showErrorNotification',
      'showSuccessNotification': 'notification/showSuccessNotification',
      'update': 'scheduleAdmin/graph/loadGraph',
    }),
    ...mapMutations({
      'setLoading': 'scheduleAdmin/graph/setLoading',
      'unsetLoading': 'scheduleAdmin/graph/unsetLoading'
    }),
    fieldName(formName, fieldName) {
      return formName + '_' + fieldName;
    },
    async save() {
      this.setLoading();

      try {
        const response = await ScheduleService.saveRecord({
          recordId: this.id,
          name: this.name.value,
          date: this.dateTime,
          postId: this.postId,
          serviceId: this.service.id,
          contactInfoId: this.contactInfoId,
          phone: this.phone.value,
          carAndLicensePlateNumber: this.carAndLicensePlateNumber.value,
          comment: this.comment.value,
          carType: parseInt(this.carType.value, 10)
        });
        const message = response.data.message;
        this.showSuccessNotification(message);
        this.cleanErrors();
        this.apply();

        console.log(message);
      } catch (e) {
        this.cleanErrors();
        this.prepareErrors(e.response.data.errors);
        this.showErrorNotification('Имеются ошибки');
        this.unsetLoading();
        console.log(e.response);
      }
    },
    async remove() {
      this.setLoading();

      try {
        const response = await ScheduleService.deleteRecord({
          data: {
            recordId: this.id
          }
        });
        const message = response.data.message;
        this.showSuccessNotification(message);
        this.id = null;
        this.cleanErrors();
        this.apply();
        console.log(message);
      } catch (e) {
        this.showErrorNotification('Имеются ошибки');
        this.unsetLoading();
        console.log(e.response);
      }
    },
    prepareErrors(errors) {
      for (const error in errors) {
        if (errors.hasOwnProperty(error)) {
          switch (error) {
            case 'carAndLicensePlateNumber':
              this.carAndLicensePlateNumber.errors = errors[error];
              break;
            case 'name':
              this.name.errors = errors[error];
              break;
            case 'phone':
              this.phone.errors = errors[error];
              break;
            case 'comment':
              this.comment.errors = errors[error];
              break;
          }
        }
      }
    },
    cleanErrors() {
      this.carAndLicensePlateNumber.errors = [];
      this.name.errors = [];
      this.phone.errors = [];
      this.comment.errors = [];
    },
    apply() {
      this.$emit('close');
      this.update();
    }
  }
}
</script>

<style lang="scss">
.record-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 28px;
  grid-column-gap: 22px;
  margin-top: 30px;

  &__button {
    padding: 12px 25px;
    border-radius: 3px;
    border: none;
    font-size: 14px;
    line-height: 120%;
    pointer-events: auto;

    &_remove {
      background: transparent;
      color: #798297;
      position: relative;
      text-align: left;


      &:before,
      &:after {
        content: '';
        width: 2px;
        height: 16px;
        background: #798297;
        top: 46%;
        position: absolute;
        transform-origin: center;
      }

      &:before {
        transform: rotate(45deg) translate3d(0, -50%, 0);
        left: 0;
      }

      &:after {
        transform: rotate(-45deg) translate3d(0, -50%, 0);
        left: 11px;
      }
    }

    &_save {
      grid-column: 2/3;
      background: #E12727;
      color: white;
    }
  }
}

.form-input {
  position: relative;
  margin-top: 22px;

  &_service,
  &_comment {
    grid-column: 1/3;
  }

  &_name {

  }

  &_phone {

  }

  &_number {

  }

  &__label {

  }

  &__input {

  }

  input, textarea, select {
    font-family: 'Roboto', serif;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 3px;
    width: 100%;
    padding: 12px;
  }

  select {
    option {
      font-weight: 400;
      font-size: 15px;
      line-height: 133%;
      color: #949494;
    }
  }

  input {
    height: 44px;
  }

  textarea {
    height: 90px;
  }

  label {
    font-weight: 400;
    font-size: 15px;
    line-height: 133%;
    color: #949494;
    position: absolute;
    top: -22px;
    left: 0;
  }
}
</style>