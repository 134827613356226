<template>
  <div class="record-for-service-edit-confirm">
    <a href="javascript:void(0);"
       title="Удалить запись"
       class="record-for-service-edit-confirm__remove"
       @click="remove()"
    >
      <span class="record-for-service-edit-confirm__remove-icon">
          <CloseIcon/>
      </span>
      <span class="record-for-service-edit-confirm__remove-title">
          Удалить запись
      </span>
    </a>

    <div class="record-for-service-edit-confirm__buttons">
      <a href="/profile/service"
         title="Записаться"
         class="record-for-service-edit-confirm__cancel button button_secondary"
      >
        Отменить изменения
      </a>

      <a href="javascript:void(0);"
         title="Записаться"
         class="record-for-service-edit-confirm__submit button button_primary"
         @click="submit"
      >
        Сохранить изменения
      </a>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapMutations, mapState} from "vuex";
  import CloseIcon from "../icons/CloseIcon";
  export default {
    name: "TheScheduleServiceEditConfirm",
    components: {CloseIcon},
    computed: {
      ...mapState('schedule', [
        'editableRecord'
      ])
    },
    methods: {
      ...mapActions('schedule', [
        'deleteRecord'
      ]),
      remove() {
        this.deleteRecord({
          recordId: this.editableRecord.recordId,
          href: '/profile/service',
          userId: this.userId
        })
      },
      submit() {
        this.emitter.emit('submitRecord', {recordId: this.editableRecord.recordId});
      }
    }
  }
</script>

<style scoped>

</style>