<template>
  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)"><path fill-rule="evenodd" clip-rule="evenodd" d="M.008 1.992 5 7l5-5L8.572.572l-3.57 3.57L1.44.565.009 1.992Z" fill="#1D202C"/></g><defs><clipPath id="a"><path fill="#fff" transform="rotate(-180 5 4)" d="M0 0h10v8H0z"/></clipPath></defs></svg>
</template>

<script>
  export default {
    name: "ArrowDown"
  }
</script>

<style scoped>

</style>