import PriceCompetitorService from "../../../services/priceCompetitor.service";

const PriceCompetitorItems = {
  namespaced: true,
  state: {
    total: 0,
    tyres: [],
    competitors: [],
    successAddMessage: 'Цена сохранена, изменения будут применены при следующей синхронизации',
    successRemoveMessage: 'Цена удалена, изменения будут применены при следующей синхронизации'

  },
  getters: {

  },
  mutations: {
    setTyres(state, data) {
      state.tyres = data;
    },
    setCompetitors(state, data) {
      state.competitors = data;
    },
    setCompetitorPriceChecked(state, data) {
      const competitorPrice = state.tyres
        .find((item) => item.id === data.tyreId).competitorPrices
        .find((price) => price.competitorId === data.competitorId);

      competitorPrice.checked = data.value;
    },
    setTotal(state, data) {
      if (data != null) {
        state.total = data;
      }
    },
    updateCompetitorItemPrices(state, data) {
      state.tyres.find((item) => item.id === data.tyreId).competitorPrices.push(data.newItem);
    }
  },
  actions: {
    async fetchCompetitors({ state, dispatch, commit, rootState }) {
      try {
        const result = await PriceCompetitorService.fetchCompetitors();
        commit('setCompetitors', result.data);
      } catch (error) {
        console.log(error);
      }
    },
    async buildReport({ state, dispatch, commit, rootState }) {
      try {
        const result = await PriceCompetitorService.buildReport();
      } catch (error) {
        alert('Нет цен конкурента, подходящих для формирования отчета');
        console.log(error);
      }
    },
    async fetchTyres({ state, dispatch, commit, rootState }) {
      try {
        // commit('catalogFilter/setLoading', true, { root: true });
        // const payload = await dispatch('getParams');
        // const { productType } = rootState.catalogFilter.settings;

        const result = await PriceCompetitorService.fetchTyres(rootState.priceCompetitorFilter.settings);
        commit('setTyres', result.data.tyreItems);
        commit('setTotal', result.data.totalCount);
        // await dispatch('catalogFilter/replaceCatalogContent', result.data, { root: true });
        // document.dispatchEvent(new Event('DOMContentMutated'));
        // commit('catalogFilter/setLoading', false, { root: true });
      } catch (error) {
        console.log(error);
      }
    },
    async debounceFetchTyres({ state, commit, dispatch, rootState }) {
      try {
        this.debounceOrder = this.debounceOrder ? this.debounceOrder + 1 : 1;
        setTimeout(async () => {
          this.debounceOrder = this.debounceOrder - 1;
          if (!this.debounceOrder) {
            commit('priceCompetitorFilter/setPage', rootState.priceCompetitorFilter.settings.page + 1, {root: true});
            const result = await PriceCompetitorService.fetchTyres(rootState.priceCompetitorFilter.settings);

            if (result.data.tyreItems.length < 50) {
              commit('priceCompetitorFilter/setTrueIsLastPage', {}, {root: true});
            }

            commit('setTyres', state.tyres.concat(result.data.tyreItems));
          }
        }, 50);
      } catch (e)  {
        console.log(error);
      }
    },
    async fetchHistoryPrices({ state, dispatch, commit, rootState }, payload) {
      try {
        const result = await PriceCompetitorService.fetchHistoryPrices(payload);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
    async updateCompetitorPrice({ state, commit }, competitorPriceObj) {
      try {
        const result = await PriceCompetitorService.updateCompetitorPrice(competitorPriceObj);
        if (result.data.status === 'error') {
          alert('При обновлении цены конкурента возникла ошибка, попробуйте еще раз.');
          console.log(result.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async createCompetitorPrice({ state, commit }, data) {
      try {
        const result = await PriceCompetitorService.createCompetitorPrice(data);

        if (result.data.status === 'success') {
          commit('updateCompetitorItemPrices', {
            tyreId: data.tyreId,
            newItem: result.data.competitorPriceItem
          });

          return result.data.status;
        } else if (result.data.status === 'error') {
          alert('Произошла ошибка при создании цены конкурента, попробуйте еще раз.');
          console.log(result.data.message);
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    async createPrice({ state, dispatch, commit }, data) {
      try {
        const result = await PriceCompetitorService.createPrice(data);
        if (result.data.status === 'error') {
          alert('Произошла ошибка при создании цены, попробуйте еще раз.');
          console.log(result.data.message);
        } else {
          dispatch('notification/showSuccessNotification', state.successAddMessage, {root:true});
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    async removePrice({ state, dispatch, commit }, data) {
      try {
        const result = await PriceCompetitorService.removePrice(data);
        if (result.data.status === 'error') {
          alert('Произошла ошибка при удалении цены, попробуйте еще раз.');
          console.log(result.data.message);
        } else {
          dispatch('notification/showSuccessNotification', state.successRemoveMessage, {root:true});
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    async createOldPrice({ state, dispatch, commit }, data) {
      try {
        const result = await PriceCompetitorService.createOldPrice(data);
        if (result.data.status === 'error') {
          alert('Произошла ошибка при создании цены, попробуйте еще раз.');
          console.log(result.data.message);
        } else {
          dispatch('notification/showSuccessNotification', state.successAddMessage, {root:true});
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    async removeOldPrice({ state, dispatch, commit }, data) {
      try {
        const result = await PriceCompetitorService.removeOldPrice(data);
        if (result.data.status === 'error') {
          alert('Произошла ошибка при удалении цены, попробуйте еще раз.');
          console.log(result.data.message);
        } else {
          dispatch('notification/showSuccessNotification', state.successRemoveMessage, {root:true});
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    async updateTracking({ state, commit }, data) {
      try {
        const result = await PriceCompetitorService.updateTracking(data);
        if (result.data.status === 'error') {
          alert('Произошла ошибка, попробуйте еще раз.');
          console.log(result.data.message);
        }
      } catch (error) {
        console.log('error', error);
      }
    },
  },
}

export default PriceCompetitorItems;