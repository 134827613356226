<template>
    <ul class="errors" v-if="errors && errors.length > 0">
        <li v-for="error in errors" class="cart-errors__error">
            {{ error }}
        </li>
    </ul>
</template>

<script>
  export default {
    name: "Errors",
    props: ['errors']
  }
</script>

<style lang="scss">
    .errors {
        position: absolute;
        z-index: 15;
        bottom: -16px;
        left: 0;
        list-style: none;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #fff;
        background-color: var(--primary-color);

        li {
            padding: 2px 3px;
        }
    }
</style>