import {onDomChanges, onDomReady} from "../../components/dynamic/observer";
import wNumb from 'wnumb';
import {encode} from "../encode";

class Encoder {
    constructor() {
        this.exports = document.querySelectorAll('[data-exports-uid]');
        this.wnumb = wNumb({
            decimals: 0,
            thousand: ' ',
        });
        this.export();
    }

    export() {
        this.exports.forEach((price) => {
            const hashedPrice = price.dataset.exportsUid;
            const encodedPrice = encode(hashedPrice);
            this.import(price, encodedPrice);
        })
    }

    import(element, price) {
        const kitPrice = price * 4;
        const intPrice = parseInt(price, 10);
        let priceElem = element.querySelector('[data-price]');
        if (priceElem) {
            priceElem.innerHTML = `${this.wnumb.to(intPrice)} ₽`;
            if (element.querySelector('[data-kit-price]')) {
                element.querySelector('[data-kit-price]').innerHTML = `${this.wnumb.to(kitPrice)} ₽ комплект`;
            }
        }
    }
}

const init = () => {
    if (document.querySelector('[data-active]')) {
        new Encoder;
    }
}

onDomReady(init);
onDomChanges(init);