<template>
  <div class="price-competitor price-competitor_main">
    <div class="price-competitor__filters-wrap">
      <ThePriceCompetitorFilters class="price-competitor__filters"/>

      <div class="price-competitor__total-title">
        Количество подобраных шин: {{ tyres.length }}
      </div>
    </div>

    <ThePriceCompetitorMain class="price-competitor__main"/>
  </div>
</template>

<script>
import ThePriceCompetitorFilters from "./ThePriceCompetitorFilters";
import ThePriceCompetitorMain from "./ThePriceCompetitorMain";
import {mapActions, mapState} from "vuex";

export default {
  name: "ThePriceCompetitor",
  components: {
    ThePriceCompetitorMain,
    ThePriceCompetitorFilters,
  },
  computed: {
    ...mapState('priceCompetitorItems', [
        'tyres'
    ])
  },
  methods: {
    ...mapActions('priceCompetitorFilter', [
      'fetchFilterParams',
    ]),
    ...mapActions('priceCompetitorItems', [
        'fetchCompetitors',
        'fetchTyres',
    ]),
  },
  mounted() {
    this.fetchFilterParams();
    this.fetchCompetitors();
    this.fetchTyres();
  }
}
</script>

<style scoped>

</style>