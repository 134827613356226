<template>
  <div class="heading-round-index">
    <div class="heading-round-index__label round-index">
      <div class="round-index__title">
        {{ index }}
      </div>
    </div>
    <h3 class="heading-round-index__title">
      {{ title }}
    </h3>
  </div>
</template>

<script>
  export default {
    name: "TheHeadingRoundIndex",
    props: {
      index: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      }
    }
  }
</script>

<style scoped>

</style>