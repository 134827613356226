import axios from 'axios';

const FavoritesService = {
    async add(chosen, id) {
        return axios.post('/favorites/api/add', {
            chosen,
            id
        });
    },
    async remove(key) {
        return axios.post('/favorites/api/remove', {
            key
        });
    },
    async fetchCheckout() {
        return axios.get('/favorites/api/index');
    },
    // async updateFavoritesList() {
    //     return axios.get('/favorites');
    // }
};

export default FavoritesService;