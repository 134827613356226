<template>
  <div class="cart-positions">
    <ul class="cart-positions__list">
      <li class="cart-positions__item" v-for="item in checkout.items">
        <div class="cart-positions__image-wrap">
          <img :src="item.imagePath"
               :alt="item.buyable.product.name"
               class="cart-positions__image"
               v-if="item.imagePath && item.buyable.product"
          >
          <img src="/static/images/base/noimage.png"
               alt="no-image"
               class="cart-positions__image"
               v-else
          >
        </div>

        <div class="cart-positions__name-wrap">
          <div v-if="item.buyable.name" class="cart-positions__name text text__small">
            {{ item.buyable.name }}
          </div>
          <div v-else class="cart-positions__name text text__small">
            {{ item.buyable.product.model.producer.name }}
            {{ item.buyable.product.model.name }}
            {{ item.buyable.product.name }}
          </div>
          <div class="cart-positions__item-price">
            <div class="cart-positions__price cart-positions__price_item text">
              {{ formatPrice(item.item_price) }} ₽
            </div>

            <div class="cart-positions__measure text text__mini">
              /шт
            </div>
          </div>
        </div>

        <div class="cart-positions__panel-wrap">
          <div class="cart-positions__panel">
            <button class="cart-positions__panel-button" @click="decrease({key: item.key})">
              <MinusIcon/>
            </button>
            <input type="text" class="cart-positions__panel-input"
                   v-model.number="item.quantity"
            />
            <button class="cart-positions__panel-button" @click="increase({key: item.key})">
              <PlusIcon/>
            </button>

            <div class="cart-positions__panel-message text" v-if="panelMessages[item.key]">
              {{ panelMessages[item.key] }}
            </div>
          </div>
        </div>

        <div class="cart-positions__info-wrap">
          <div class="cart-positions__vendor-code text text__mini" v-if="item.buyable.product.articles">
            Артикулы: {{ item.buyable.product.articles }}
          </div>

          <div class="cart-positions__delivery text text__mini">
            {{ item.buyable.deliveryName }}
          </div>
        </div>

        <div class="cart-positions__price-wrap">
          <div class="cart-positions__price cart-positions__price_total text">
            {{ formatPrice(item.price)}} ₽
          </div>
        </div>

        <div class="cart-positions__buttons-wrap">
          <a href="javascript:void(0);"
             data-favorites-toggle
             :data-chosen="item.buyable.product_class"
             :data-id="item.buyable.product.id"
             class="cart-positions__button-icon to-favorite"
             :class="{'to-favorite_selected': isFavorite(item)}">
            <FavoritesIcon/>
          </a>
          <a href="javascript:void(0);" class="cart-positions__button-icon" @click="remove({key: item.key})">
            <CloseIcon/>
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import CloseIcon from "../icons/CloseIcon.vue";
import FavoritesIcon from "../icons/FavoritesIcon.vue";
import MinusIcon from "../icons/MinusIcon.vue";
import PlusIcon from "../icons/PlusIcon.vue";

export default {
  name: "TheCartPositions",
  components: {
    CloseIcon,
    FavoritesIcon,
    MinusIcon,
    PlusIcon
  },
  methods: {
    ...mapActions('cart', {
      'remove': 'remove',
      'increase': 'increase',
      'decrease': 'decrease',
    }),
    formatPrice: function(rawValue) {
      const value = parseInt(rawValue, 10);
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    isFavorite(item) {
      if (!this.favoritesCheckout || !this.favoritesCheckout.items.length) {
        return false;
      }

      const items = this.favoritesCheckout.items;
      const productClass = item.buyable.product_class;
      const productId = item.buyable.product.id;

      if (!items.find((favorite) => favorite.chosen_class === productClass && favorite.chosen_id === productId)) {
        return false;
      }

      return true;
    }
  },
  computed: {
    ...mapState('cart', {
      'checkout': 'checkout',
      'panelMessages': 'panelMessages'
    }),
    ...mapState('favorites', {
      'favoritesCheckout': 'checkout'
    }),
  },
}
</script>
