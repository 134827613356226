<template>
  <div class="slot-item" :class="{ _occupied: !freedom, '_has-records': hasRecords }">
    <div class="slot-item__verbose" @click.prevent="openRecordModal(slotPosts[0].id)">
      {{ verbose }}
    </div>
    <ul class="slot-item__post-list">
      <li class="slot-item__post-item" v-for="slotPost in slotPosts" @click.prevent="openRecordModal(slotPost.id)">
        <div class="slot-item__post-name">
          {{ slotPost.name }}
        </div>
        <div class="slot-item__freedom" v-if="!slotPost.record">
          Свободно
        </div>
        <div class="slot-item__record-name" v-else>
          {{ slotPost.record.contactInfo.name }}
        </div>
        <div class="slot-item__record" v-if="slotPost.record">
          <ul class="slot-item__record-list">
            <li class="slot-item__record-list-item">
              <div class="slot-item__record-list-item-key">
                Контактное лицо
              </div>
              <div class="slot-item__record-list-item-value">
                {{ slotPost.record.contactInfo.name }}
              </div>
            </li>
            <li class="slot-item__record-list-item" v-if="slotPost.record.contactInfo.phone">
              <div class="slot-item__record-list-item-key">
                Телефон
              </div>
              <div class="slot-item__record-list-item-value">
                {{ slotPost.record.contactInfo.phone }}
              </div>
            </li>
            <li class="slot-item__record-list-item">
              <div class="slot-item__record-list-item-key">
                Марка и гос.номер
              </div>
              <div class="slot-item__record-list-item-value">
                {{ slotPost.record.contactInfo.carAndLicensePlateNumber }}
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <Popup :opened="isModalOpened('record')" @close="closeModal('record')">
      <RecordModal
          :initial-posts="slotPosts"
          :initial-active-post-id="activePostSlotId"
          :date-time="dateTime"
          @close="closeModal('record')"
      />
    </Popup>
  </div>
</template>

<script>
import modalMixin from "../../../mixins/modal";
import Popup from "../Popup/Popup.vue";
import RecordModal from "../Record/RecordModal.vue";

export default {
  name: "SlotItem",
  props: {
    dateTime: String,
    verbose: String,
    freedom: Boolean,
    slotPosts: Array
  },
  components: {
    Popup,
    RecordModal
  },
  data() {
    return {
      showModal: false,
      activePostSlotId: undefined
    }
  },
  mixins: [
    modalMixin
  ],
  computed: {
    hasRecords() {
      let hasRecords = false;
      this.slotPosts.forEach((post) => {
        if (post.record) {
          hasRecords = true;
        }
      })
      return hasRecords;
    }
  },
  methods: {
    openRecordModal(postSlotId) {
      // this.activePostSlotId = postSlotId;
      // this.openModal('record');
    }
  }
}
</script>

<style lang="scss">
.slot-item {
  border: 1px solid var(--gray);
  border-radius: 3px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  position: relative;
  cursor: pointer;

  &._has-records {
    border-color: #2651e1;
    background-color: #edf4ff;

    .slot-item {
      &__verbose {
        color: #2651e1;
      }
    }
  }

  &._occupied {
    border-color: var(--red);
    background-color: var(--red-bg);

    .slot-item {
      &__verbose {
        color: var(--red);
      }
    }
  }

  &:hover {
    .slot-item {
      &__post-list {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__verbose {
  }

  &__post-list {
    display: flex;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: -65px;
    top: 100%;
    background-color: var(--black);
    min-width: 205px;
    z-index: 10;

    padding: 8px 0;
    border-radius: 3px;

    &:before {
      content: '';
      position: absolute;
      border: 12px solid transparent;
      border-bottom: 12px solid var(--black);
      top: -17px;
      left: 99px;

    }
  }

  &__post-item {
    padding: 6px 15px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    border-bottom: 1px solid var(--border);
    position: relative;

    &:first-child {
      border-top: 1px solid var(--border);
    }

    &:hover {
      .slot-item {
        &__record {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }

  &__post-name {
    color: var(--white-opacity);
    font-size: 13px;
  }

  &__freedom {
    border: 1px solid var(--green-border);
    color: var(--green-text);
    line-height: calc(14 / 12);
    font-size: 12px;
    border-radius: 3px;
    padding: 0 4px;
  }

  &__record-name {
    font-size: 12px;
    color: red;
  }

  &__record {
    top: -9px;
    opacity: 0;
    pointer-events: none;
    z-index: 10;
    position: absolute;
    left: 100%;
    min-width: 254px;
  }

  &__record-list {
    background-color: var(--black);
    border-radius: 3px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    margin-left: 5px;

    &:before {
      content: '';
      position: absolute;
      border: 12px solid transparent;
      border-right: 12px solid var(--black);
      top: 10px;
      left: -17px;
    }
  }

  &__record-list-item {
    display: flex;
    justify-content: space-between;
    padding: 6px 15px;
    font-size: 13px;

    border-bottom: 1px solid var(--border);

    &:first-child {
      border-top: 1px solid var(--border);
    }
  }

  &__record-list-item-key {
    flex: 0 0 40%;
    color: var(--white-opacity);
  }

  &__record-list-item-value {
    flex: 0 0 60%;
    color: #fff;
  }
}
</style>

<!--
slotPosts: [
        {
          id: Number,
          name: String,
          record: {
            type: Object,
            required: false
          }
        }
      ]
-->