import {onDomReady} from "../../components/dynamic/observer";
import {Autoplay, Navigation, Pagination, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    const element = document.querySelector('[data-hero-slider]');
    if (!element) {
        return false;
    }

    const slider = new Swiper(element, {
        modules: [Navigation, Autoplay, Pagination],
        speed: 700,
        loop: element.querySelectorAll('.swiper-slide').length > 1,
        watchOverflow: true,
        grabCursor: true,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: document.querySelector('[data-hero-slider-pagination]'),
        },
        navigation: {
            prevEl: document.querySelector('[data-hero-slider-left]'),
            nextEl: document.querySelector('[data-hero-slider-right]'),
        },
    });
}

onDomReady(init);