<template>
  <div class="record-service-point">
    <div class="record-service-point__select-wrap">
      <base-field-select
          :field-id="'address'"
          :values="serviceCenters"
          v-model="selectedId"
          :no-default="true"
          :disabled="serviceCenters.length <= 1"
          :class="{_inactive: serviceCenters.length <= 1}"
          @input="setFirstInit(false)"
      />
    </div>

<!--    <h4 class="record-service-point__title">-->
<!--      Дата и время-->
<!--    </h4>-->

    <div class="record-service-point__main">
      <div class="record-service-point__calendar">
        <TheDatePicker/>
      </div>

      <div class="record-service-point__time-wrap">
        <ul class="record-service-point__time-list">
          <li class="record-service-point__item"
              :class="slotClasses(slot)"
              v-for="(slot, key) in currentSlots"
          >
            <input type="radio"
                   name="times"
                   :id="`slot-`+key"
                   :value="slot.verbose"
                   :checked="isCheckedSlot(slot)"
            >
            <label class="record-service-point__item-link"
                   :for="`slot-`+key"
                   @click="setCurrentSlot(slot)"
            >
              {{ slot.verbose }}
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
    import TheDatePicker from "../parts/TheDatePicker.vue";
    import BaseFieldSelect from "../parts/fields/BaseFieldSelect.vue";
    import {mapGetters, mapMutations, mapState} from "vuex";

    export default {
        name: "TheScheduleServicePoint",
        components: {
            BaseFieldSelect,
            TheDatePicker,
        },
        data() {
          return {
            now: 0
          }
        },
        methods: {
            ...mapMutations('schedule', [
                'setSelectedServiceCenterId',
                'setSelectedPost',
                'setSelectedSlot',
                'setFirstInit'
            ]),
            setCurrentSlot(slot) {
                if (slot !== this.selectedSlot) {
                    this.setSelectedSlot(slot);
                }
            },
            input(id) {
                this.setSelectedServiceCenterId(parseInt(id, 10));
            },
            isEditableRecordSlot(slot) {
              if (this.editableRecord) {
                if (this.selectedServiceCenter.id === this.editableRecord.serviceCenterId
                  && slot.dateTime === this.editableRecord.date) {
                  return true;
                }
              }
              return false;
            },
            slotClasses(slot) {
              return {
                '_disabled' : !slot.freedom || this.isEditableRecordSlot(slot) || this.slotTimeIsPast(slot.dateTime),
                '_active': slot === this.selectedSlot || this.isEditableRecordSlot(slot)
              };
            },
            isCheckedSlot(slot) {
              if(slot === this.selectedSlot) {
                return true
              }

              return false;
            },
            slotTimeIsPast(slotDate) {
              const [dateValues, timeWithGmt] = slotDate.split('T');
              const [year, month, day] = dateValues.split('-'); // год, месяц, день
              const [time, gmt] = timeWithGmt.split('+'); // время, gmt
              const [hours, minutes, seconds] = time.split(':'); // часы, минуты, секунды
              const date = new Date(+year, month - 1, +day, +hours, +minutes, +seconds).getTime();

              const currentDatePlusHour = this.now + 1.5 * (60 * 60 * 1000);

              return currentDatePlusHour > date;
            },
            updateNow() {
              this.now = Date.now();
            }
        },
        computed: {
            ...mapState('schedule', [
                'selectedServiceCenterId',
                'graph',
                'selectedPost',
                'selectedSlot',
                'searchText',
                'editableRecord',
                'firstInit'
            ]),
            ...mapGetters('schedule', {
                selectedServiceCenter: 'selectedServiceCenter',
                currentSlots: "currentSlots"
            }),
            selectedId: {
                get() {
                    return this.selectedServiceCenterId;
                },
                set(value) {
                    this.setSelectedServiceCenterId(parseInt(value, 10));
                }
            },
            serviceCenters() {
                if (this.graph.serviceCenters) {
                    return this.graph.serviceCenters;
                }
                return [];
            },
        },
        mounted() {
          this.updateNow();
          setInterval(this.updateNow.bind(this) , (1000 * 60));
        }
    }
</script>

<style lang="scss">

</style>