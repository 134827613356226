<template>
  <div class="vue-field vue-field_input form-field"
       :class="{ '_has-errors': errors.length }"
  >
    <label :for="fieldId" class="vue-field__label required">
      {{ label }}
    </label>
    <input
        type="text"
        v-maska="'+7 (###) ###-##-##'"
        :id="fieldId"
        :name="fieldId"
        :value="modelValue"
        :placeholder="placeholder"
        class="vue-field__input required"
        :class="{ _filled: setFilledInput }"
        required="required"
        ref="input"
        inputmode="true"
        @input="$emit('update:modelValue', $event.target.value)"
    >
    <Errors :errors="errors"/>
  </div>
</template>

<script>
  import Errors from "../common/Errors.vue";
  import { maska } from 'maska'

  export default {
    name: 'BaseFieldPhone',
    components: {
      Errors,
    },
    directives: {
      maska
    },
    data() {
      return {
        value: '',
      }
    },
    props: {
      fieldId: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      modelValue: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        required: true,
      },
      errors: {
        type: Array,
        required: true,
      },
    },
    computed: {
      setFilledInput() {
        return !!this.value;
      }
    }
  };
</script>

<style scoped>

</style>