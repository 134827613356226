<template>
  <div class="cart-order-block cart-order-block_comment">
    <div class="cart-order-block__head">
      <div class="cart-order-block__number-wrap">
        <div class="cart-order-block__number">
          04
        </div>
      </div>
      <h3 class="cart-order-block__title">
        Комментарий к заказу
      </h3>
    </div>


    <div class="cart-order-block__content form-field">
      <label for="order_comment" class="cart-order-block__label text text__mini">
        На что стоит обратить внимание
      </label>
      <textarea
          name="comment"
          id="order_comment"
          cols="30"
          rows="10"
          class="cart-order-block__comment text text__small"
          placeholder="Комментарий"
          v-model="comment"
      >
      </textarea>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "TheCartOrderComment",
  computed: {
    comment: {
      get() {
        return this.$store.state.order.comment
      },
      set (value) {
        this.$store.commit('order/setComment', value)
      }
    },
  }
}
</script>

<style lang="scss">

</style>