import ProfileService from "../../services/profile.service";

const ProfileModule = {
  namespaced: true,
  state: {
    info: {}
  },
  actions: {
    async fetchInfo({ commit }) {
      try {
        const result = await ProfileService.fetchInfo();
        if (typeof result.data === 'object') {
          commit('setInfo', result.data)
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  mutations: {
    setInfo(state, value) {
      state.info = value
    },
  }
}

export default ProfileModule;