<template>
  <div class="chosen-fieldset" :class="blockClasses" ref="root">
    <div class="chosen-fieldset__fields"
         :class="elementClasses('fields')"
    >
      <div class="chosen-fieldset__field"
           :class="[...elementClasses('field'), ...elementClasses('field_' + field.key)]"
           v-for="field in fields"
      >
        <Field v-if="field.visible" :fieldKey="field.key" :filterKey="filterKey" @change="debouncedChange"/>
      </div>
    </div>
  </div>
</template>

<script>
  import {fieldsetMixin} from "./fieldsetMixin";
  import Field from "../field/Field.vue";
  import { FilterManagerPool } from "../../../vue/filter/manager";
  import { debounce } from "lodash";

  export default {
    name: "ChosenFieldset",
    components: {Field},
    mixins: [
      fieldsetMixin
    ],
    methods: {
      change() {
        if (this.fieldset.data.instantСhangeable) {
          this.manager.submit();
        }
      },
      debouncedChange: debounce(function() {
        this.change();
      }, 300),
    },
    mounted() {
      this.manager = FilterManagerPool.getManager(this.filterKey);
    },
  }
</script>

<style lang="scss">

</style>