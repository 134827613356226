import axios from 'axios';
import FileDownload from 'js-file-download'

const PriceCompetitorService = {
  async fetchCompetitors() {
    const url = '/price_competitor/api/competitor_list';
    return axios.get(url, {});
  },
  async fetchTyres(params) {
    const url = '/price_competitor/api/tyre_list';
    return axios.post(url, params);
  },
  async fetchWidths() {
    const url = '/price_competitor/api/widths';
    return axios.get(url, {});
  },
  async fetchHeights() {
    const url = '/price_competitor/api/heights';
    return axios.get(url, {});
  },
  async fetchDiameters() {
    const url = '/price_competitor/api/diameters';
    return axios.get(url, {});
  },
  async fetchSeasons() {
    const url = '/price_competitor/api/seasons';
    return axios.get(url, {});
  },
  async fetchProducers() {
    const url = '/price_competitor/api/producers';
    return axios.get(url, {});
  },
  async fetchHistoryPrices(params) {
    const url = '/price_competitor/api/history_prices';
    return axios.post(url, params);
  },
  async updateCompetitorPrice(data) {
    return axios.post('/price_competitor/api/update_competitor_price', data);
  },
  async createCompetitorPrice(data) {
    return axios.post('/price_competitor/api/create_competitor_price', data);
  },
  async createPrice(data) {
    return axios.post('/price_competitor/api/create_price', data);
  },
  async removePrice(data) {
    return axios.post('/price_competitor/api/remove_price', data);
  },
  async removeOldPrice(data) {
    return axios.post('/price_competitor/api/remove_old_price', data);
  },
  async createOldPrice(data) {
    return axios.post('/price_competitor/api/create_old_price', data);
  },
  async updateTracking(data) {
    return axios.post('/price_competitor/api/update_tracking', data);
  },
  async buildReport() {
    const url = '/price_competitor/api/build_report';
    return axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      FileDownload(response.data, 'report.xlsx')
    });
  },
};

export default PriceCompetitorService;