<template>
    <svg :width="size" :height="size" :viewBox="'0 0 ' + size + ' ' + size" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.65745 18.3848L7.07166 19.799L12.7285 14.1421L18.3854 19.799L19.7996 18.3848L14.1427 12.7279L19.7996 7.07107L18.3854 5.65685L12.7285 11.3137L7.07166 5.65686L5.65745 7.07107L11.3143 12.7279L5.65745 18.3848Z" :fill="fill"/>
    </svg>
</template>

<script>
  export default {
    name: "IconX",
    props: {
      fill: {
        type: String,
        required: false,
        default: '#CCCCCC'
      },
      size: {
        type: Number,
        required: false,
        default: 26
      }
    },
  }
</script>