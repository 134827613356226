import tinkoff from '@tcb-web/create-credit';
import {onDomReady} from "../components/dynamic/observer";

onDomReady(() => {
    window.tinkoff.methods.on(tinkoff.constants.SUCCESS, onMessage);
    window.tinkoff.methods.on(tinkoff.constants.REJECT, onMessage);
    window.tinkoff.methods.on(tinkoff.constants.CANCEL, onMessage);
    window.tinkoff.methods.on(tinkoff.constants.READY, onMessage);

    initCreditModal();
})
function onMessage(data) {
    console.log(data)
    switch (data.type) {
        case tinkoff.constants.SUCCESS:
            console.log('SUCCESS', data.meta.iframe.url);
            break;
        case tinkoff.constants.REJECT:
            console.log('REJECT', data.meta.iframe.url);
            break;
        case tinkoff.constants.CANCEL:
            console.log('CANCEL', data.meta.iframe.url);
            break;
        default:
            return;
    }

    window.tinkoff.methods.off(tinkoff.constants.SUCCESS, onMessage);
    window.tinkoff.methods.off(tinkoff.constants.REJECT, onMessage);
    window.tinkoff.methods.off(tinkoff.constants.CANCEL, onMessage);
    data.meta.iframe.destroy();
}

function initCreditModal() {
    setTimeout(() => {
        const button = document.querySelector('tinkoff-create-button');
        if (button) {
            button.handleClick();
        }
    }, 200)
}