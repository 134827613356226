<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m19.72 9.993-.543-.942-1.81.001 1.049-1.816-.544-.947h-1.088L15.19 9.052h-3.562l1.777-3.085h3.185l.544-.942-.544-.942h-2.1L15.69 2l-.544-.942h-1.079l-1.2 2.083-1.05-1.817h-1.089l-.544.943 1.592 2.76-1.78 3.083-1.782-3.085 1.591-2.759-.542-.942H8.175L7.126 3.14l-1.2-2.083h-1.09L4.291 2l1.2 2.083h-2.1l-.544.943.55.942h3.185l1.781 3.085H4.805l-1.6-2.763H2.12l-.544.942 1.05 1.821-2.13-.004-.22.942.224 1.067 2.13.004-1.05 1.817.544.943H3.21l1.6-2.759h3.558l-1.78 3.084H3.4l-.544.942.544.942h2.1l-1.206 2.084.544.943h1.09l1.2-2.083 1.05 1.816h1.088l.542-.942-1.59-2.759L10 12.004l1.781 3.085-1.592 2.76.544.942h1.088l1.048-1.817 1.2 2.083h1.088l.547-.943-1.2-2.083h2.1l.544-.942-.543-.943h-3.196l-1.78-3.085h3.561l1.592 2.76h1.088l.544-.943-1.048-1.816h1.81l.545-1.069Z" fill="#C1CDDF"/></svg>
</template>

<script>
  export default {
    name: "Snowflake"
  }
</script>

<style scoped>

</style>