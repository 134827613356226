import { onDomReady } from '../../components/dynamic/observer';

class Header {
    constructor(element) {
        this.header = element;
        this.hamburger = element.querySelector('[data-menu-button]');
        this.dropMenu = element.querySelector('[data-drop-menu]');
        this.contactMenuButton = element.querySelector('[data-contact-menu-button]')
        this.contactMenu = element.querySelector('[data-contact-menu]');
        this.mainMenuButton = element.querySelector('[data-main-menu-button]');
        this.mainMenu = element.querySelector('[data-main-menu]');
        this.isOpened = false;
        this.isContactMenuOpened = false;
        this.currentY = 0;
        this.lastY = 0;
        this.bind();
    }


    bind() {
        this.bindScroll();
        this.bindHamburger();
        this.bindContactMenuButton();
        this.bindMainMenuButton();
    }


    bindHamburger() {
        const instance = this;
        this.hamburger.addEventListener('click', (e) => {
            e.preventDefault();
            instance.isOpened ? instance.closeMenu() : instance.openMenu();
        });
    }

    bindContactMenuButton() {
        const instance = this;
        this.contactMenuButton.addEventListener('click', (e) => {
            e.preventDefault();
            instance.isContactMenuOpened ? instance.closeContactMenu() : instance.openContactMenu();
        });
    }

    bindMainMenuButton() {
        const instance = this;
        this.mainMenuButton.addEventListener('click', (e) => {
            this.mainMenu.classList.toggle('_open');
            this.mainMenuButton.classList.toggle('_open');
            if (document.querySelector('.content-header')) {
                document.querySelector('.content-header').classList.toggle('_menu-open');
            }
            document.querySelector('.layout').classList.toggle('_menu-open');
        });
    }

    openMenu() {
        if (this.isContactMenuOpened) {
            this.closeContactMenu();
            return;
        }

        this.header.classList.add('_menu-opened');
        this.isOpened = true;
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100%';
    }

    closeMenu() {
        this.header.classList.remove('_menu-opened');
        this.isOpened = false;
        document.body.style.overflow = '';
        document.body.style.height = '';
    }

    openContactMenu() {
        this.contactMenu.classList.add('_menu-opened');
        this.isContactMenuOpened = true;
        this.contactMenuButton.classList.add('_opened')
        this.hamburger.classList.add('_menu-opened');
    }

    closeContactMenu() {
        this.contactMenu.classList.remove('_menu-opened');
        this.isContactMenuOpened = false;
        this.contactMenuButton.classList.remove('_opened')
        this.hamburger.classList.remove('_menu-opened');
    }

    bindScroll() {
        const instance = this;
        window.addEventListener('scroll', () => {
            if (window.pageYOffset >= 100) {
                instance.header.classList.add('header_transform');
            } else {
                instance.header.classList.remove('header_transform');
            }
        });
    }
}

onDomReady(() => {
    setTimeout(() => {
        const header = document.querySelector('[data-header]');
        if (header) {
            new Header(header);
        }
    }, 1000);
});
