<template>
  <div class="product-added">
    <h2 class="product-added__title">
      Товар добавлен в корзину
    </h2>

    <div class="product-added__main">
      <TheCartAddedProductCard :product="addedProduct" v-if="addedProduct"/>
    </div>

    <div class="product-added__already-in-cart" v-if="itemsWithoutAddedProduct.length">
      <h4 class="product-added__already-in-cart-title">
        Уже в корзине:
      </h4>
      <ul class="product-added__already-in-cart-list">
        <li class="product-added__already-in-cart-item" v-for="item in itemsWithoutAddedProduct">
          <TheCartAddedProductCard :product="item"/>
        </li>
      </ul>
    </div>

    <div class="product-added__bottom">
      <div class="product-added__bottom-info">
        <div class="product-added__total-items text__small">
          В корзине {{ totalCount }} товаров(а)
        </div>

        <div class="product-added__total-price text__small text__small_medium">
          На сумму {{ formatPrice(itemsPrice) }} ₽
        </div>
      </div>

      <div class="product-added__buttons">
        <a href="javascript:void(0)"
           title="Продолжить покупки"
           class="product-added__close button button_secondary"
           data-modal-close
        >
          Продолжить покупки
        </a>

        <a href="/cart"
           title="Перейти в корзину"
           class="product-added__to-cart button button_primary"
           data-goal-yandex-click="go_to_the_shopping_cart"
           data-goal-google-click="cart#go"
           data-goal-vk-click="go_to_the_shopping_cart"
        >
          Перейти в корзину
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapState} from "vuex";
  import TheCartAddedProductCard from "./TheCartAddedProductCard.vue";

  export default {
    name: "TheCartAddedProduct",
    components: {TheCartAddedProductCard},
    props: {
      buyableId: null,
    },
    computed: {
      ...mapState('cart', {
        'checkout': 'checkout',
        'panelMessages': 'panelMessages'
      }),
      ...mapGetters('cart', {
        'totalCount': 'totalCount',
        'itemsPrice': 'itemsPrice',
      }),
      addedProduct() {
        const product = this.checkout.items.find((item) => item.buyable_id === parseInt(this.buyableId, 10));
        return product ? product : null;
      },
      itemsWithoutAddedProduct() {
        return this.checkout.items.filter((item) => item.buyable_id !== parseInt(this.buyableId, 10));
      }
    },
    methods: {
      ...mapActions('cart', {
        'remove': 'remove',
        'increase': 'increase',
        'decrease': 'decrease',
      }),
      formatPrice: function(rawValue) {
        const value = parseInt(rawValue, 10);
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      },
    }
  }
</script>

<style scoped>

</style>