<template>
  <div class="price-competitor-main" :ref="'main'">
    <div class="price-competitor-main__heading-title">
      Шина
    </div>

    <!--  Первый строчка в таблице -->
    <div class="price-competitor-main__heading-columns">
      <div class="price-competitor-main__heading-column">Цена на n.shlepakov.ru</div>
      <div class="price-competitor-main__heading-column"
           v-for="competitor in filteredCompetitors"
      >
        {{ competitor.name }}
      </div>
    </div>

    <!--  Первый столбик с названиями шин  -->
    <div class="price-competitor-main__heading-rows">
      <div class="price-competitor-main__heading-row"
           v-for="tyre in sortedTyres"
      >
        <div class="price-competitor-main__heading-row-title">
          {{ tyre.name }}
        </div>

        <div class="price-competitor-main__tracked">

          <input type="checkbox"
                 :id="`tracked-${tyre.id}`"
                 class="price-competitor-main__matched-input"
                 :checked="tyre.tracked"
                 @change="handleTracked($event, tyre.id)"
          >
          <label :for="`tracked-${tyre.id}`">
            Отслеживается
          </label>
        </div>
      </div>
    </div>

    <!--  Таблица с шинами  -->
    <ul class="price-competitor-main__data">
      <li class="price-competitor-main__item"
          v-for="tyre in sortedTyres"
      >
        <!--  Первый столбик  -->
        <div class="price-competitor-main__item-column">
          <div class="price-competitor-main__price">
            Цена: {{ tyre.price }} ₽
          </div>
          <div class="price-competitor-main__coment">
            Комментарий: {{ tyre.comment }}
          </div>
          <div class="price-competitor-main__price-date">
            Дата обновления: {{ tyre.lastUpdatePrice }}
          </div>

<!--          <div class="price-competitor-main__form price-competitor-main__price-form">-->
<!--            <div class="price-competitor-main__price-input-wrap">-->
<!--              <label for="new-price">Новая цена</label>-->
<!--              <input type="number" id="new-price" :value="tyre.manualPrice" :ref="'manualPriceInput-' + tyre.id">-->
<!--            </div>-->

<!--            <div class="price-competitor-main__price-buttons">-->
<!--              <button class="price-competitor-main__price-button price-competitor-main__price-submit"-->
<!--                      @click="saveManualPrice($event, tyre.id)"-->
<!--              >-->
<!--                <GreenTick/>-->
<!--              </button>-->
<!--              <button v-if="tyre.manualPrice" class="price-competitor-main__price-button price-competitor-main__price-remove"-->
<!--                      @click="removeManualPrice($event, tyre.id)"-->
<!--                      :ref="'removePriceButton-' + tyre.id"-->
<!--              >-->
<!--                <RedCross/>-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="price-competitor-main__form price-competitor-main__price-form price-competitor-main__price-form_old">-->
<!--            <div class="price-competitor-main__price-input-wrap">-->
<!--              <label for="old-price">Старая цена</label>-->
<!--              <input type="number" id="old-price" :value="tyre.oldManualPrice" :ref="'manualOldPriceInput-' + tyre.id">-->
<!--            </div>-->

<!--            <div class="price-competitor-main__price-buttons">-->
<!--              <button class="price-competitor-main__price-button price-competitor-main__price-submit"-->
<!--                      @click="saveOldManualPrice($event, tyre.id)"-->
<!--              >-->
<!--                <GreenTick/>-->
<!--              </button>-->
<!--              <button v-if="tyre.oldManualPrice" class="price-competitor-main__price-button price-competitor-main__price-remove"-->
<!--                      @click="removeOldManualPrice($event, tyre.id)"-->
<!--                      :ref="'removeOldPriceButton-' + tyre.id"-->
<!--              >-->
<!--                <RedCross/>-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <!--  Подбор порядка цен конкурентов в зависимости от порядка конкурентов в первой строке таблицы  -->
        <div class="price-competitor-main__item-column"
             v-for="competitor in filteredCompetitors"
             :class="{
               '_green': getTyreCompetitorPriceObjByCompetitorId(tyre.id, competitor.id).price > tyre.price,
               '_red': getTyreCompetitorPriceObjByCompetitorId(tyre.id, competitor.id).price < tyre.price,
               '_yellow': getTyreCompetitorPriceObjByCompetitorId(tyre.id, competitor.id).isFailed
             }"
        >
          <div class="price-competitor-main__price">
            <div class="price-competitor-main__price-title">Цена:&nbsp;</div>
            <div class="price-competitor-main__price-value">
              {{ getTyreCompetitorPriceObjByCompetitorId(tyre.id, competitor.id).price }} ₽
            </div>
          </div>
          <div class="price-competitor-main__price-date">
            <div class="price-competitor-main__price-date-title">Дата обновления:</div>
            <div class="price-competitor-main__price-date-value">
              {{ getTyreCompetitorPriceObjByCompetitorId(tyre.id, competitor.id).updatedAt }}
            </div>
          </div>
          <div class="price-competitor-main__source-type">
            <div class="price-competitor-main__source-type-title">Кто установил:&nbsp;</div>
            <div class="price-competitor-main__source-type-value">
              {{ getTyreCompetitorPriceObjByCompetitorId(tyre.id, competitor.id).sourceType }}
            </div>
          </div>

          <a :href="getTyreCompetitorPriceObjByCompetitorId(tyre.id, competitor.id).url"
             class="price-competitor-main__tyre-link"
             target="_blank"
             v-if="getTyreCompetitorPriceObjByCompetitorId(tyre.id, competitor.id)"
          >
            <span class="price-competitor-main__tyre-link-title">
              Ссылка на продукт
            </span>
          </a>

          <form class="price-competitor-main__form"
                @submit.prevent="submitCompetitorActualPrice($event, tyre.id, competitor.id)"
          >
            <label for="new-price">Новая цена конкурента</label>
            <input type="number" id="new-price">
            <label for="competitor-tyre-link">Ссылка на продукт</label>
            <input type="text" id="competitor-tyre-link">
            <button class="price-competitor-main__submit">Применить</button>
          </form>

        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import ThePriceCompetitorHistoryPrices from "./ThePriceCompetitorHistoryPrices";
import RedCross from "../icons/RedCross.vue";
import GreenTick from "../icons/GreenTick.vue";

export default {
  name: "ThePriceCompetitorMain",
  components: {GreenTick, ThePriceCompetitorHistoryPrices, RedCross},
  data() {
    return {
      isLoading: false,
      showHistoryPricesModal: false,
      modalTitle: '',
      historyPricesData: [],
    }
  },
  computed: {
    ...mapState('priceCompetitorItems', [
        'competitors',
        'tyres',
    ]),
    ...mapState('priceCompetitorFilter', [
        'settings',
        'isLastPage',
    ]),
    sortedTyres() {
      this.tyres.forEach((tyre) => {
        tyre.competitorPrices = this.sortCompetitorPricesByCompetitors(tyre.competitorPrices)
      })
      return this.tyres;
    },
    historyPrices() {
      return this.historyPricesData;
    },
    filteredCompetitors() {
      const competitorId = parseInt(this.settings.competitor, 10);
      if (competitorId) {
        return this.competitors.filter(competitor => competitor.id === competitorId);
      }
      return this.competitors;
    },
  },
  methods: {
    ...mapMutations('priceCompetitorFilter', [
        'setPage'
    ]),
    ...mapActions('priceCompetitorItems', [
        'fetchTyres',
        'debounceFetchTyres',
        'fetchHistoryPrices',
        'updateCompetitorPrice',
        'createCompetitorPrice',
        'createPrice',
        'createOldPrice',
        'removePrice',
        'removeOldPrice',
        'updateTracking'
    ]),
    sortCompetitorPricesByCompetitors(competitorPrices) {
      // Формируем вспомогательный массив из `id` первого массива.
      const order = this.competitors.map(item => item.id);

      // Сортируем второй массив согласно индексам вспомогательного.
      competitorPrices.sort((a, b) => order.indexOf(a.competitorId) - order.indexOf(b.competitorId));

      return competitorPrices;
    },
    // Получаем цену поставщика по id поставщика.
    getTyreCompetitorPriceObjByCompetitorId(tyreId, competitorId) {
      const competitorPrices = this.tyres
          .find((item) => item.id === tyreId).competitorPrices
          .filter((price) => price.competitorId === competitorId);

      let lastDate = null;
      competitorPrices.map((item) => {
        const date = Date.parse(item.updatedAt);
        if (date > lastDate) {
          lastDate = date;
        }
      });

      const priceObj = competitorPrices.find((price) => Date.parse(price.updatedAt) === lastDate);

      return priceObj ? priceObj : false;
    },
    autoPagination() {
      window.addEventListener('scroll', (event) => {
        const bounds = this.$refs.main.getBoundingClientRect();
        if (bounds.bottom < window.innerHeight + window.innerHeight / 2) {
          if (!this.isLastPage) {
            this.debounceFetchTyres();
          }
        }
      });
    },
    openHistoryPricesModal(e) {
      this.isLoading = true;
      this.showHistoryPricesModal = true;
      this.modalTitle = e.target.dataset.tyreName;

      this.fetchHistoryPrices({
        'tyreId': parseInt(e.target.dataset.tyreId, 10),
        'competitorId': parseInt(e.target.dataset.competitorId, 10)
      })
        .then((r) => {
          this.isLoading = false;
          this.historyPricesData = r;
        })
    },
    closeHistoryPricesModal() {
      this.showHistoryPricesModal = false;
      this.modalTitle = '';
      this.historyPricesData = [];
    },
    submitCompetitorActualPrice(e, tyreId, competitorId) {
      const priceInput = e.target[0];
      const linkInput = e.target[1];
      const price = parseInt(priceInput.value, 10);
      const link = linkInput.value;

      if (!price) {
        priceInput.classList.add('_error');
      } else {
        priceInput.classList.remove('_error');
      }

      if (!link) {
        linkInput.classList.add('_error');
      } else {
        linkInput.classList.remove('_error');
      }

      if (price && link) {
        this.createCompetitorPrice({
          tyreId: tyreId,
          competitorId: competitorId,
          price: price,
          url: link
        }).then((r) => {
          if (r === 'success') {
            priceInput.value = '';
            linkInput.value = '';
          }
        });
      }
    },
    saveManualPrice(e, tyreId) {
      const inputRefName =  `manualPriceInput-${tyreId}`;
      const priceInput = this.$refs[inputRefName][0];
      const priceValue = parseInt(priceInput.value, 10);
      if (!priceInput.value) {
        priceInput.classList.add('_error');
      } else {
        priceInput.classList.remove('_error');

        this.createPrice({tyreId: tyreId, price: priceValue});
      }
    },
    removeManualPrice(e, tyreId) {
      const inputRefName =  `manualPriceInput-${tyreId}`;
      const removeButtonRefName = `removePriceButton-${tyreId}`
      const priceInput = this.$refs[inputRefName][0];
      const priceValue = parseInt(priceInput.value, 10);
      if (!priceInput.value) {
        priceInput.classList.add('_error');
      } else {
        priceInput.classList.remove('_error');

        this.removePrice({tyreId: tyreId, price: priceValue});

        priceInput.value = '';

        this.$refs[removeButtonRefName][0].style.display = 'none';
      }
    },
    saveOldManualPrice(e, tyreId) {
      const inputRefName =  `manualOldPriceInput-${tyreId}`;
      const priceInput = this.$refs[inputRefName][0];
      const priceValue = parseInt(priceInput.value, 10);
      if (!priceInput.value) {
        priceInput.classList.add('_error');
      } else {
        priceInput.classList.remove('_error');

        this.createOldPrice({tyreId: tyreId, price: priceValue});
      }
    },
    removeOldManualPrice(e, tyreId) {
      const inputRefName =  `manualOldPriceInput-${tyreId}`;
      const removeButtonRefName = `removeOldPriceButton-${tyreId}`
      const priceInput = this.$refs[inputRefName][0];
      const priceValue = parseInt(priceInput.value, 10);
      if (!priceInput.value) {
        priceInput.classList.add('_error');
      } else {
        priceInput.classList.remove('_error');

        this.removeOldPrice({tyreId: tyreId, price: priceValue});

        priceInput.value = '';

        this.$refs[removeButtonRefName][0].style.display = 'none';
      }
    },
    handleTracked(e, tyreId) {
      this.updateTracking({tyreId: tyreId, isTracked: e.target.checked});
    },
  },
  mounted() {
    this.autoPagination();
  }
}
</script>

<style scoped>

</style>