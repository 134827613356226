<template>
  <div class="cart-order-map">
    <div class="cart-order-map__map" data-cart-map></div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "TheCartOrderMap",
  data() {
    return {
      element: null
    }
  },
  components: {
  },
  computed: {
    ...mapState('pickupPoints', {
      'points': 'points',
      'pointsLoaded': 'loaded'
    }),
    ...mapState('order', {
      'pickupPoint': 'pickupPoint'
    })
  },
  methods: {
    initMap() {
      this.element = document.querySelector('[data-cart-map]')
      document.dispatchEvent(new CustomEvent('pickupPoint:map:init', {
        detail: {
          points: this.points,
          element: this.element
        }
      }))
    },
    initMapPending() {
      if (window.ymaps && this.pointsLoaded) {
        this.initMap();
      } else {
        setTimeout(() => {
          this.initMapPending();
        }, 500)
      }
    }
  },
  mounted() {
    this.initMapPending();
  },
  watch: {
    pickupPoint: function (value) {
      if (!this.element) {
        return;
      }
      this.element.dispatchEvent(new CustomEvent('pickupPoint:point:changed', {detail: {value}}))
    }
  }
}
</script>