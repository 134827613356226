<template>
  <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="10" width="2" height="10" transform="rotate(90 10 0)" fill="#1D202C"/>
  </svg>
</template>

<script>
export default {
  name: "MinusIcon"
}
</script>
