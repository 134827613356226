export default class Office {
  constructor(listItemElement) {
    this.settings = Object.assign({
      lat: 0,
      lng: 0,
      type: '',
      zoom: undefined,
      opened: undefined
    }, listItemElement.dataset);

    this.placemark = undefined;

    this.listItemElement = listItemElement;

    this.link = this.listItemElement.querySelector('[data-list-item-link]');

    this.block = this.listItemElement.querySelector('[data-list-item-block]');
    this.content = this.block.children[0];

    this.routeLink = this.listItemElement.querySelector('[data-list-item-route-link]');
    this.setRouteLinkHref();

    this.defineHeight();
    this.eventListeners();

    if (this.settings.opened) {
      this.show();
    }
  }

  getLat() {
    return parseFloat(this.settings.lat);
  }

  getLng() {
    return parseFloat(this.settings.lng);
  }

  show() {
    this.block.style.height = `${this.targetHeight}px`;
    this.listItemElement.classList.add('_opened');
  }

  hide() {
    this.block.style.height = '';
    this.listItemElement.classList.remove('_opened');
  }

  setPlacemark(placemark) {
    this.placemark = placemark;
  }

  getPlacemark() {
    return this.placemark;
  }

  defineHeight() {
    this.targetHeight = this.content.clientHeight;
  }

  setRouteLinkHref() {
    if (this.getLat() && this.getLng()) {
      this.routeLink.href = `https://yandex.ru/maps/?rtext=~${this.getLat()},${this.getLng()}&rtt=mt`;
    }
  }

  eventListeners() {
    this.link.addEventListener('click', (e) => {
      e.preventDefault();
      this.show();
    });
  }
}