<template>
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 1H1V3H2C2.75845 3 3.1949 3.10911 3.29289 3.20711C3.67086 3.58508 4 4.35306 4 5L4.01005 5.14142L4.98992 12.0005C3.35036 12.0856 2.0802 13.3588 2.00123 14.9504L2 16C2.09682 17.6439 3.36275 18.9085 4.94324 18.9984L5.17026 18.9987C5.58174 20.1646 6.69331 21 8 21C9.30622 21 10.4175 20.1652 10.8293 19H13.1707C13.5825 20.1652 14.6938 21 16 21C17.6569 21 19 19.6569 19 18C19 16.3431 17.6569 15 16 15C14.6938 15 13.5825 15.8348 13.1707 17H10.8293C10.4175 15.8348 9.30622 15 8 15C6.69378 15 5.58254 15.8348 5.17071 17H5C4.49001 16.9694 4.03254 16.5124 3.99821 15.9401L4 15C4.02697 14.4813 4.48131 14.027 5.04955 13.9988L7.02369 13.9993L7.038 14H17.1169L17.2645 13.9532C18.0227 13.7126 18.6296 13.1416 18.917 12.4025L19.0224 12.194L19.3647 11.5159C19.7188 10.8139 20.0729 10.1108 20.418 9.4238C21.2632 7.74107 21.7948 6.67166 21.9125 6.40906C22.5171 5.06045 21.2924 4.02246 20.0407 4.00036L6.07125 3.00254C5.89365 3.00216 5.72244 3.02226 5.55976 3.06048C5.33674 2.56893 5.04216 2.12794 4.70711 1.79289C4.13843 1.22422 3.24155 1 2 1ZM7.11287 12H16.7694C16.9028 11.9274 17.0065 11.8078 17.0588 11.6622L17.1076 11.5488L17.2371 11.2923L17.579 10.6151C17.9326 9.91405 18.2863 9.21186 18.6163 8.55493L18.6308 8.52609C19.2089 7.37516 19.6505 6.49032 19.8934 5.99493L6.01026 5.00073L6.99015 11.86C6.99973 11.9278 7.04866 11.9822 7.11287 12ZM17 18C17 18.5523 16.5523 19 16 19C15.4477 19 15 18.5523 15 18C15 17.4477 15.4477 17 16 17C16.5523 17 17 17.4477 17 18ZM8 19C8.55228 19 9 18.5523 9 18C9 17.4477 8.55228 17 8 17C7.44772 17 7 17.4477 7 18C7 18.5523 7.44772 19 8 19Z" fill="#1D202C"/>
  </svg>
</template>

<script>
export default {
  name: "CartIcon"
}
</script>
