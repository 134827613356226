import axios from 'axios';

const OrderService = {
  async save(orderData) {
    return axios.post('/order/api/save', orderData);
  },
  async fastSave(orderData) {
    return axios.post('/order/api/save/fast', orderData);
  },
};

export default OrderService;