<template>
  <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 1.5L7.5 6.49414L2.5 11.5" stroke="#FF5924" stroke-width="2" stroke-linecap="square"/>
  </svg>
</template>

<script>
export default {
  name: "ArrowNext"
}
</script>
