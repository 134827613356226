<template>
    <ul class="markup-errors" v-if="errors && errors.length > 0">
        <li v-for="error in errors" class="markup-errors__error">
            {{ error }}
        </li>
    </ul>
</template>

<script>
export default {
  name: "ItemErrors",
  props: ['errors']
}
</script>

<style lang="scss">
    .markup-errors {
      color: red;
      list-style: none;
      margin: 3px 0 5px;
      padding: 0;

      &:empty {
        margin-bottom: 0;
      }
    }
</style>