<template>
  <div class="product-added-card">
    <div class="product-added-card__image-wrap">
      <picture class="product-added-card__picture" v-if="product.imagePath">
        <img :src="product.imagePath" alt="product-added-image" class="product-added-card__image">
      </picture>
      <div class="product-added-card__picture" v-else>
        <img src="/static/images/base/noimage.png"
             alt="no-image"
             class="product-added-card__img"
        >
      </div>
    </div>

    <div class="product-added-card__name-wrap">
      <div v-if="product.buyable.name" class="product-added-card__name text text__small">
        {{ product.buyable.name}}
      </div>
      <div v-else class="product-added-card__name-wrap">
        {{ product.buyable.product.model.producer.name }}
        {{ product.buyable.product.model.name }}
        {{ product.buyable.product.name }}
      </div>
      <div class="product-added-card__item-price">
        <h4 class="product-added-card__price product-added-card__price_item">
          {{ formatPrice(product.item_price) }} ₽
        </h4>

        <div class="product-added-card__measure text text__mini">
          /шт
        </div>
      </div>
    </div>

    <div class="product-added-card__panel-wrap">
      <div class="product-added-card__panel">

        <button class="product-added-card__panel-button" @click="decrease({key: product.key})">
          <MinusIcon/>
        </button>

        <input type="text" class="product-added-card__panel-input"
               v-model.number="product.quantity"
        />
        <button class="product-added-card__panel-button" @click="increase({key: product.key})">
          <PlusIcon/>
        </button>

        <div class="product-added-card__panel-message text" v-if="panelMessages[product.key]">
          {{ panelMessages[product.key] }}
        </div>
      </div>
    </div>

    <div class="product-added-card__price-wrap">
      <h3 class="product-added-card__price product-added-card__price_total">
        {{ formatPrice(product.price)}} ₽
      </h3>
    </div>

    <a href="javascript:void(0);"
       class="product-added-card__remove"
       @click="remove({key: product.key})"
    >
      <CloseIcon/>
    </a>
  </div>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  import MinusIcon from "../icons/MinusIcon.vue";
  import PlusIcon from "../icons/PlusIcon.vue";
  import CloseIcon from "../icons/CloseIcon.vue";

  export default {
    name: "TheCartAddedProductCard",
    components: {
      MinusIcon,
      PlusIcon,
      CloseIcon
    },
    props: {
      product: null,
      picture: null
    },
    computed: {
      ...mapState('cart', {
        'panelMessages': 'panelMessages'
      }),
    },
    methods: {
      ...mapActions('cart', {
        'remove': 'remove',
        'increase': 'increase',
        'decrease': 'decrease',
      }),
      formatPrice: function(rawValue) {
        const value = parseInt(rawValue, 10);
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      },
    }
  }
</script>

<style scoped>

</style>