<template>
  <div class="cart-order-block cart-order-block_contacts">
    <div class="cart-order-block__head">
      <div class="cart-order-block__number-wrap">
        <div class="cart-order-block__number">
          01
        </div>
      </div>
      <h3 class="cart-order-block__title">
        Контактная информация
      </h3>
    </div>


    <div class="cart-order-block__content form-field">
      <ul class="tabs tabs_choice cart-order-block__tabs">
        <li class="tabs__item" :class="{'_active': isPhysical}">
          <a href="javascript:void(0);" class="tabs__link" title="Физ. лицо"  @click="setPhysical">
            Физ. лицо
          </a>
        </li>
        <li class="tabs__item" :class="{'_active': isLegal}">
          <a href="javascript:void(0);" class="tabs__link" title="Юр. лицо" @click="setLegal">
            Юр. лицо
          </a>
        </li>
      </ul>

      <ul class="cart-order-block__fields">
        <li class="cart-order-block__field">
          <label for="order_name" class="cart-order-block__label">
            Ваше имя*
          </label>
          <input type="text" id="order_name" class="cart-order-block__input" v-model="name">
          <ItemErrors :errors="errors.name"/>
        </li>
        <li class="cart-order-block__field">
          <label for="order_phone" class="cart-order-block__label">
            Телефон*
          </label>

          <input
              type="text"
              v-model="phone"
              id="order_phone"
              name="phone"
              required="required"
              class="cart-contacts__input required"
              ref="phone"
          >

          <ItemErrors :errors="errors.phone"/>
        </li>
        <li class="cart-order-block__field">
          <label for="order_email" class="cart-order-block__label">
            E-mail
          </label>
          <input type="text" id="order_email" class="cart-order-block__input" v-model="email">
          <ItemErrors :errors="errors.email"/>
        </li>
        <li class="cart-order-block__field" v-if="isLegal">
          <label for="order_organization" class="cart-order-block__label">
            Название организации
          </label>
          <input type="text" id="order_organization" class="cart-order-block__input" v-model="organization" required="required">
          <ItemErrors :errors="errors.organization"/>
        </li>
      </ul>

      <div class="cart-order-block__info text text__mini">
        * Поля со звездочкой обязательны
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import ItemErrors from "./ItemErrors";
import {makeMask} from "../../../js/field/phone";

export default {
  name: "TheCartOrderContacts",
  components: {
    ItemErrors
  },
  mounted() {
    const phoneInput = this.$refs.phone;
    makeMask(phoneInput);
  },
  computed: {
    clientType: {
      get () {
        return this.$store.state.order.clientType
      },
      set (value) {
        this.$store.commit('order/setClientType', value)
      }
    },
    name: {
      get () {
        return this.$store.state.order.name
      },
      set (value) {
        this.$store.commit('order/setName', value)
      }
    },
    phone: {
      get () {
        return this.$store.state.order.phone
      },
      set (value) {
        this.$store.commit('order/setPhone', value)
      }
    },
    email: {
      get () {
        return this.$store.state.order.email
      },
      set (value) {
        this.$store.commit('order/setEmail', value)
      }
    },
    organization: {
      get () {
        return this.$store.state.order.organization
      },
      set (value) {
        this.$store.commit('order/setOrganization', value)
      }
    },
    errors: {
      get () {
        return this.$store.state.order.errors
      },
    },
    ...mapGetters('order', {
      'isPhysical': 'isPhysical',
      'isLegal': 'isLegal',
    }),
    ...mapState('profile', {
      'profileInfo': 'info'
    })
  },
  methods: {
    setPhysical() {
      this.clientType = 10;
    },
    setLegal() {
      this.clientType = 20;
    }
  },
  watch: {
    profileInfo: function (info) {
      this.name = info.name;
      this.email = info.email;
      this.phone = info.phone;
    }
  }
}
</script>

<style lang="scss">

</style>