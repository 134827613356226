import {onDomReady} from "../../components/dynamic/observer";
import CompanyHistoryItem from "./objects/CompanyHistoryItem";

class Parallax {
    offsetY = 0;
    lastOffsetY = 0;

    constructor() {
        this.collect();
        this.eventListeners();
        this.update();
    }

    collect() {
        this.collectCompanyHistoryItems()
    }

    collectCompanyHistoryItems() {
        const images = Array.from(document.querySelectorAll('[data-company-history-item-image]'));
        this.companyHistoryItems = [];
        Array.from(document.querySelectorAll('[data-company-history-item]'))
            .forEach((container) => {
                const containerId = parseInt(container.getAttribute('data-company-history-item'), 10);
                const image = images
                    .find(image => parseInt(image.getAttribute('data-company-history-item-image'), 10) === containerId);

                this.companyHistoryItems.push(
                    new CompanyHistoryItem(
                        container,
                        {image: image}
                    )
                );
        });

    }

    update() {
        if (this.lastOffsetY !== this.offsetY) {
            this.companyHistoryItems.forEach((companyHistoryItem) => {
                companyHistoryItem.update(this.offsetY);
            });

            this.lastOffsetY = this.offsetY;
        }

        window.requestAnimationFrame(this.update.bind(this));
    }

    eventListeners() {
        const instance = this;

        document.addEventListener('scroll', () => {
            instance.offsetY = window.pageYOffset;
        });
    }
}

onDomReady(() => {
    new Parallax();
});
