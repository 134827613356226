<template>
    <div class="preloader">
        <div class="preloader__content">
            <div class="preloader__tyre"></div>
            <div class="preloader__name">
                Загрузка
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "Preloader",
    components: {

    },
    props: {

    }
  }
</script>

<style lang="scss">
    .preloader {
        z-index: 100000;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--white-opacity);

        &__content {
            min-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        &__name {
            margin-top: 20px;
            font-size: 25px;
        }

        &__tyre {
            height: 300px;
            width: 300px;
            background: {
                image: url("../../../../images/base/preloader.png");
                size: contain;
                repeat: no-repeat;
                position: center;
            };

            animation: rotation 5s linear infinite;
        }
    }

    @keyframes rotation {
        0% {
            transform:rotate(0deg);
        }
        100% {
            transform:rotate(360deg);
        }
    }
</style>