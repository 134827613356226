<template>
  <div class="record-details">
    <div class="record-details__top">
      <ul class="record-details__top-fields">
        <li class="record-details__field"
            v-for="(field, name) in this.fields"
        >
          <base-field-select
              v-if="name === 'width'"
              :field-id="name"
              :values="field"
              :label="'Ширина'"
              v-model="details.formWidth"
              :errors="details.errors.widthError"
          />

          <base-field-select
              v-if="name === 'height'"
              :field-id="name"
              :values="field"
              :label="'Высота'"
              :no-default="true"
              v-model="details.formHeight"
              :errors="details.errors.heightError"
          />

          <base-field-select
              v-if="name === 'diameter'"
              :field-id="name"
              :values="field"
              :label="'Диаметр'"
              :no-default="true"
              v-model="details.formDiameter"
              :errors="details.errors.diameterError"
          />
        </li>
      </ul>
    </div>
    <div class="record-details__bottom">
      <div class="record-details__bottom-title h4">
        Где хранятся шины
      </div>
      <ul class="record-details__radio-list">
        <li class="record-details__radio-item">
          <div class="record-details__radio form-field">
            <input type="radio" name="payment" checked v-model="detailsVariant" id="bring-myself" value="10">
            <label for="bring-myself" class="record-details__input-label">
              <span class="record-details__label-title text text__main text__main_medium">
                Шины привезу с собой
              </span>
            </label>
          </div>
        </li>
        <li class="record-details__radio-item">
          <div class="record-details__radio form-field">
            <input type="radio" name="payment" v-model="detailsVariant" id="in_storage" value="20">
            <label for="in_storage" class="record-details__input-label">
              <span class="record-details__label-title text text__main text__main_medium">
                Шины находятся в Бюро
              </span>
            </label>
          </div>
        </li>
      </ul>
      <div class="record-details__in-storage" v-if="details.detailsVariant === 20">
        <div class="record-details__field">
          <base-field-select
              field-id="in-storage-type"
              :values= fields.detailsVariant
              label="Тип"
              :no-default="true"
              v-model="details.inStorageVariant"
              :errors="details.errors.inStorageVariantError"
          />
        </div>
        <div class="record-details__field record-details__field_order-number"
           v-if="details.inStorageVariant === 'Новые'"
        >
          <base-field-input
              fieldId="order-number"
              label="Номер заказа"
              v-model="details.orderNumber"
              :errors="details.errors.orderNumberError"
              placeholder="ST3-444, STC-555"
          />
        </div>
        <div class="record-details__field record-details__field_save-order-number"
             v-if="details.inStorageVariant === 'БУ на хранении'"
        >
          <base-field-input
              fieldId="save-order-number"
              label="Номер квитанции"
              v-model="details.saveOrderNumber"
              :errors="details.errors.saveOrderNumberError"
              placeholder="412534428464820364"
          />
        </div>
        <div class="record-details__field record-details__field_save-order-name"
             v-if="details.inStorageVariant === 'БУ на хранении'"
        >
          <base-field-input
              fieldId="save-order-name"
              label="ФИО в квитанции"
              v-model="details.saveOrderName"
              placeholder="Иван Иванович Иванов"
              :errors="details.errors.saveOrderNameError"
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import BaseFieldInput from "../parts/fields/BaseFieldInput.vue";
import BaseFieldSelect from "../parts/fields/BaseFieldSelect.vue";
import {mapActions, mapState} from "vuex";
import errors from "../parts/common/Errors.vue";

export default {
  name: "TheScheduleServiceDetailsForm",
  components: {
    BaseFieldSelect,
    BaseFieldInput,
  },
  computed: {
    errors() {
      return errors
    },
    ...mapState('schedule', [
      'fields',
      'details'
    ]),
    detailsVariant: {
      set (value) {
        this.$store.commit('schedule/setDetailsVariant', parseInt(value, 10))
      }
    },
  },
}
</script>

<style scoped>

</style>