<template>
    <transition-group name="notifications-list" class="notifications">
        <div class="notification" v-for="(notification, key) in notifications" :key="notification.key" :class="['notification_' + notification.type]">
            <div class="notification__text">
                {{ notification.text }}
            </div>
            <a class="notification__close" href="javascript:void(0);" @click.prevent="removeNotification(key)">
                &times;
            </a>
        </div>
    </transition-group>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';

  export default {
    name: "Notifications",
    computed: {
      ...mapState('notification', [
        'notifications'
      ])
    },
    methods: {
      ...mapMutations('notification', [
        'removeNotification',
      ])
    }
  }
</script>

<style lang="scss">
    .notifications {
        position: fixed;
        top: 14px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10000;
        width: var(--body-max-width);
    }

    .notification {
        transition: all 0.5s;
        background-color: #fff;
        border: 1px solid #E8E8E8;
        box-sizing: border-box;
        box-shadow: 0 8px 30px rgba(32, 40, 38, 0.09);
        border-radius: 10px;
        right: 0;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 14px 20px;
        position: relative;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 8px;
        margin-right: 20px;

        &_success {
            color: #2c9407;
        }

        &_error {
            color: #c30808;
        }
    }

    .notification__close {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 0 20px;

        svg {
            display: block;
        }
    }

    .notifications-list-enter, .notifications-list-leave-to {
        opacity: 0;
        transform: translateY(-100px);
    }

    /*.notifications-list-leave-active {
        position: absolute;
    }*/
</style>