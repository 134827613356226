<template>
  <div class="record-modal">
    <div class="record-modal__tabs">
      <ul class="record-modal__tabs-list">
        <li v-for="post in posts" class="record-modal__tabs-item" :class="{ _active: isActive(post.id) }">
          <a :href="`#${post.id}`" class="record-modal__tabs-item-link" @click="setActive(post.id)">
            {{ post.name }}
          </a>
        </li>
      </ul>
    </div>
    <div class="record-modal__tabs-content">
      <ul class="record-modal__tabs-content-list">
        <li v-for="(post, key) in posts" class="record-modal__tabs-content-item"
            :class="{ _active: isActive(post.id) }">
          <div class="record-modal__record-number" v-if="post.record">
            Запись №{{ post.record.id }}
          </div>
          <div class="record-modal__record-date">
            {{ humanizeDate }}
          </div>
          <RecordForm v-if="post.record"
                      :post-id="post.id"
                      :initial-id="post.record.id"
                      :initial-name="post.record.contactInfo.name"
                      :initial-phone="post.record.contactInfo.phone"
                      :initial-car-and-license-plate-number="post.record.contactInfo.carAndLicensePlateNumber"
                      :initial-comment="post.record.comment"
                      :initial-car-type="post.record.contactInfo.carType"
                      :car-types="carTypes"
                      :form-name="formName + '_' + key"
                      :date-time="dateTime"
                      @close="$emit('close')"
          />
          <RecordForm v-else
                      :post-id="post.id"
                      :form-name="formName + '_' + key"
                      :date-time="dateTime"
                      :initial-car-type="firstCarType"
                      :car-types="carTypes"
                      @close="$emit('close')"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import RecordForm from "./RecordForm.vue";
import moment from "moment";
import {mapState} from "vuex";

export default {
  name: "RecordModal",
  props: {
    initialPosts: Array,
    initialActivePostId: Number,
    dateTime: String
  },
  components: {
    RecordForm
  },
  data() {
    return {
      formName: "RecordForm",
      posts: this.initialPosts,
      activePostId: this.initialActivePostId
    }
  },
  computed: {
    humanizeDate() {
      const mmm = moment(`${this.dateTime}}`, 'YYYY-MM-DDThh:mm:ss');
      return mmm.locale("ru").format("D MMMM в kk:mm");
    },
    ...mapState('scheduleAdmin', [
      'carTypes'
    ]),
    firstCarType() {
      return parseInt(Object.keys(this.carTypes)[0], 10);
    }
  },
  methods: {
    isActive(postId) {
      return this.activePostId === postId
    },
    setActive(postId) {
      this.activePostId = postId
    }
  }
}
</script>

<style lang="scss">
.record-modal {
  max-width: 600px;
  width: 600px;

  &__record-number {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #798297;
    background: #F1F2F4;
    border-radius: 3px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 6px;
  }

  &__record-date {
    font-weight: 500;
    font-size: 24px;
    line-height: 117%;
    color: #1F2129;
    box-shadow: 0px 1px 0px #E7E7E7;
    margin: 0 -33px;
    padding: 10px 33px 24px;
  }

  &__tabs {
    border-bottom: 1px solid var(--gray);
    background: #F5F5F6;
    border-radius: 3px 3px 0px 0px;
    padding: 30px 33px 15px;
  }

  &__tabs-list {
    display: flex;
  }

  &__tabs-item {
    position: relative;
    display: block;

    &:before {
      content: '';
      position: absolute;
      background: transparent;
      width: 100%;
      right: 0;
      left: 0;
      bottom: -15px;
      height: 2px;
      display: block;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }

    &._active {
      &:before {
        background: #000000;
      }
    }
  }

  &__tabs-item-link {
    background: rgba(121, 130, 151, 0.3);
    border-radius: 3px;
    padding: 8px 14px;
    display: flex;
  }

  &__tabs-content {
    padding: 30px 33px;
    border-radius: 0px 0px 3px 3px;
  }

  &__tabs-content-list {

  }

  &__tabs-content-item {
    display: none;

    &._active {
      display: block;
    }
  }
}
</style>