<template>
  <div class="filter-preloader" :class="{_visible: isLoading}">
    <Preloader class-name="filter-preloader__preloader"/>
  </div>
</template>

<script>
import Preloader from "../../components/parts/common/Preloader.vue";

export default {
  name: "FilterPreloader",
  components: {Preloader},
  data() {
    return {
      isLoading: false,
    }
  },
  mounted() {
    document.addEventListener('filter:before-submit', () => {
      this.isLoading = true;
      const paginationDataEl = document.querySelector('[data-pagination-data]');
      if (paginationDataEl) {
        document.querySelector('[data-pagination-data]').classList.add('_blur');
      }
    });

    document.addEventListener('filter:after-submit', () => {
      this.isLoading = false;
    });
  }
}
</script>

<style lang="scss">

</style>