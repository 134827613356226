<template>
  <div class="cart-order-fast">
    <div class="cart-order-fast__inner">
      <h2 class="cart-order-fast__title">
        Покупка в 1 клик
      </h2>
      <div class="cart-order-fast__subtitle text text__small">
        Отправьте данные для заказа, в течение 15 минут вам позвонит менеджер для его подтверждения
      </div>
      <ul class="cart-order-fast__fields form-field">
        <li class="cart-order-fast__field">
          <label for="fast_order_name" class="cart-order-fast__label">
            Ваше имя*
          </label>
          <input type="text" id="fast_order_name" class="cart-order-fast__input" v-model="name">
          <ItemErrors :errors="errors.name"/>
        </li>
        <li class="cart-order-block__field">
          <label for="fast_order_phone" class="cart-order-block__label">
            Телефон*
          </label>

          <input
              type="text"
              v-model="phone"
              ref="phone"
              id="fast_order_phone"
              name="phone"
              required="required"
              class="cart-contacts__input required"
          >

          <ItemErrors :errors="errors.phone"/>
        </li>
      </ul>

      <div class="cart-order-fast__button-wrap">
        <div class="cart-order-fast__politic text">
          Подтверждая заказ вы соглашаетесь с 
          <a href="#" class="cart-order-fast__politic-link">
            политикой конфиденциальности
          </a>
        </div>

        <button class="cart-order-fast__button button" @click="sendOrder" ref="submit">
          Купить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from "vuex";
import ItemErrors from "./ItemErrors.vue";
import {makeMask} from "../../../js/field/phone";

export default {
  name: "TheCartOrderFast",
  components: {
    ItemErrors
  },
  mounted() {
    const phoneInput = this.$refs.phone;
    makeMask(phoneInput);
  },
  computed: {
    ...mapState('order', {
      'name': 'name',
      'phone': 'phone',
      'errors': 'errors'
    }),
    ...mapState('cart', {
      'isCartPromoApply': 'isPromoApply'
    }),
    name: {
      get () {
        return this.$store.state.order.name
      },
      set (value) {
        this.$store.commit('order/setName', value)
      }
    },
    phone: {
      get () {
        return this.$store.state.order.phone
      },
      set (value) {
        this.$store.commit('order/setPhone', value)
      }
    },
  },
  methods: {
    ...mapActions('order', {
      'send': 'send'
    }),
    ...mapMutations({
      'setIsPromoApply': 'order/setIsPromoApply',
    }),
    sendOrder() {
      if (!this.name || !this.phone) {
        this.send(true);
      } else {
        this.$refs.submit.disabled = true;
        if(this.isCartPromoApply) {
          this.setIsPromoApply(this.isCartPromoApply);
        }
        this.send(true);
      }
    }
  }
}
</script>

<style lang="scss">

</style>