<template>
  <div class="checkbox-block-list-openable filter-openable"
       :class="blockClasses"
       data-accordion-element
       data-is-opened="true"
       v-if="choices.length"
  >
    <a href="javascript:void(0);"
       :title="field.label"
       class="checkbox-block-list-openable__link filter-openable__link"
       :class="elementClasses('link')"
       data-accordion-link
    >
      <span v-if="field.label"
             class="checkbox-block-list-openable__link-title filter-openable__link-title"
             :class="elementClasses('link-title')"
      >
        {{ field.label }}
      </span>
      <span class="checkbox-block-list-openable__help filter-openable__help"
            :class="elementClasses('link-help')"
            v-if="field.help"
      >
        <span class="checkbox-block-list-openable__help-icon filter-openable__help-icon">
          <QuestionBackground/>
        </span>
        <span class="checkbox-block-list-openable__help-description filter-openable__help-description">
          {{ field.help }}
        </span>
      </span>
      <span class="checkbox-block-list-openable__link-icon filter-openable__link-icon"
            :class="elementClasses('link-icon')"
      >
          <ArrowDown/>
      </span>
    </a>

    <div class="checkbox-block-list-openable__content filter-openable__content"
         :class="elementClasses('content')"
         v-if="valueItem.value"
         data-accordion-block
    >
      <div class="checkbox-block-list-openable__values-wrap">
        <div class="checkbox-block-list-openable__values"
             :class="elementClasses('values')"
        >
          <div class="checkbox-block-list-openable__value"
               :class="elementClasses('value')"
               v-for="(choice, index) in choices"
          >
            <input
                type="checkbox"
                :id="id(choice, index)"
                :value="choice.key"
                class="checkbox-block-list-openable__input"
                :class="elementClasses('input')"
                v-model="valueItem.value"
            >
            <label :for="id(choice, index)"
                   class="checkbox-block-list-openable__item-label text__small"
                   :class="elementClasses('item-label')"
            >
              {{ choice.label }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {fieldMixin} from "./fieldMixin";
  import ArrowDown from "../../components/icons/ArrowDown";
  import CheckMarkBlack from "../../components/icons/CheckMarkBlack";
  import QuestionBackground from "../../components/icons/QuestionBackground";

  export default {
    name: "CheckboxBlockListOpenable",
    components: {CheckMarkBlack, ArrowDown, QuestionBackground},
    mixins: [
      fieldMixin
    ],
    computed: {
      choices() {
        let choices = this.field.choices;
        const orderedChoices = this.field.data.orderedChoices;
        if(orderedChoices && orderedChoices.length) {
          choices.sort((a, b) => {
            if (!orderedChoices.includes(a.key) || !orderedChoices.includes(b.key)) {
              return;
            }
            return orderedChoices.indexOf(a.key) - orderedChoices.indexOf(b.key);
          });
        }
        return choices;
      },
    },
    methods: {
      checkValue() {
        if (!this.valueItem.value) {
          this.valueItem.value = [];
        }
      },
      id(choice, index) {
        index = index | 0;
        return `${this.field.key}_${choice.key}_${index}`;
      }
    },
    mounted() {
      this.checkValue();
    },
    beforeUpdate() {
      this.checkValue();
    }
  }
</script>

<style lang="scss">

</style>