<template>
  <div class="record-for-service-success" data-record-for-service-success>
    <div class="record-for-service-success__heading">
      <h3 class="record-for-service-success__title">
        Запись №{{ orderData.recordId }} создана
      </h3>
    </div>

    <div class="record-for-service-success__main">
      <ul class="record-for-service-success__list">
        <li class="record-for-service-success__item text__small">
          <div class="record-for-service-success__item-name">
            Услуга
          </div>
          <div class="record-for-service-success__item-value">
            {{ serviceName }}
          </div>
        </li>
        <li class="record-for-service-success__item text__small">
          <div class="record-for-service-success__item-name">
            Сервисный центр
          </div>
          <div class="record-for-service-success__item-value">
            {{ serviceCenter.address }}
          </div>
        </li>
        <li class="record-for-service-success__item text__small">
          <div class="record-for-service-success__item-name">
            Дата и время
          </div>
          <div class="record-for-service-success__item-value">
            {{ date }}
          </div>
        </li>
        <li class="record-for-service-success__item text__small">
          <div class="record-for-service-success__item-name">
            Контактное лицо
          </div>
          <div class="record-for-service-success__item-value">
            {{ orderData.name }}
          </div>
        </li>
        <li class="record-for-service-success__item text__small">
          <div class="record-for-service-success__item-name">
            Телефон
          </div>
          <div class="record-for-service-success__item-value">
            {{ orderData.phone }}
          </div>
        </li>
      </ul>

      <div class="record-for-service-success__bottom">
        <h4 class="record-for-service-success__bottom-title">
          Отредактировать запись можно в личном кабинете
        </h4>

        <div class="record-for-service-success__buttons">
          <a href="/profile"
             title="В личный кабинет"
             class="record-for-service-success__primary button button_primary"
          >
            В личный кабинет
          </a>

          <a href="/"
             title="На главную"
             class="record-for-service-success__secondary button button_secondary"
          >
            На главную
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';

  export default {
    name: "TheScheduleServiceSuccess",
    computed: {
      ...mapState('schedule', [
        'graph',
        'orderData',
      ]),
      serviceName() {
          return this.orderData.service ? this.orderData.service.name : '';
      },
      date() {
          const monthNames = ["января", "февраля", "марта", "апреля", "мая", "июня",
              "июля", "августа", "сентября", "октября", "ноября", "декабря"
          ];

          if (this.orderData.date) {
              const [dateValues, timeWithGmt] = this.orderData.date.split('T');
              const [year, month, day] = dateValues.split('-'); // год, месяц, день
              const [time, gmt] = timeWithGmt.split('+'); // время, gmt
              const [hours, minutes, seconds] = time.split(':'); // часы, минуты, секунды
              const date = new Date(+year, month - 1, +day, +hours, +minutes, +seconds);
              return `${date.getDate()} ${monthNames[date.getMonth()]} в ${date.getHours()}:${minutes}`;
          }
          return '';
      },
      serviceCenters() {
          if (this.graph.serviceCenters) {
              return this.graph.serviceCenters;
          }
          return [];
      },
      serviceCenter() {
          if (this.orderData.serviceCenterId) {
              return this.serviceCenters
                  .find((item) => item.id === this.orderData.serviceCenterId);
          }
          return '';
      }
    },
    mounted() {

    }
  }
</script>

<style lang="scss">

</style>