<template>
  <form action="" class="price-competitor-filters" @submit.prevent>
    <div class="price-competitor-filters__top">
      <ul class="price-competitor-filters__fields">
        <li class="price-competitor-filters__field"
            v-for="(field, name) in this.fields"
        >
          <base-field-select
              v-if="name === 'width'"
              :field-id="name"
              :values="field"
              :label="'Ширина'"
              :default-value="{ name: 'Любая', value: '0' }"
              v-model="settings.width"
              @input="updateTyres"
          />

          <base-field-select
              v-if="name === 'height'"
              :field-id="name"
              :values="field"
              :label="'Высота'"
              :default-value="{ name: 'Любая', value: '0' }"
              v-model="settings.height"
              @input="updateTyres"
          />

          <base-field-select
              v-if="name === 'diameter'"
              :field-id="name"
              :values="field"
              :label="'Диаметр'"
              :default-value="{ name: 'Любая', value: '0' }"
              v-model="settings.diameter"
              @input="updateTyres"
          />
          <base-field-select
              v-if="name === 'producer'"
              :field-id="name"
              :values="field"
              :label="'Производитель'"
              :default-value="{ name: 'Любая', value: '0' }"
              v-model="settings.producer"
              @input="updateTyres"
          />

          <base-field-select
              v-if="name === 'season'"
              :field-id="name"
              :values="field"
              :label="'Сезон'"
              :default-value="{ name: 'Любая', value: '0' }"
              v-model="settings.season"
              @input="updateTyres"
          />

        </li>
      </ul>
    </div>

    <div class="price-competitor-filters__bottom">
      <div v-for="(field, name) in this.fields">
        <base-field-input
            v-if="name === 'id'"
            :field-id="id"
            :values="field"
            :label="'внутренний ID'"
            v-model="settings.id"
            v-debounce:800ms="updateTyres"
            errors="" placeholder=""/>
      </div>
      <base-field-checkbox
          class="price-competitor-filters__has-manual-prices"
          fieldId="'hasManualPrice'"
          label="Есть ручные цены"
          v-model:checked="settings.manualPrices"
          @update:checked="updateTyres"
      />
      <base-field-checkbox
          class="price-competitor-filters__has-manual-old-prices"
          fieldId="'hasManualOldPrice'"
          label="Есть старые цены"
          v-model:checked="settings.manualOldPrices"
          @update:checked="updateTyres"
      />
      <base-field-checkbox
          class="price-competitor-filters__has-manual-avito-prices"
          fieldId="'hasManualAvitoPrice'"
          label="Есть avito цены"
          v-model:checked="settings.manualAvitoPrices"
          @update:checked="updateTyres"
      />
<!--      <base-field-checkbox-->
<!--          class="price-competitor-filters__has-manual-yandex-prices"-->
<!--          fieldId="'hasManualYandexPrice'"-->
<!--          label="Есть yandex цены"-->
<!--          v-model:checked="settings.manualYandexPrices"-->
<!--          @update:checked="updateTyres"-->
<!--      />-->
    </div>

  </form>
</template>

<script>
import DropdownField from "../../filter/field/DropdownField";
import {mapActions, mapMutations, mapState} from "vuex";
import BaseFieldSelect from "../parts/fields/BaseFieldSelect.vue";
import BaseFieldCheckbox from "../parts/fields/BaseFieldCheckbox.vue";
import BaseFieldInput from "../parts/fields/BaseFieldInput.vue";
import { vue3Debounce } from 'vue-debounce'

export default {
  name: "ThePriceManualFilters",
  components: {BaseFieldInput, BaseFieldCheckbox, BaseFieldSelect, DropdownField},
  computed: {
    ...mapState('priceManualFilter', [
      'settings',
      'fields',
    ]),
    ...mapState('priceManualItems', [
      'tyres',
    ]),
  },
  methods: {
    ...mapMutations('priceManualFilter', [
        'setPage',
        'setFalseIsLastPage'
    ]),
    ...mapActions('priceManualItems', [
        'fetchTyres'
    ]),
    updateTyres() {
      this.setPage(1);
      this.setFalseIsLastPage();
      setTimeout(() => {
        this.fetchTyres();
      }, 50);
    },
  },
  directives: {
    debounce: vue3Debounce({ lock: true })
  }
}
</script>

<style scoped>

</style>