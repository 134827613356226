import CryptoJS from "crypto-js";

function encode(string) {
    const k = atob(document.querySelector('[data-user-validate]').dataset.userValidate);

    const decryptedJSONString = atob(string);

    const json = JSON.parse(decryptedJSONString);
    const encrypted = json.outerId;
    const id = CryptoJS.enc.Hex.parse(json.id);
    const userId = CryptoJS.enc.Hex.parse(json.userId);

    const key = CryptoJS.PBKDF2(k, id, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 3});

    const decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: userId});

    return decrypted.toString(CryptoJS.enc.Utf8);
}

export {encode}