<template>
  <div class="cart-order-block__pickup cart-order-delivery">
    <div class="cart-order-delivery__input-wrap">
      <label for="order_address" class="cart-order-delivery__label">
        Адрес доставки
      </label>
      <input type="text" class="cart-order-delivery__input" id="order_address" v-model="address">
      <ItemErrors :errors="errors.addressFilled"/>
    </div>

    <ul class="cart-order-delivery__conditions">
      <li class="cart-order-delivery__condition">
        <h4 class="cart-order-delivery__condition-title">
          Доставка по Нижнему Новгороду
        </h4>
        <div class="cart-order-delivery__condition-content">
          <div class="cart-order-delivery__text text">
            Комплект шин или дисков (4 шт.) — <span class="cart-order-delivery__text_primary">бесплатно</span>
            <br>
            Шины или диски менее 4 шт. — <span class="cart-order-delivery__text_primary">400 ₽</span>
          </div>
        </div>
      </li>
      <li class="cart-order-delivery__condition">
        <h4 class="cart-order-delivery__condition-title">
          Доставка в регионы России
        </h4>
        <div class="cart-order-delivery__condition-content">
          <div class="cart-order-delivery__text text">
            Отправляем заказы всеми транспортными
            компаниями
            <br>
            <br>
            Комплект шин или дисков (4 шт.) до терминала транспортной компании — <span class="cart-order-delivery__text_primary">бесплатно</span>
            <br>
            Шины или диски менее 4 шт. до терминала транспортной компании — <span class="cart-order-delivery__text_primary">400 ₽</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ItemErrors from "./ItemErrors.vue";
import {mapState} from "vuex";

export default {
  name: "TheCartOrderReceivingDelivery",
  components: {
    ItemErrors
  },
  computed: {
    address: {
      get () {
        return this.$store.state.order.address
      },
      set (value) {
        this.$store.commit('order/setAddress', value)
      }
    },
    errors: {
      get () {
        return this.$store.state.order.errors
      },
    },
    ...mapState('profile', {
      'profileInfo': 'info'
    })
  },
  mounted() {
    this.address = this.profileInfo.address;
  }
}
</script>
