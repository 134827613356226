<template>
  <div>
    <Datepicker
        language="ru"
        :inline="true"
        :value="date"
        :mondayFirst="true"
        @selected="setDate"
    ></Datepicker>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import Datepicker from "vuejs3-datepicker";

export default {
  name: "DatePicker",
  components: {
    Datepicker
  },
  computed: {
    ...mapState('scheduleAdmin/date', [
      'date'
    ]),
    ...mapGetters('scheduleAdmin/date', [
      'timestamp'
    ])
  },
  methods: {
    ...mapActions('scheduleAdmin/date', [
      'setDate'
    ])
  }
}
</script>

<style lang="scss">
.vdp-datepicker {
  padding: 14px;
  background: white;

  &__calendar {
    width: auto !important;
    border: none !important;

    header {
      display: flex !important;
      justify-content: space-between;
      height: 65px;
      align-items: center;
      background: white;
      border: 1px solid #E7E7E7;
      padding: 10px 18px;

      span {
        font-family: 'Roboto', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #1F2129;

        &.prev, &.next {
          background: #FFFFFF;
          box-shadow: 0px 10px 18px rgba(50, 59, 86, 0.1);
          border-radius: 50%;
          width: 45px;
          height: 45px;
        }
      }
    }

    div {
      background: #F5F5F6;
    }

    .cell {
      padding: 0 !important;
      height: 32px !important;
      line-height: 32px !important;
      border-color: #f5f5f6 !important;

      &.selected {
        background: #1F2129 !important;
        color: white;
      }

      &.day-header {
        text-transform: uppercase;
      }

      &.blank {
        background: transparent !important;
      }
    }

    .day {
      background: white;
    }
  }
}
</style>