/**
 * Елемент аккордиона
 *
 * ОБЯЗАТЕЛЬНО:
 * [data-accordion-element] (общий элемент) - должен быть на элементе
 *
 * [data-accordion-link] (переключатель) - должен быть на ссылке внутри общего элемента
 *
 * [data-accordion-block] (прячущий элемент (overflow: hidden)) -
 *  должен содержать только одного потомка и быть внутри общего элемента
 *
 * ДОПОЛНИТЕЛЬНО:
 * [data-is-opened="true"] - можно повесить на элемент, что бы он раскрылся при инициализации
 *
 * ПОБОЧНО:
 * ._opened - вешается на элемент при раскрытии и убирается при закрытии
 *
 * ElementOpen - событие отправляемое на элемент при раскрытии
 *
 * ElementClose - событие отправляемое на элемент при закрытии
 *
 * ПРИМЕР РАЗМЕТКИ ЭЛЕМЕНТА:
 *  <li data-accordion-element data-is-opened="true">
 *    <a data-accordion-link>
 *      Развернуть
 *    </a>
 *
 *    <div data-accordion-block>  <-- задать транзишен на высоту
 *      <div>
 *        (много текста)
 *      </div>
 *    </div
 *  </li>
 */
class Element {
  constructor(element) {
    this.element = element;

    this.link = this.element.querySelector('[data-accordion-link]');
    this.block = this.element.querySelector('[data-accordion-block]');

    this.parentBlock = this.link.closest('[data-accordion-block]');
    if (this.parentBlock) {
      this.parentElement = this.parentBlock.closest('[data-accordion-element]');
    }

    this.children = this.element.querySelectorAll('[data-accordion-element]');

    if (this.parentBlock) {
      this.setParentLastHeight();
    }

    if (this.link.dataset.accordionLinkText) {
      this.textLinkElement = this.link.dataset.accordionLinkText;
    } else {
      this.textLinkElement = this.link.querySelector('[data-accordion-link-text]');
    }

    if (!this.link) {
      throw new Error('Element by selector [data-accordion-link] not found');
    }
    if (!this.block) {
      throw new Error('Element by selector [data-accordion-block] not found');
    }

    if (this.block.childElementCount !== 1) {
      throw new Error('Element by selector [data-accordion-block] must contain 1 child');
    }

    // this.block.style.overflow = 'hidden';
    this.content = this.block.children[0];
    this.defineHeight();
    this.addListeners();
    if (!!this.element.dataset.isOpened) {
      this.setOpenedProperties();
    } else {
      this.setCloseProperties();
    }
  }

  /**
   * Установка целевой высоты равной высоте контента блока
   */
  defineHeight() {
    this.targetHeight = this.content.clientHeight;
    this.defaultElementHeight = this.element.clientHeight;
    this.linkHeight = this.link.clientHeight;
  }

  /**
   * Развернуть и отправить событие
   */
  open() {
    this.setOpenedProperties();

    if (this.parentBlock) {
      this.setParentLastHeight();
      this.parentBlock.style.height = `${this.targetHeight + this.link.clientHeight}px`;
    }

    this.element.dispatchEvent(new CustomEvent('ElementOpen'));
  }

  /**
   * Свернуть и отправить событие
   */
  close() {
    this.setCloseProperties();

    if (this.parentBlock && this.parentElement.classList.contains('_opened')) {
      this.parentBlock.dispatchEvent(new CustomEvent('ChildElementClose'));
      if (this.checkedHeight) {
        this.parentBlock.style.height = `${this.checkedHeight + this.link.clientHeight}px`;
      } else {
        this.parentBlock.style.height = `${this.defaultElementHeight}px`;
      }
    }

    if (this.children) {
      this.children.forEach((child) => {
        if (child.classList.contains('_opened')) {
          child.dispatchEvent(new CustomEvent('ParentElementClose'))
        }
      });
    }

    this.element.dispatchEvent(new CustomEvent('ElementClose'));
  }

  /**
   * Развернуть
   */
  setOpenedProperties() {
    this.block.style.height = `${this.content.clientHeight}px`;
    this.isOpened = true;
    this.element.classList.add('_opened');
    this.swipeText();
  }

  /**
   * Свернуть
   */
  setCloseProperties() {
    if (this.checkedHeight) {
      this.block.style.height = `${this.checkedHeight}px`;
    } else {
      this.block.style.height = '';
    }

    this.isOpened = false;
    this.element.classList.remove('_opened');
    this.swipeText();
  }

  /**
   * Подменить текст при наличии
   */
  swipeText() {
    if (this.textLinkElement) {
      const text = this.textLinkElement.dataset.accordionLinkText;
      this.textLinkElement.dataset.accordionLinkText = this.textLinkElement.innerHTML;
      this.textLinkElement.innerHTML = text;
    }
  }

  setParentLastHeight() {
    this.parentLastHeight = this.parentBlock.clientHeight;
  }

  /**
   * Отслеживание нажатия по переключателю
   */
  addListeners() {
    const instance = this;
    this.link.addEventListener('click', (e) => {
      e.preventDefault();
      if (instance.isOpened) {
        instance.close();
      } else {
        instance.open();
      }
    });

    instance.element.addEventListener('ParentElementClose', () => {
      this.close();
    });

    instance.element.addEventListener('FilterInputChecked', (e) => {
      const height = e.detail.height;
      if (this.isOpened) {
        if (height) {
          instance.checkedHeight = height;
          this.close()
        } else {
          instance.checkedHeight = null;
        }
      } else {
        instance.checkedHeight = null;

        if (height) {
          this.block.style.height = `${height}px`;

          if (this.parentBlock) {
            this.parentBlock.style.height = `${height + this.linkHeight}px`;
          }
        } else {
          this.block.style.height = '';

          if (this.parentBlock) {
            this.parentBlock.style.height = `${this.defaultElementHeight}px`;
          }
        }
      }
    });
  }

  onResize() {
    this.defineHeight();
    if (this.isOpened) {
      this.setOpenedProperties();
    }
  }
}

export default Element;