<template>
  <div class="vue-field vue-field_select" :class="class">
    <label v-if="label" :for="fieldId">
      {{ label }}
    </label>

    <select :id="fieldId"
            :value="modelValue"
            :disabled="disabled"
            @change="$emit('update:modelValue', $event.target.value)"
    >
      <option v-if="!noDefault"
              :value="defaultValue.value"
      >
        {{ defaultValue.name }}
      </option>
      <option v-for="value in values"
              :value="valueAsString(value)"
              :selected="getIsSelected(value)"
              :disabled="checkDisabled(value.id)">
        {{ nameAsString(value) }}
      </option>
    </select>
    <Errors :errors="errors"/>
  </div>

</template>

<script>
  import Errors from "../common/Errors.vue";

  export default {
    name: "BaseFieldSelect",
    components: {
      Errors,
    },
    data() {
      return {
        selected: 0,
      }
    },
    props: {
      // Уникальный id селекта
      fieldId: {
        type: String,
        required: true
      },
      values: {
        type: Array,
        required: true
      },
      modelValue: {
        type: String,
        required: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      // Список недоступных значений из списка всех значений
      disableValues: {
        type: Array,
        required: false
      },
      // true - не добавлять 'all' => 'Все'
      noDefault: {
        type: Boolean,
        required: false,
        default: false
      },
      defaultValue: {
        type: Object,
        required: false,
        default: () => {
          return {
            name: '',
            value: 0
          }
        }
      },
      class: {
        type: String,
        required: false,
      },
      // Лейбл. Отображается при наличии
      label: {
        type: String,
        required: false
      },
      errors: {
        type: Array,
        required: false,
      },
    },
    methods: {
      change(e) {
        this.$emit('input', e.target.value);
      },
      valueAsString(item) {
        if (item.id !== undefined) {
          return item.id;
        }

        if (item.value !== undefined) {
          return item.value;
        }

        if (item.name !== undefined) {
          return item.name;
        }

        return item;
      },
      getIsSelected(value) {
        return value === this.selectedValue;
      },
      nameAsString(item) {
        if (item.address !== undefined) {
          return item.address;
        }

        if (item.name !== undefined) {
          return item.name;
        }

        return item;
      },
      checkDisabled(value) {
        return this.disableValues ? this.disableValues.includes(value) : false;
      },
    },
    mounted() {

    },
  }
</script>