<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 19a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0 2c5.523 0 10-4.477 10-10S16.523 1 11 1 1 5.477 1 11s4.477 10 10 10Z" fill="#B3BAC0"/><path d="M10.011 12.915c0-.627.076-1.126.227-1.498.15-.372.426-.737.825-1.096.404-.363.673-.656.806-.88.133-.229.2-.468.2-.72 0-.756-.347-1.135-1.039-1.135-.329 0-.593.103-.792.31-.196.2-.298.48-.307.84H8c.009-.857.282-1.526.82-2.01C9.36 6.242 10.097 6 11.03 6c.941 0 1.671.23 2.19.692.52.457.78 1.105.78 1.942 0 .381-.084.742-.253 1.082-.169.336-.464.71-.886 1.123l-.54.517c-.336.327-.53.71-.579 1.15l-.026.41H10.01Zm-.193 2.064c0-.3.1-.547.3-.74.204-.197.464-.296.779-.296s.572.1.772.296c.204.193.307.44.307.74 0 .295-.1.54-.3.732-.195.193-.455.289-.78.289-.323 0-.585-.096-.785-.289a.985.985 0 0 1-.293-.732Z" fill="#B3BAC0"/></svg>
</template>

<script>
  export default {
    name: "Question"
  }
</script>

<style scoped>

</style>