import ScheduleService from "../../../services/schedule.service";

const serviceModule = {
  namespaced: true,
  state: {
    services: [],
    activeItemId: ''
  },
  getters: {
    activeService: (state) => {
      let serviceObject = {};

      for (const service in state.services) {
        if(state.services.hasOwnProperty(service) && state.services[service].id === state.activeItemId) {
          serviceObject = state.services[service];
        }
      }

      return serviceObject;
    }
  },
  actions: {
    async init({ commit, state, dispatch }) {
      const services = await ScheduleService.getServices();
      commit('setServices', services.data);

      if(services.data[0]) {
        commit('setActive', services.data[0].id);
      }
      dispatch('scheduleAdmin/graph/loadGraph', null, {root: true});
      dispatch('scheduleAdmin/loadCarTypes', null, {root: true});
    },
    setActive({commit, state, dispatch}, activeId) {
      commit('setActive', activeId);
      dispatch('scheduleAdmin/graph/loadGraph', null, {root: true});
    }
  },
  mutations: {
    setServices(state, services) {
      state.services = services;
    },
    setActive(state, activeId) {
      state.activeItemId = activeId;
    }
  },
};

export default serviceModule;