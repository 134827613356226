<template>
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="34" height="34" rx="2" fill="#F22B2B"/>
    <path d="M11 11L22.9993 22.9993" stroke="white" stroke-width="2"/>
    <path d="M23 11L11.0007 22.9993" stroke="white" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: "RedCross"
}
</script>

<style lang="scss">

</style>