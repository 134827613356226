<template>
  <div class="cart-oils">
    <div class="cart-oils__title h3">
      Рекомендуем добавить
    </div>
    <div class="cart-oils__nav">
      <a href="javascript:void(0);" @click="swiperPrevSlide" class="cart-oils__prev link link_slider-prev">
        <ArrowNext/>
      </a>
      <a href="javascript:void(0);" @click="swiperNextSlide" class="cart-oils__next link link_slider-next">
        <ArrowNext/>
      </a>
    </div>
    <div class="cart-oils__slider-wrap">
      <swiper
          :modules="modules"
          :breakpoints="swiperOptions.breakpoints"
          navigation
          @swiper="onSwiper"
          ref="swiperRef"
          class="cart-oils__slider"
      >
        <swiper-slide class="cart-oils__slide" v-for="item in oils">
          <div class="cart-oils__item">
            <div class="cart-oils__top">
              <div class="cart-oils__image-wrap">
                <img :src="item.imagePath"
                     :alt="item.name"
                     class="cart-oils__image"
                     v-if="item.imagePath"
                >
                <img src="/static/images/base/noimage.png"
                     alt="no-image"
                     class="cart-oils__image"
                     v-else
                >
              </div>
              <div class="cart-oils__name">
                {{ item.name }}
              </div>
            </div>
            <div class="cart-oils__bottom">
              <div class="cart-oils__price h3">
                {{ item.price }} ₽
              </div>
              <a href="javascript:void(0);"
                 class="cart-oils__to-cart"
                 @click="add(item)"
              >
                <CartAdd/>
              </a>
            </div>
          </div>

        </swiper-slide>
      </swiper>
    </div>
  </div>

</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { Navigation } from 'swiper'
import {ref} from "vue";
import 'swiper/css'
import 'swiper/css/navigation'
import CartAdd from "../icons/CartAdd.vue";
import ArrowNext from "../icons/ArrowNext.vue";

export default {
  name: "TheCartOils",
  components: {
    ArrowNext,
    CartAdd,
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      APIData: [],
      swiperOptions: {
        breakpoints: {
          0: {
            slidesPerView: 1.3,
            spaceBetween: 15
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 14
          },
          1199: {
            slidesPerView: 3,
            spaceBetween: 28
          }
        }
      }
    }
  },
  setup() {
    const swiperInstance = ref()

    function onSwiper(swiper) {
      swiperInstance.value = swiper
    }
    const swiperNextSlide = () => {
      swiperInstance.value.slideNext()
    };
    const swiperPrevSlide = () => {
      swiperInstance.value.slidePrev()
    };

    return {
      swiperPrevSlide,
      swiperNextSlide,
      onSwiper,
      modules: [Navigation]
    }
  },
  computed: {
    ...mapState('cart', {
      'checkout': 'checkout',
      'oils': 'oils'
    }),
  },
  methods: {
    ...mapActions({
      'loadOils': 'cart/loadOils',
      'add': 'cart/add'
    }),

  },

  mounted() {
    this.loadOils();
  }
}
</script>


