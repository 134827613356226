<template>
  <div class="chosen-field" :class="blockClasses" v-show="chosenValues.length">
    <div class="chosen-field__values" :class="elementClasses('values')">
      <div class="chosen-field__value"
           v-for="(choice, index) in field.choices"
           v-show="isChosen(choice)"
      >
        <input type="checkbox"
               :class="elementClasses('input')"
               :id="id(choice, index)"
               :value="choice.key"
               v-model="valueItem.value"
               :disabled="choice.disabled"
               @change="removeFromChosen">
        <label :for="id(choice, index)"
               class="chosen-field__label"
               :class="elementClasses('label')">
          {{ choice.label }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  import {fieldMixin} from "./fieldMixin";

  export default {
    name: "ChosenField",
    mixins: [
      fieldMixin
    ],
    data() {
      return {
        chosenValuesData: []
      }
    },
    computed: {
      chosenValues() {
        return this.chosenValuesData;
      }
    },
    methods: {
      checkValue() {
        if (!this.valueItem.value) {
          this.valueItem.value = [];
        }
        if (this.field.data[this.field.storeKey]) {
          this.chosenValuesData = Object.assign(this.field.data[this.field.storeKey]);
        }
      },
      id(choice, index) {
        index = index | 0;
        return `${this.field.key}_${choice.key}_${index}`;
      },
      isChosen(choice) {
        return !!this.chosenValues.includes(choice.key) && !!this.valueItem.value.includes(choice.key);
      },
      removeFromChosen(e) {
        this.chosenValuesData.splice(this.chosenValuesData.indexOf(e.target.value), 1);
        this.$emit('change', e.target.value);
      }
    },
    mounted() {
      this.checkValue();

    },
    beforeUpdate() {
      this.checkValue();
    },
    updated() {
      this.checkValue();
    }
  }
</script>

<style scoped>

</style>