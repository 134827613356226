import FavoritesService from "../../services/favorites.service";

const favoritesModule = {
    namespaced: true,
    state: {
        loading: false,
        checkout: null,
        isFavoritePage: false
    },
    getters: {
        count: (state) => state.checkout ? state.checkout.items.length : 0
    },
    actions: {
        async add({ state, dispatch, commit }, { chosen, id }) {
            try {
                const result = await FavoritesService.add(chosen, id);
                console.log(result)
                chosen = chosen.replace(/\\/gi, '\\\\');
                document.querySelectorAll(`[data-chosen="${chosen}"][data-id="${id}"]`).forEach((item) => {
                    item.dataset.selected = result.data.key;
                });

                dispatch('fetchCheckout');
                // dispatch('notification/showSuccessNotification', 'Товар успешно добавлен в избранное', {root:true});
            } catch (e) {
                dispatch('notifyError', e);
            }
        },
        async remove({ state, dispatch, commit, getters }, { key }) {
            try {
                const result = await FavoritesService.remove(key);
                console.log(document.querySelectorAll(`[data-selected="${key}"]`))
                document.querySelectorAll(`[data-selected="${key}"]`).forEach((item) => {
                    item.dataset.selected = '';
                });

                await dispatch('fetchCheckout');
                // dispatch('notification/showErrorNotification', 'Товар удалён из избранного', {root:true});

                if (state.isFavoritePage && !getters.count) {
                    window.location.reload();
                }
            } catch (e) {
                dispatch('notifyError', e);
            }
        },
        async loadCheckout({ state, dispatch, commit }) {
            if (!state.checkout && !state.loading) {
                commit('setLoading', true);
                await dispatch('fetchCheckout');
                console.log()
                if (document.querySelector('[data-favorites-list]')) {
                    commit('setFavoritePage', true)
                }
                commit('setLoading', false);
            }
        },
        async fetchCheckout({ commit }) {
            try {
                const result = await FavoritesService.fetchCheckout();
                commit('setCheckout', result.data);
            } catch (e) {
                console.log(e);
            }
        },
        // setFavoritePage({commit}, isFavoritePage) {
        //     commit('setFavoritePage', isFavoritePage)
        // },
        // async initializationFavorites ({ state, dispatch, commit }) {
        //     const response = await FavoritesService.updateFavoritesList();
        //     const page = document.createElement('div');
        //     page.innerHTML = response.data;
        //
        //     const newData = page.querySelector('[data-favorites-list]');
        //     const currentData = document.querySelector('[data-favorites-list]');
        //
        //     currentData.parentNode.replaceChild(newData, currentData);
        //
        //     let event = new Event('DOMContentMutated');
        //     document.dispatchEvent(event);
        // },
    },
    mutations: {
        setCheckout(state, data) {
            state.checkout = data;
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setFavoritePage(state, isFavorite) {
            state.isFavoritePage = isFavorite;
        }
    },
};

export default favoritesModule;