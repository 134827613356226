<template>
  <div class="service-centers">
    <ul class="service-centers__list">
      <li class="service-centers__item" v-for="serviceCenter in serviceCenters">
        <ServiceCenter
            :id="serviceCenter.id"
            :address="serviceCenter.address"
            :address-lat="serviceCenter.addressLat"
            :address-lng="serviceCenter.addressLng"
            :name="serviceCenter.name"
            :posts="serviceCenters.posts"
            :slots="serviceCenter.slots"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import ServiceCenter from "./ServiceCenter.vue";

export default {
  name: "ServiceCenters",
  components: {
    ServiceCenter
  },
  computed: {
    ...mapState('scheduleAdmin/graph', {
      'graph': 'graph'
    }),
    serviceCenters() {
      if (this.graph) {
        return this.graph.serviceCenters;
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss">
.service-centers {
  margin-right: 110px;

  &__list {
    display: flex;
  }

  &__item {
    flex: 0 0 238px;
    border-right: 1px solid var(--gray);
    background-color: var(--block-background);

    &:last-child {
      border-right: none;
    }
  }
}
</style>