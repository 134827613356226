<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="7" fill="#C7C7C8"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.105 5.6c.147-.14.294-.504.294-.7a1.4 1.4 0 0 0-2.8 0H4.2a2.8 2.8 0 1 1 5.6 0c0 .574-.3 1.288-.7 1.694L7.993 7.7c-.147.14-.294.497-.294.7v.7H6.3v-.7c0-.574.301-1.288.7-1.694L8.105 5.6ZM6.3 11.9v-1.4h1.4v1.4H6.3Z" fill="#fff"/></svg>
</template>

<script>
  export default {
    name: "QuestionBackground"
  }
</script>

<style scoped>

</style>