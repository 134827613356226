<template>
  <div class="price-competitor price-competitor_info">
    <div class="price-competitor__filters-wrap">
      <ThePriceCompetitorFilters class="price-competitor__filters"/>

      <div class="price-competitor__total-title">
        Количество подобраных шин: {{ total }}
      </div>
    </div>
    <a href="javascript:void(0);" @click="buildReport" class="price-competitor__button button button_primary">
      Сформировать отчет
    </a>
    <ThePriceCompetitorMainInfo class="price-competitor__main"/>
  </div>
</template>

<script>
import ThePriceCompetitorFilters from "./ThePriceCompetitorFilters";
import ThePriceCompetitorMainInfo from "./ThePriceCompetitorMainInfo";
import {mapActions, mapState} from "vuex";

export default {
  name: "ThePriceCompetitorInfo",
  components: {
    ThePriceCompetitorMainInfo,
    ThePriceCompetitorFilters,
  },
  computed: {
    ...mapState('priceCompetitorItems', [
        'tyres',
        'total'
    ])
  },
  methods: {
    ...mapActions('priceCompetitorFilter', [
      'fetchFilterParams',
    ]),
    ...mapActions('priceCompetitorItems', [
        'fetchCompetitors',
        'fetchTyres',
        'buildReport'
    ]),
  },
  mounted() {
    this.fetchFilterParams();
    this.fetchCompetitors();
    this.fetchTyres();
  },
}
</script>

<style scoped>
  .price-competitor__button{
    margin-top: 20px;
  }
</style>