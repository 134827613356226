import LiveEvent from "../live/live";

function smartTab(e) {
  let button = this;
  if (button.tagName === 'A') {
    e.preventDefault();
  }

  let selector = button.dataset.tab;
  if (!selector && button.getAttribute('href')) {
    selector = button.getAttribute('href');
  }

  let container = button.closest('[data-tabs-container]');

  let tabContents = container.querySelectorAll(selector);
  if (tabContents) {
    container.querySelectorAll(".smart-content").forEach((item) => { item.classList.remove('_active') });
    tabContents.forEach((tab) => {
      tab.classList.add('_active');
    })
  }

  let tabs = button.closest('[data-smart-tabs-multiply]');
  if (!tabs) {
    tabs = button.closest('[data-smart-tabs]');
  }
  tabs.querySelectorAll('li').forEach((item) => { item.classList.remove('_active') });
  button.closest('li').classList.add('_active');

  let event = new CustomEvent('smart-tabs-handle', {'detail': {'button': button, 'tabs': tabContents}});
  document.dispatchEvent(event);
}

new LiveEvent('click', '[data-smart-tabs-multiply] li a', smartTab);