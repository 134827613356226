export default {
  data() {
    return {
      modals: [],
      successes: [],
      processing: [],
    };
  },
  methods: {
    openModal(key) {
      const index = this.modals.indexOf(key);
      if (index === -1) {
        this.modals.push(key);
      }
    },
    isModalOpened(key) {
      return this.modals.indexOf(key) !== -1;
    },
    closeModal(key) {
      const index = this.modals.indexOf(key);
      if (index !== -1) {
        this.modals.splice(index, 1);
        this.unsetSuccess(key);
        this.unsetProcessing(key);
      }
    },

    setSuccess(key) {
      const index = this.successes.indexOf(key);
      if (index === -1 && this.isModalOpened(key)) {
        this.successes.push(key);
      }
    },
    isSuccess(key) {
      return this.successes.indexOf(key) !== -1;
    },
    unsetSuccess(key) {
      const index = this.successes.indexOf(key);
      if (index !== -1) {
        this.successes.splice(index, 1);
      }
    },

    setProcessing(key) {
      const index = this.processing.indexOf(key);
      if (index === -1) {
        this.processing.push(key);
      }
    },
    isProcessing(key) {
      return this.processing.indexOf(key) !== -1;
    },
    unsetProcessing(key) {
      const index = this.processing.indexOf(key);
      if (index !== -1 && this.isModalOpened(key)) {
        this.processing.splice(index, 1);
      }
    },
  },
};