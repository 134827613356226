import { onDomChanges } from '../../../components/dynamic/observer';
import Jax from '../../../components/jax/jax';

class Auth {
  constructor(element) {
    this.element = element;
    this.type = element.dataset.authContent;

    this.bind();
  }

  bind() {
    this.bindLinks();
    this.bindForms();
  }

  bindLinks() {
    const instance = this;
    this.element.querySelectorAll('[data-replace-link]:not([data-initialize="true"])').forEach((link) => {
      link.dataset.initialize = 'true';
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const jax = new Jax(link.href, 'GET', false);
        jax.send(new FormData()).then((html) => {
          instance.replace(html);
        });
      })
    });
  }

  bindForms() {
    const instance = this;
    this.element.querySelectorAll('[data-replace-form]:not([data-initialize="true"])').forEach((form) => {
      form.dataset.initialize = 'true';
      form.addEventListener('submit', (e) => {
        e.preventDefault();
        const jax = new Jax(form.action, 'POST', false);
        jax.send(new FormData(form)).then((html) => {
          instance.replace(html);
        });
      })
    })
  }

  replace(html) {
    const page = document.createElement('div');
    page.innerHTML = html;
    const authContent = page.querySelector('[data-auth-content]');
    console.log(authContent)
    if (authContent) {
      this.element.innerHTML = authContent.innerHTML;
      this.bind();
    }
    document.dispatchEvent(new Event('DOMContentMutated'))
  }
}

onDomChanges(() => {
  document.querySelectorAll('[data-auth-content]:not([data-initialize="true"])').forEach((block) => {
    block.dataset.initialize = 'true';
    new Auth(block);
  });
  // data-auth-content
})