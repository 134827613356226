<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#1D202C" d="M0 0h16v16H0z"/><path d="m4 8 3 3 5-6" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
</template>

<script>
  export default {
    name: "CheckMarkBlack"
  }
</script>

<style scoped>

</style>