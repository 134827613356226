<template>
  <div>
    <Notifications/>

    <div class="container">

      <Preloader class="container__preloader" v-if="loading"/>

      <div class="schedule">

        <div class="schedule__services">
          <Services/>
        </div>

        <div class="schedule__content">

          <div class="schedule__datepicker">
            <DatePicker/>
          </div>

          <div class="schedule__service-centers">
            <ServiceCenters/>
          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex';
import Notifications from './Notification/Notifications.vue';
import Services from './Services/TheServices.vue';
import DatePicker from "./DatePicker/TheDatePicker.vue";
import ServiceCenters from "./ServiceCenter/TheServiceCenters.vue";
import Preloader from "./Preloader/ThePreloader.vue";

export default {
  name: 'App',
  components: {
    Notifications,
    Services,
    DatePicker,
    ServiceCenters,
    Preloader
  },
  data() {
    return {
      graph: []
    }
  },
  computed: {
    ...mapState({
      'date': 'scheduleAdmin/date/date',
      'serviceId': 'scheduleAdmin/service/activeItemId'
    }),
    ...mapGetters({
      'loading': 'scheduleAdmin/isAdminLoading'
    })
  },
  methods: {
    ...mapActions('scheduleAdmin/service', {
      'init': 'init'
    })
  },
  mounted() {

    this.init();
  }
}
</script>

<style lang="scss">
.schedule {
  &__services {
    padding: 30px 30px 20px;
    background: #1F2129;
  }

  &__content {
    display: flex;
    padding: 30px 30px 20px;
    flex-wrap: nowrap;
  }

  &__datepicker {
    margin-right: 20px;
    margin-bottom: 20px;
    flex: 0 0 300px;
  }

  &__service-centers {
    flex: 1 1 auto;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  picture {
    display: flex;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  img {
    max-width: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
    -webkit-tap-highlight-color: var(--t);
  }

  //body {
  //  background-color: #DDDDE1;
  //}

  //.container {
  //  font-family: 'Roboto', serif;
  //}

  //html, body, div, span, applet, object, iframe,
  //h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  //a, abbr, acronym, address, big, cite, code,
  //del, dfn, em, img, ins, kbd, q, s, samp,
  //small, strike, strong, sub, sup, tt, var,
  //b, u, i, center,
  //dl, dt, dd, ol, ul, li,
  //fieldset, form, label, legend,
  //table, caption, tbody, tfoot, thead, tr, th, td,
  //article, aside, canvas, details, embed,
  //figure, figcaption, footer, header, hgroup,
  //menu, nav, output, ruby, section, summary,
  //time, mark, audio, video {
  //  margin: 0;
  //  padding: 0;
  //  border: 0;
  //  font-size: 100%;
  //  font: inherit;
  //  vertical-align: baseline;
  //}





  //* {
  //  box-sizing: border-box;
  //  outline: none;
  //}


}

</style>