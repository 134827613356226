export default class CompanyHistoryItem {
    constructor(el, options) {
        this.el = el;
        this.progress = this.el.querySelector('[data-company-history-item-progress]');
        this.progressHeight = 0;
        this.image = options.image ? options.image : undefined;

        this.update();
    }

    computed(offset) {
        const middleScreenOffset = (offset + window.innerHeight / 2);
        if (this.el.offsetTop < middleScreenOffset) {
            this.addClass();
        } else {
            this.removeClass();
        }

        const elBottom = this.el.offsetTop + this.el.offsetHeight;
        if (this.el.offsetTop > middleScreenOffset) {
            this.progressHeight = 0;
        } else if (this.el.offsetTop < middleScreenOffset && elBottom > middleScreenOffset) {
            this.progressHeight = (middleScreenOffset - this.el.offsetTop) / (this.el.offsetHeight / 100);
        } else if (elBottom < middleScreenOffset) {
            this.progressHeight = 100;
        }
    }

    addClass() {
        this.el.classList.add('_active');

        if (window.innerWidth > 1200 && this.image) {
            this.image.classList.add('_active');
        }
    }

    removeClass() {
        this.el.classList.remove('_active');

        if (window.innerWidth > 1200 && this.image) {
            this.image.classList.remove('_active');
        }
    }

    setStyles() {
        this.progress.style.height = `calc(${this.progressHeight}% - 61px)`;
    }

    update(offset) {
        this.computed(offset);
        this.setStyles();
    }
}
