import {onDomReady} from "../../components/dynamic/observer";
import {Autoplay, FreeMode, Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    document.querySelectorAll('[data-manufacturers-container]')
        .forEach((el) => {
            const slider = new Swiper(el.querySelector('[data-manufacturers-slider]'), {
                modules: [Navigation, Autoplay, FreeMode],
                spaceBetween: 0,
                speed: 700,
                freeMode: true,
                autoplay: {
                    delay: 5000,
                },
                navigation: {
                    prevEl: el.querySelector('[data-manufacturers-left]'),
                    nextEl: el.querySelector('[data-manufacturers-right]'),
                },
                breakpoints: {
                    // when window width is >= 320px
                    0: {
                        loop: false,
                        autoplay: false,
                        slidesPerView: "auto"
                    },
                    1199: {
                        slidesPerView: 5,
                    },
                    1300: {
                        slidesPerView: 6
                    }
                }
            });
        })
}

onDomReady(init);