<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m7.938 4.968 1.781 3.085L11.724 10l-2 1.947-1.781 3.085 1.59 2.759-.542.942H7.903l-1.05-1.816L5.652 19h-1.09l-.544-.943 1.206-2.084h-2.1l-.544-.942.544-.942H6.31l1.781-3.084H4.533l-1.6 2.759H1.848l-.544-.943 1.05-1.817L.223 11 0 9.933l.22-.942 2.13.004L1.3 7.174l.544-.942h1.085l1.6 2.763h3.558L6.307 5.91H3.12l-.55-.942.544-.943h2.1l-1.2-2.083L4.56 1h1.09l1.2 2.083L7.9 1.267h1.088l.542.942-1.591 2.759Z" fill="#C1CDDF"/><path fill-rule="evenodd" clip-rule="evenodd" d="m12.187 18.508-.753-2.812a5.605 5.605 0 0 1-1.615-.079l.861-1.784a3.764 3.764 0 0 0 1.496-7.301 3.747 3.747 0 0 0-1.358-.222L9.79 4.538c.23-.047.46-.078.69-.096l.277-2.99a.941.941 0 1 1 1.877.145l-.281 3.02a5.672 5.672 0 0 1 1.813.85l2.213-2.22a.941.941 0 1 1 1.278 1.382L15.5 6.794c.322.452.583.96.764 1.512l2.617-.399a.929.929 0 0 1 1.047.793.928.928 0 0 1-.764 1.068l-2.615.398a5.625 5.625 0 0 1-.42 2.044l2.502 1.552a.941.941 0 0 1-.993 1.6l-2.501-1.553a5.613 5.613 0 0 1-1.885 1.4l.753 2.813a.941.941 0 0 1-1.818.486Z" fill="#E8C759"/></svg>
</template>

<script>
  export default {
    name: "AllSeason"
  }
</script>

<style scoped>

</style>