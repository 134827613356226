<template>
  <ul class="cart-errors" v-if="errors && errors.length > 0">
    <li v-for="error in errors" class="cart-errors__error">
      {{ error }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "ItemErrors",
  props: ['errors']
}
</script>

<style lang="scss">
  .cart-errors {
    color: red;
    list-style: none;
    margin: 3px 0 5px;
    padding: 0;
    font-size: 12px;

    &:empty {
      margin-bottom: 0;
    }
  }
</style>