<template>
  <div class="record-for-service-form">
    <div class="record-for-service-form__fields">
      <div class="record-for-service-form__field">
        <base-field-input
            :fieldId="name.id"
            :label="name.label"
            v-model="name.value"
            :placeholder="name.placeholder"
            :errors="name.errors"
        />
      </div>

      <div class="record-for-service-form__field">
        <base-field-phone
            :fieldId="phone.id"
            :label="phone.label"
            v-model="phone.value"
            :placeholder="phone.placeholder"
            :errors="phone.errors"
        />
      </div>
    </div>
    <div class="record-for-service-form__info text__mini">
      * Поля со звёздочкой обязательны
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import BaseFieldInput from "../parts/fields/BaseFieldInput.vue";
  import BaseFieldPhone from "../parts/fields/BaseFieldPhone.vue";
  import ScheduleService from "../../services/schedule.service";
  import {scrollToTarget} from "../../../components/scroll/scroll";

  export default {
    name: "TheScheduleServiceForm",
    components: {
      BaseFieldPhone,
      BaseFieldInput,
    },
    data() {
      return {
        name: {
          value: '',
          fieldName: 'name',
          id: 'name',
          label: 'Контактное лицо*',
          placeholder: 'Ваше имя',
          errors: []
        },
        phone: {
          value: '',
          fieldName: 'phone',
          label: 'Телефон*',
          placeholder: 'Номер телефона',
          id: 'phone',
          errors: []
        },
      }
    },
    methods: {
      ...mapActions('schedule', [
        'loadGraph',
      ]),
      ...mapActions('notification', [
        'showErrorNotification',
        'showSuccessNotification',
      ]),
      ...mapMutations('schedule', {
        'setLoading': 'setLoading',
        'unsetLoading': 'unsetLoading',
        'setOrderData': 'setOrderData'
      }),
      async submit(data) {
        if(!this.selectedSlot) {
          this.showErrorNotification('Не выбрано время записи');
          return null;
        }

        if (this.phone.value.length && this.phone.value.length !== 18) {
          this.showErrorNotification('Короткий номер телефона');
          return null;
        }

        this.setLoading();

        try {
          const response = await ScheduleService.saveRecord({
            name: this.name.value,
            date: this.selectedSlot.dateTime,
            postId: this.selectedSlot.postId,
            serviceId: this.activeService.id,
            phone: this.phone.value,
            carAndLicensePlateNumber: '',
            carType: '',
            userId: this.userId,
            recordId: data ? data.recordId : null,
            height: this.details.formHeight,
            width: this.details.formWidth,
            diameter: this.details.formDiameter,
            detailsVariant: this.details.detailsVariant,
            inStorageVariant: this.details.inStorageVariant,
            orderNumber: this.details.orderNumber,
            saveOrderNumber: this.details.saveOrderNumber,
            saveOrderName: this.details.saveOrderName

          }).then((result) => {
              this.setOrderData(result.data.data[0]);
              setTimeout(() => {
                  scrollToTarget(
                      document.querySelector('[data-record-for-service-success]'),
                      document.querySelector('[data-header]').offsetHeight * -2
                  );
              }, 100);
          });
        } catch (e) {
          this.cleanErrors();
          this.prepareErrors(e.response.data.errors);
          this.showErrorNotification('При заполнении формы допущены ошибки');
          this.unsetLoading();
        }
      },
      prepareErrors(errors) {
        console.log(errors)
        for (const error in errors) {
          if(errors.hasOwnProperty(error)) {
            switch (error) {
              case 'detailsVariant':
                this.details.errors.detailsVariantError = errors[error];
                break;
              case 'inStorageVariant':
                this.details.errors.inStorageVariantError = errors[error];
                break;
              case 'orderNumber':
                this.details.errors.orderNumberError = errors[error];
                break;
              case 'saveOrderName':
                this.details.errors.saveOrderNameError = errors[error];
                break;
              case 'saveOrderNumber':
                this.details.errors.saveOrderNumberError = errors[error];
                break;
              case 'width':
                this.details.errors.widthError = errors[error];
                break;
              case 'height':
                this.details.errors.heightError = errors[error];
                break;
              case 'diameter':
                this.details.errors.diameterError = errors[error];
                break;
              case 'name':
                this.name.errors = errors[error];
                break;
              case 'phone':
                this.phone.errors = errors[error];
                break;
            }
          }
        }
      },
      clean() {
        this.cleanErrors();
        this.cleanFields();
        this.cleanPolicy();
      },
      cleanErrors() {
        // this.carAndLicensePlateNumber.errors = [];
        this.name.errors = [];
        this.phone.errors = [];
        this.details.errors.widthError = [];
        this.details.errors.heightError = [];
        this.details.errors.diameterError = [];
        this.details.errors.detailsVariantError = [];
        this.details.errors.inStorageVariantError = [];
        this.details.errors.orderNumberError = [];
        this.details.errors.saveOrderNameError= [];
        this.details.errors.saveOrderNumberError = [];
      },
      cleanFields() {
        // this.carAndLicensePlateNumber.value = '';
        this.name.value = '';
        this.phone.value = '';
      },
      cleanPolicy() {
        this.$emit('cleanPolicy', false);
      },
    },
    computed: {
      ...mapState('schedule', [
        'carTypes',
        'selectedSlot',
        'activeService',
        'userId',
        'editableRecord',
        'details'
      ])
    },
    watch: {
      editableRecord: {
        handler() {
          this.name.value = this.editableRecord.name;
          this.phone.value = this.editableRecord.phone;
        }
      }
    },
    mounted() {
      this.emitter.on('submitRecord', (data) => {
        if(data) {
          this.submit(data);
        } else {
          this.submit();
        }
      });
    }
  }
</script>

<style lang="scss">

</style>