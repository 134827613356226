const dateModule = {
  namespaced: true,
  state: {
    date: new Date()
  },
  actions: {
    setDate({commit, dispatch}, date) {
      commit('setDate', date);
      dispatch('scheduleAdmin/graph/loadGraph', null, {root: true});
    }
  },
  getters: {
    timestamp: (state) => {
      return Math.round(state.date.getTime()/1000);
    }
  },
  mutations: {
    setDate(state, date) {
      state.date = date;
    }
  },
};

export default dateModule;