import { createApp } from 'vue';

import App from './App.vue';

import TheScheduleUserService from "./components/ScheduleService/User/TheScheduleUserService.vue";
import TheScheduleUserProfileService from "./components/ScheduleService/User/TheScheduleUserProfileService.vue";
import ThePriceCompetitor from './components/PriceCompetitor/ThePriceCompetitor.vue';
import ThePriceCompetitorInfo from './components/PriceCompetitor/ThePriceCompetitorInfo.vue';

import TheFavoritesWidget from "./components/favorites/TheFavoritesWidget.vue";
import TheCartWidget from "./components/cart/TheCartWidget.vue";
import TheCartPage from "./components/cart/TheCartPage.vue";
import TheNotification from "./components/notification/TheNotification.vue";

import store from './store';

import { createPinia } from "pinia";
import { initFilters } from "./filter/init";

import mitt from 'mitt';
import maska from "maska";

import TheScheduleUserProfileServiceList from "./components/ScheduleService/User/TheScheduleUserProfileServiceList";
import TheCartAddedProduct from "./components/cart/TheCartAddedProduct.vue";
import ThePriceManual from "./components/price-manual/ThePriceManual.vue";
import TheScheduleAdmin from "./components/ScheduleAdmin/TheScheduleAdmin.vue";

const pinia = createPinia();
initFilters(pinia);

const emitter = mitt();


if (document.querySelector('#app')) {
  const app = createApp(App);
  app.mount('#app');
}

// Запись на сервис
const scheduleUserServiceEl = document.querySelector('#schedule-user-service');
if (scheduleUserServiceEl) {
  const app = createApp(
    TheScheduleUserService,
    {
      userId: scheduleUserServiceEl.dataset.profileServiceUserId,
    }
  ).use(store);
  app.config.globalProperties.emitter = emitter;
  app.mount('#schedule-user-service');
}

// Запись на сервис лк клиента список
const userProfileServiceListEl = document.querySelector('#profile-service-list');
if (userProfileServiceListEl) {
  const app = createApp(
    TheScheduleUserProfileServiceList,
    {
      userId: userProfileServiceListEl.dataset.profileServiceUserId,
    }
  ).use(store);
  app.config.globalProperties.emitter = emitter;
  app.mount('#profile-service-list');
}

// Запись на сервис лк клиента
const userProfileServiceEl = document.querySelector('#user-profile-service');
if (userProfileServiceEl) {
  const app = createApp(
    TheScheduleUserProfileService,
    {
      userId: userProfileServiceEl.dataset.profileServiceUserId,
      title: userProfileServiceEl.dataset.profileServiceTitle,
      recordId: userProfileServiceEl.dataset.profileServiceRecordId,
    }
  ).use(store);
  app.config.globalProperties.emitter = emitter;
  app.mount('#user-profile-service');
}

// Страница с конкурентами
if (document.querySelector('#price-competitor')) {
  const app = createApp(ThePriceCompetitor).use(store);
  app.config.globalProperties.emitter = emitter;
  app.mount('#price-competitor');
}

// Страница с конкурентами info
if (document.querySelector('#price-competitor-info')) {
  const app = createApp(ThePriceCompetitorInfo).use(store);
  app.config.globalProperties.emitter = emitter;
  app.mount('#price-competitor-info');
}

// Страница с ручными ценами
if (document.querySelector('#price-manual')) {
  const app = createApp(ThePriceManual).use(store);
  app.config.globalProperties.emitter = emitter;
  app.mount('#price-manual');
}

if (document.querySelector('#favorites-widget')) {
  const app = createApp(TheFavoritesWidget).use(store);
  app.config.globalProperties.emitter = emitter;
  app.mount('#favorites-widget');
}

if (document.querySelector('#cart-widget')) {
  const app = createApp(TheCartWidget).use(store);
  app.config.globalProperties.emitter = emitter;
  app.mount('#cart-widget');
}

if (document.querySelector('#cart-page')) {
  const app = createApp(TheCartPage).use(store);
  app.use(maska);
  app.config.globalProperties.emitter = emitter;
  app.mount('#cart-page');
}
if (document.querySelector('#notifications')) {
  const app = createApp(TheNotification).use(store);
  app.config.globalProperties.emitter = emitter;
  app.mount('#notifications');
}

if (document.querySelector('#schedule-app')) {
  const app = createApp(TheScheduleAdmin).use(store);
  app.config.globalProperties.emitter = emitter;
  app.mount('#schedule-app');
}

document.addEventListener('DOMContentMutated', () => {
  const addedProduct = document.querySelector('#added-buyable');
  if (addedProduct) {
    const app = createApp(
      TheCartAddedProduct,
      {
        buyableId: addedProduct.dataset.addedBuyableId
      }
    ).use(store);
    app.config.globalProperties.emitter = emitter;
    app.mount('#added-buyable');
  }
});

document.addEventListener('cartAdd', (e) => {
  store.dispatch('cart/add', e.detail);
});

document.addEventListener('favoritesAdd', (e) => {
  store.dispatch('favorites/add', e.detail);
});

document.addEventListener( 'favoritesRemove', (e) => {
  store.dispatch('favorites/remove', e.detail);
});