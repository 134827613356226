<template>
  <div class="vue-field vue-field_input form-field"
       :class="{ '_has-errors': errors.length }"
  >
    <label :for="fieldId" class="vue-field__label required">
      {{ label }}
    </label>
    <input type="text"
           class="vue-field__input required"
           :class="{ _filled: setFilledInput }"
           :value="modelValue"
           :id="fieldId"
           :name="fieldId"
           :placeholder="placeholder"
           required="required"
           ref="input"
           @input="$emit('update:modelValue', $event.target.value)"
    >
    <Errors :errors="errors"/>
  </div>
</template>

<script>
  import Errors from "../common/Errors.vue";

  export default {
    name: 'BaseFieldInput',
    components: {
      Errors,
    },
    data() {
      return {
        value: '',
      }
    },
    props: {
      fieldId: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      modelValue: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        required: true,
      },
      errors: {
        type: Array,
        required: true,
      },
    },
    computed: {
      setFilledInput() {
        return !!this.value;
      },
    }
  };
</script>

<style scoped>

</style>