<template>
  <div class="cart-order-block cart-order-block_receiving">
    <div class="cart-order-block__head">
      <div class="cart-order-block__number-wrap">
        <div class="cart-order-block__number">
          02
        </div>
      </div>
      <h3 class="cart-order-block__title">
        Способ получения
      </h3>
    </div>

    <div class="cart-order-block__content form-field">
      <ul class="tabs tabs_choice cart-order-block__tabs">
        <li class="tabs__item" :class="{'_active': isPickup}">
          <a href="javascript:void(0);" class="tabs__link" title="Самовывоз"  @click="setPickup">
            Самовывоз
          </a>
        </li>
        <li class="tabs__item" :class="{'_active': isDelivery}">
          <a href="javascript:void(0);" class="tabs__link" title="Доставка" @click="setDelivery">
            Доставка
          </a>
        </li>
      </ul>

      <div class="cart-order-block__tab-contents">
        <div class="cart-order-block__tab-content" v-if="isPickup">
          <TheCartOrderReceivingPickup/>
        </div>
        <div class="cart-order-block__tab-content" v-if="isDelivery">
          <TheCartOrderReceivingDelivery/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import TheCartOrderReceivingPickup from "./TheCartOrderReceivingPickup.vue";
import TheCartOrderReceivingDelivery from "./TheCartOrderReceivingDelivery.vue";

export default {
  name: "TheCartOrderReceiving",
  components: {TheCartOrderReceivingDelivery, TheCartOrderReceivingPickup},
  computed: {
    receiving: {
      get () {
        return this.$store.state.order.receiving
      },
      set (value) {
        this.$store.commit('order/setReceiving', value)
      }
    },
    ...mapGetters('order', {
      'isPickup': 'isPickup',
      'isDelivery': 'isDelivery',
    }),
  },
  methods: {
    setPickup() {
      this.receiving = 10;
    },
    setDelivery() {
      this.receiving = 20;
    }
  }
}
</script>

<style lang="scss">

</style>