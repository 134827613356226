<template>
  <div class="record-for-service" :class="{ _loading: loading }">
    <div class="record-for-service__inner" v-if="!Object.keys(orderData).length">
      <div class="record-for-service__box">
        <div class="record-for-service__heading">
          <TheHeadingRoundIndex :index="'1'" :title="'Услуга'"/>
        </div>

        <div class="record-for-service__content">
          <TheScheduleServicesList/>
        </div>
      </div>

      <div class="record-for-service__box">
        <div class="record-for-service__heading">
          <TheHeadingRoundIndex :index="'2'" :title="'Дата и время'"/>
        </div>

        <div class="record-for-service__content">
          <TheScheduleServicePoint/>
        </div>
      </div>

      <div class="record-for-service__box">
        <div class="record-for-service__heading">
          <TheHeadingRoundIndex :index="'3'" :title="'Контактная информация'"/>
        </div>

        <div class="record-for-service__content">
          <TheScheduleServiceForm @cleanPolicy="setPolicyCheckbox"/>
        </div>
      </div>

      <div v-if="isTyreChangeService" class="record-for-service__box">
        <div class="record-for-service__heading">
          <TheHeadingRoundIndex :index="'4'" :title="'Детали заявки'"/>
        </div>

        <div class="record-for-service__content">
          <TheScheduleServiceDetailsForm/>
        </div>
      </div>

      <div class="record-for-service__info-wrap">
        <div class="record-for-service__info">
          <div class="record-for-service__info-title text__mini">
            Стоимость услуги определяется специалистом в зависимости от объема и срочности работы
          </div>

          <a href="/request/recall-service-modal"
             title="Помощь с записью"
             class="record-for-service__question-link"
             data-modal
          >
            <span class="record-for-service__question-icon">
              <Question/>
            </span>
            <span class="record-for-service__question-title text__small">
              Помощь с записью
            </span>
          </a>
        </div>
      </div>

      <div class="record-for-service__confirm">
        <div class="record-for-service__policy">
          <PolicyCheckbox :id="'record-for-service'"
                          v-model="policyCheckbox.isChecked"
                          :show-error="policyCheckbox.showError"
          />
        </div>

        <a href="javascript:void(0);"
           title="Записаться"
           class="record-for-service__submit button button_primary"
           @click="submit"
        >
          Записаться
        </a>
      </div>
    </div>

    <TheScheduleServiceSuccess v-if="Object.keys(orderData).length"/>

  </div>
</template>

<script>
  import {mapActions, mapMutations, mapState} from "vuex";
  import TheScheduleServicesList from "../TheScheduleServicesList.vue";
  import TheScheduleServicePoint from "../TheScheduleServicePoint.vue";
  import TheScheduleServiceForm from "../TheScheduleServiceForm.vue";
  import PolicyCheckbox from "../../parts/common/policy-checkbox.vue";
  import Question from "../../icons/Question.vue";
  import TheHeadingRoundIndex from "../../parts/common/TheHeadingRoundIndex.vue";
  import TheScheduleServiceSuccess from "../TheScheduleServiceSuccess.vue";
  import TheScheduleServiceDetailsForm from "../TheScheduleServiceDetailsForm.vue";
  // import Notifications from "../Notification/Notifications.vue";
  // import ThePreloader from "../Preloader/ThePreloader.vue";

  export default {
    name: "TheScheduleUserService",
    props: {
      userId: null
    },
    components: {
      TheScheduleServiceDetailsForm,
      TheScheduleServiceSuccess,
      TheHeadingRoundIndex,
      Question,
      PolicyCheckbox,
      TheScheduleServiceForm,
      TheScheduleServicePoint,
      TheScheduleServicesList,
      // ThePreloader,
      // Notifications,
    },
    data() {
      return {
        policyCheckbox: {
          isChecked: true,
          showError: false,
        }
      }
    },
    computed: {
      ...mapState('schedule', [
        'loading',
        'orderData',
        'activeService',
      ]),
      isTyreChangeService() {
        return (this.activeService.id === 1);
      },
      isPolicyChecked() {
        return this.policyCheckbox.isChecked;
      },
    },
    methods: {
      ...mapActions('schedule', [
        'init'
      ]),
      ...mapMutations('schedule', [
        'setUserId',
      ]),
      setPolicyCheckbox(value) {
        this.policyCheckbox.isChecked = value;
      },
      submit() {
        if (!this.isPolicyChecked) {
          this.policyCheckbox.showError = true;
        } else {
          this.policyCheckbox.showError = false;
          this.emitter.emit('submitRecord');
          window.goalManager.proceedGoal('yandex', 'success_online_recording')
          window.goalManager.proceedGoal('vk', 'success_online_recording')
          window.goalManager.proceedGoal('google', 'sign_up_for_services#success')
        }
      }
    },
    mounted() {
      this.setUserId(parseInt(this.userId, 10));
      this.init();
    },
  }
</script>

<style lang="scss">

</style>