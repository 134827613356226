import Jax from "../../components/jax/jax.js";
import IMask from "imask";
import {onDomChanges} from "../../components/dynamic/observer";


const initPhoneField = () => {
  document.querySelectorAll('[data-float-field]:not([data-initialized])').forEach((input) => {
    input.dataset.initialized = "true";

    const mask = IMask(input, {
      mask: Number,

      scale: 2,
      thousandsSeparator: '',
      padFractionalZeros: false,
      normalizeZeros: true,
      radix: ',',
      mapToRadix: ['.'],

      min: 0,
      max: 99999
    });
  });
}

onDomChanges(initPhoneField);