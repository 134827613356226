<template>
  <div class="user-profile-record-for-service">
    <div class="user-profile-record-for-service__header profile-service-record__header">
      <a href="/profile/service"
         title="Назад к услугам"
         class="user-profile-record-for-service__back profile-service-record__back"
      >
        <ArrowLeft/>
      </a>

      <h3 class="user-profile-record-for-service__title profile-service-record__title">
        <span v-if="isChanged">
          Запись №{{ orderData.recordId }} изменена
        </span>
        <span v-else-if="isCreated">
          Запись №{{ orderData.recordId }} создана
        </span>
        <span v-else>
          {{ title }}
        </span>
      </h3>
    </div>

    <div class="user-profile-record-for-service__inner" v-if="!Object.keys(orderData).length">
      <div class="user-profile-record-for-service__box">
        <h4 class="user-profile-record-for-service__heading">
          Услуги
        </h4>

        <div class="user-profile-record-for-service__content">
          <TheScheduleServicesList/>
        </div>
      </div>

      <div class="user-profile-record-for-service__box">
        <h4 class="user-profile-record-for-service__heading">
          Сервисный центр
        </h4>

        <div class="user-profile-record-for-service__content">
          <TheScheduleServicePoint/>
        </div>
      </div>

      <div class="user-profile-record-for-service__box">
        <h4 class="user-profile-record-for-service__heading">
          Контактная информация
        </h4>

        <div class="user-profile-record-for-service__content">
          <TheScheduleServiceForm/>
        </div>
      </div>

      <div class="user-profile-record-for-service__box" v-if="recordId">
        <TheScheduleRerviceRecordInfo/>
      </div>

      <div class="user-profile-record-for-service__box user-profile-record-for-service__box_confirm"
           v-if="recordId"
      >
        <TheScheduleServiceEditConfirm/>
      </div>

      <div class="user-profile-record-for-service__confirm" v-if="!recordId">
        <div class="user-profile-record-for-service__policy">
          <PolicyCheckbox :id="'record-for-service'"
                          v-model="policyCheckbox.isChecked"
          />
        </div>

        <a href="javascript:void(0);"
           title="Записаться"
           class="user-profile-record-for-service__submit button button_primary"
           @click="submit"
        >
          Записаться
        </a>
      </div>
    </div>

    <TheScheduleServiceSuccess v-if="Object.keys(orderData).length"/>
  </div>
</template>

<script>
  import {mapActions, mapMutations, mapState} from "vuex";
  import TheScheduleServicesList from "../TheScheduleServicesList.vue";
  import TheScheduleServicePoint from "../TheScheduleServicePoint.vue";
  import TheScheduleServiceForm from "../TheScheduleServiceForm.vue";
  import TheScheduleServiceSuccess from "../TheScheduleServiceSuccess.vue";
  import TheScheduleRerviceRecordInfo from "../TheScheduleServiceRecordInfo.vue";
  import ArrowLeft from "../../icons/ArrowLeft";
  import TheScheduleServiceEditConfirm from "../TheScheduleServiceEditConfirm.vue";
  import PolicyCheckbox from "../../parts/common/policy-checkbox";

  export default {
    name: "TheScheduleUserProfileService",
    components: {
      PolicyCheckbox,
      TheScheduleServiceEditConfirm,
      ArrowLeft,
      TheScheduleServiceSuccess,
      TheScheduleServiceForm,
      TheScheduleServicePoint,
      TheScheduleServicesList,
      TheScheduleRerviceRecordInfo
    },
    props: {
      userId: null,
      title: '',
      recordId: null
    },
    data() {
      return {
        policyCheckbox: {
          isChecked: false,
        }
      }
    },
    computed: {
      ...mapState('schedule', [
        'loading',
        'orderData',
        'editableRecord'
      ]),
      isPolicyChecked() {
        return this.policyCheckbox.isChecked;
      },
      isChanged() {
        return Object.keys(this.orderData).length
          && this.editableRecord
          && this.orderData.recordId === this.editableRecord.recordId;
      },
      isCreated() {
        return Object.keys(this.orderData).length;
      }
    },
    methods: {
      ...mapActions('schedule', [
        'init',
        'getRecord',
        'initEditableRecord'
      ]),
      ...mapMutations('schedule', [
        'setUserId',
      ]),
      setPolicyCheckbox(value) {
        this.policyCheckbox.isChecked = value;
      },
      submit() {
        if (!this.isPolicyChecked) {
          //  TODO: policy not checked notification
        } else {
          this.emitter.emit('submitRecord');
        }
      }
    },
    mounted() {
      this.setUserId(parseInt(this.userId, 10));

      if (this.userId && this.recordId) {
        this.initEditableRecord({userId: this.userId, recordId: this.recordId});
      } else {
        this.init();
      }
    },
  }
</script>

<style scoped>

</style>