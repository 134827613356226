<template>
  <form action="" class="price-competitor-filters" @submit.prevent>
    <div class="price-competitor-filters__top">
      <ul class="price-competitor-filters__fields">
        <li class="price-competitor-filters__field"
            v-for="(field, name) in this.fields"
        >
          <base-field-select
              v-if="name === 'width'"
              :field-id="name"
              :values="field"
              :label="'Ширина'"
              :default-value="{ name: 'Любая', value: '0' }"
              v-model="settings.width"
              @input="updateTyres"
          />

          <base-field-select
              v-if="name === 'height'"
              :field-id="name"
              :values="field"
              :label="'Высота'"
              :default-value="{ name: 'Любая', value: '0' }"
              v-model="settings.height"
              @input="updateTyres"
          />

          <base-field-select
              v-if="name === 'diameter'"
              :field-id="name"
              :values="field"
              :label="'Диаметр'"
              :default-value="{ name: 'Любая', value: '0' }"
              v-model="settings.diameter"
              @input="updateTyres"
          />
          <base-field-select
              v-if="name === 'producer'"
              :field-id="name"
              :values="field"
              :label="'Производитель'"
              :default-value="{ name: 'Любая', value: '0' }"
              v-model="settings.producer"
              @input="updateTyres"
          />

          <base-field-select
              v-if="name === 'season'"
              :field-id="name"
              :values="field"
              :label="'Сезон'"
              :default-value="{ name: 'Любая', value: '0' }"
              v-model="settings.season"
              @input="updateTyres"
          />

          <base-field-select
              v-if="name === 'competitor'"
              :field-id="name"
              :values="field"
              :label="'Конкурент'"
              :default-value="{ name: 'Все', value: '0' }"
              v-model="settings.competitor"
              @input="updateTyres"
          />
        </li>
      </ul>
    </div>

    <div class="price-competitor-filters__bottom">
      <div class="price-competitor-filters__prices-field">
        <base-field-checkbox
            class="price-competitor-filters__prices-field-checkbox"
            fieldId="'prices'"
            label="Есть цены конкурентов"
            v-model:checked="settings.equalPricesField.isChecked"
            @update:checked="updateTyres"
        />
        <base-field-select
            field-id="prices-modifier"
            :values="equalPricesValues"
            v-model="settings.equalPricesField.equalSymbol"
            :no-default="true"
            :disabled="!settings.equalPricesField.isChecked"
            @input="updateTyres"
        />
      </div>

      <base-field-checkbox
          class="price-competitor-filters__tracked"
          fieldId="'tracked'"
          label="Отслеживаемые шины"
          v-model:checked="settings.tracked"
          @update:checked="updateTyres"
      />

      <base-field-checkbox
          class="price-competitor-filters__failed-links"
          fieldId="'failed-links'"
          label="Есть нерабочие ссылки"
          v-model:checked="settings.failedLinks"
          @update:checked="updateTyres"
      />
      <base-field-checkbox
          class="price-competitor-filters__failed-links"
          fieldId="'hasManualPrice'"
          label="Есть ручные цены"
          v-model:checked="settings.manualPrices"
          @update:checked="updateTyres"
      />
      <base-field-checkbox
          class="price-competitor-filters__failed-links"
          fieldId="'hasManualOldPrice'"
          label="Есть ручные старые цены"
          v-model:checked="settings.manualOldPrices"
          @update:checked="updateTyres"
      />
    </div>

  </form>
</template>

<script>
import DropdownField from "../../filter/field/DropdownField";
import {mapActions, mapMutations, mapState} from "vuex";
import BaseFieldSelect from "../parts/fields/BaseFieldSelect.vue";
import BaseFieldCheckbox from "../parts/fields/BaseFieldCheckbox.vue";

export default {
  name: "ThePriceCompetitorFilters",
  components: {BaseFieldCheckbox, BaseFieldSelect, DropdownField},
  data() {
    return {
      equalPricesValues: [
        {name: 'Ниже', value: 'low'},
        {name: 'Совпадают', value: 'equal'},
        {name: 'Выше', value: 'high'}
      ]
    }
  },
  computed: {
    ...mapState('priceCompetitorFilter', [
      'settings',
      'fields',
    ]),
    ...mapState('priceCompetitorItems', [
      'tyres',
    ]),
  },
  methods: {
    ...mapMutations('priceCompetitorFilter', [
        'setPage',
        'setFalseIsLastPage'
    ]),
    ...mapActions('priceCompetitorItems', [
        'fetchTyres'
    ]),
    updateTyres() {
      this.setPage(1);
      this.setFalseIsLastPage();
      setTimeout(() => {
        this.fetchTyres();
      }, 50);
    },
  },
}
</script>

<style scoped>

</style>