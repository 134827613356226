<template>
  <div class="admin-services">
    <ul class="admin-services__list">
      <li v-for="service in services" class="admin-services__item" :class="{ _active: isActive(service.id) }">
        <a href="javascript:void(0)" class="admin-services__item-link" @click="setActive(service.id)">
          {{ service.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

export default {
  name: "Services",
  computed: {
    ...mapState('scheduleAdmin/service', {
      'services': 'services',
      'activeItemId': 'activeItemId'
    })
  },
  methods: {
    setActive(activeItemId) {
      if (activeItemId !== this.activeItemId) {
        this.$store.dispatch('service/setActive', activeItemId);
      }
    },
    isActive(activeItemId) {
      return this.activeItemId === activeItemId;
    }
  }
}
</script>

<style lang="scss">
.admin-services {
  &__list {
    display: flex;
    margin: 0 -9px;
  }

  &__item {
    padding: 0 9px;

    &._active {
      .admin-services {
        &__item-link {
          color: #787B83;
          background-color: white;
        }
      }
    }
  }

  &__item-link {
    display: block;
    color: #fff;
    font-weight: 400;
    line-height: 133%;
    font-size: 15px;
    padding: 10px 14px;
    background-color: rgba(255, 255, 255, .1);
    border-radius: 3px;
  }
}
</style>