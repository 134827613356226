import {onDomReady} from "../../components/dynamic/observer";
import Swiper, {EffectCreative, EffectFade, Navigation, Pagination} from "swiper";

onDomReady(() => {
    document.querySelectorAll('[data-company-advantages-slider]')
        .forEach((el) => {
            const textSlider = new Swiper(el.querySelector('[data-company-advantages-slider-text]'), {
                modules: [Pagination, Navigation, EffectCreative],
                effect: "creative",
                creativeEffect: {
                    prev: {
                        translate: [0, '-20px', 0],
                        opacity: 0,
                    },
                    next: {
                        translate: [0, '20px', 0],
                        opacity: 0,
                    },
                },
                loop: false,
                rewind: true,
                preventInteractionOnTransition: true,
                speed: 1000,
                pagination: {
                    el: el.querySelector('[data-company-advantages-slider-pagination]'),
                    type: "fraction",
                },
                navigation: {
                    prevEl: el.querySelector('[data-company-advantages-slider-left]'),
                    nextEl: el.querySelector('[data-company-advantages-slider-right]'),
                },
            });

            const imageSlider = new Swiper(el.querySelector('[data-company-advantages-slider-images]'), {
                modules: [EffectFade],
                speed: 1000,
                loop: false,
                rewind: true,
                effect: "fade",
            });

            textSlider.on('activeIndexChange', () => {
                imageSlider.slideTo(textSlider.activeIndex);
            })
        })
});