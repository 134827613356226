<template>
  <div class="price-competitor-history-prices">
    <div class="price-competitor-history-prices__inner">
      <div class="price-competitor-history-prices__heading">
        <div class="price-competitor-history-prices__title">
          {{ title }}
        </div>

        <a href="javascript:void(0);"
           class="price-competitor-history-prices__close"
           @click="close"
        >
          <CloseIcon/>
        </a>
      </div>

      <div class="price-competitor-history-prices__content">
        <div class="price-competitor-history__chart-wrap"
             v-if="prices.length"
        >
          <Bar
              class="price-competitor-history-prices__chart"
              :chart-options="chartOptions"
              :chart-data="chartData"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :styles="styles"
          />
        </div>

        <div class="price-competitor-history-prices__empty"
             v-if="!prices.length && !isLoading"
        >
          История цен отсутствует
        </div>
      </div>
    </div>

    <div class="price-competitor-history-prices__loader lds-ring"
         v-if="isLoading"
    >
      <div></div><div></div><div></div><div></div>
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
import CloseIcon from "../icons/CloseIcon";

export default {
  name: "ThePriceCompetitorHistoryPrices",
  components: {CloseIcon, Bar },
  data() {
    return {
      wide: window.innerWidth > 1000,
      chartOptions: {
        responsive: true,
        aspectRatio: 2,
      }
    }
  },
  props: {
    chartId: {
      type: String,
      required: true,
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    title: {
      type: String,
      required: false,
    },
    prices: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    labels() {
      return this.prices.map(item => this.formatDate(item.updatedAt));
    },
    data() {
      return this.prices.map(item => item.price);
    },
    chartData() {
      return {
        labels: this.labels,
        datasets: [
            {
              label: 'Цены',
              data: this.data,
              backgroundColor: 'rgba(57, 172, 38, 0.5)',
              borderColor: '#8EB681',
              pointRadius: 1,
              pointHoverBackgroundColor: '#8EB681',
              pointHoverBorderColor: '#8EB681',
              pointHoverRadius: 5,
              pointHitRadius: 100,
            },
        ]
      }
    },
    styles: () => {
      return {
        position: 'relative',
        backgroundColor: '#F1F2F4',
      }
    }
  },
  methods: {
    formatDate(dateAsString) {
      const date = new Date(Date.parse(dateAsString));
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      return `${day}.${month}.${date.getFullYear()}`;
    },
    close() {
      this.$emit('close', {});
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>