import {onDomChanges} from "../../components/dynamic/observer";
import {singleBind, singleInit} from "../../components/dynamic/bind";
import _ from "lodash";

function addToCart(e) {
  const button = this;
  let quantity = 1;

  const block = button.closest('[data-cart-block]');
  let quantityBlock = null;
  if (block) {
    quantityBlock = block.querySelector('[data-cart-quantity]');
  } else {
    quantityBlock = document.querySelector('[data-cart-quantity]');
  }

  if (quantityBlock) {
    if (quantityBlock.tagName === 'INPUT') {
      quantity = parseInt(quantityBlock.value);
    } else {
      quantity = parseInt(quantityBlock.dataset.cartQuantity);
    }
  }
  if (!quantity) {
    quantity = 1;
  }

  document.dispatchEvent(new CustomEvent('cartAdd', {
    detail: {
      buyable: button.dataset.buyable,
      id: parseInt(button.dataset.id),
      quantity: quantity,
      element: button
    }
  }));
}

function bindQuantityElements(cartBlock) {
  const quantity = cartBlock.querySelector('[data-cart-quantity]');
  if (quantity) {
    const maxQuantity = parseInt(quantity.getAttribute('max'), 10);
    const minQuantity = parseInt(quantity.getAttribute('min'), 10) || 1;

    const increaseButton = cartBlock.querySelector('[data-cart-quantity-increase]');
    const decreaseButton = cartBlock.querySelector('[data-cart-quantity-decrease]');

    let itemPrice = 0;
    const totalPriceBlock = cartBlock.querySelector('[data-total-price]');
    const itemPriceBlock = cartBlock.querySelector('[data-item-price]');
    if (itemPriceBlock) {
      itemPrice = parseInt(itemPriceBlock.dataset.itemPrice, 10);
    }

    quantity.addEventListener('input', _.debounce((e) => {
      const input = e.target;
      const value = parseInt(input.value);
      if (value < 1 || isNaN(value)) {
        input.value = 1;
      }
    }, 500));

    if (increaseButton) {
      increaseButton.addEventListener('click', (e) => {
        const count = parseInt(quantity.value, 10) + 1;
        if (count <= maxQuantity) {
          quantity.value = count;
          if (totalPriceBlock) {
            totalPriceBlock.innerHTML = (count * itemPrice).toLocaleString();
          }
        }
      });
    }

    if (decreaseButton) {
      decreaseButton.addEventListener('click', (e) => {
        const count = parseInt(quantity.value, 10) - 1;
        if (count >= minQuantity) {
          quantity.value = count;
          if (totalPriceBlock) {
            totalPriceBlock.innerHTML = (count * itemPrice).toLocaleString();
          }
        }
      });
    }
  }
}

onDomChanges(() => {
  document.querySelectorAll('[data-cart-add]').forEach((link) => {
    singleBind(link, 'initialized', 'click', addToCart);
  });

  document.querySelectorAll('[data-cart-block]').forEach((cartBlock) => {
    singleInit(cartBlock, 'initialized', bindQuantityElements)
  })
});