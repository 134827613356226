<template>
  <div class="cart-result">
    <div class="cart-result__content">
      <h4 class="cart-result__title">
        Итого
      </h4>

      <ul class="cart-result__info">
        <li class="cart-result__info-item">
          <div class="cart-result__info-name text text__small">
            Итого {{ totalCount }} товаров(а) на сумму
          </div>
          <div class="cart-result__info-value">
            {{ formatPrice(itemsPrice) }} ₽
          </div>
        </li>
        <li class="cart-result__info-item">
          <div class="cart-result__info-name text text__small">
            Стоимость доставки
          </div>
          <div class="cart-result__info-value" v-if="deliveryPrice && isDelivery">
            {{ formatPrice(deliveryPrice) }} ₽
          </div>
          <div class="cart-result__info-value" v-else>
            Бесплатно
          </div>
        </li>
      </ul>

      <div class="cart-result__price">
        {{ formatPrice(resultPrice) }} ₽
      </div>

<!--      <div v-if="isPromoApply">-->
<!--        {{ formatPrice(fullPrice) }}-->
<!--      </div>-->

      <div class="cart-result__promo-wrap">
        <form action="" method="post" class="cart-result__promocode-wrap">
          <input type="text"
                 name="promocode"
                 :class="`cart-result__promocode ${promocodeHasErrors ? 'cart-result__promocode_error' : ''}`"
                 placeholder="Введите промокод"
                 data-promo-input
          >
          <a href="javascript:void(0);"
             class="cart-result__promocode-button"
             @click="promo"
             data-promo-button
          >
            <span class="cart-result__promocode-button-icon cart-result__promocode-button-icon_arrow">
              <InputArrowRight/>
            </span>
            <span class="cart-result__promocode-button-icon cart-result__promocode-button-icon_success">
              <InputSuccess/>
            </span>
          </a>

          <div class="cart-result__promocode-hint text text__twelve" data-promo-hint>
            <div class="cart-result__promocode-hint-text cart-result__promocode-hint-text_help">
              Для применения нажмите кнопку
            </div>

            <div class="cart-result__promocode-hint-text cart-result__promocode-hint-text_success">
              Промокод применён
            </div>

            <div class="cart-result__promocode-icon">
              <HintArrow/>
            </div>
          </div>

          <div class="cart-result__promocode-error text text__twelve" v-if="promocodeHasErrors">
            Данного промокода не существует или он уже не активен
          </div>
        </form>
      </div>

      <div class="cart-result__buttons-wrap">
        <button type="button" class="cart-result__button button button_secondary" @click="openFastOrder"
                data-goal-yandex-click="quick_order_basket"
                data-goal-google-click="order_basket#click"
                data-goal-vk-click="quick_order_basket"
        >
          купить в 1 клик
        </button>
        <a href="/request/recall-modal"
           class="cart-result__help-header"
           data-modal
           data-goal-yandex-click="click_order_a_call"
           data-goal-yandex-submit="success_of_the_call_order"
           data-goal-google-click="zakaz_zvonka#cliсk"
           data-goal-google-submit="zakaz_zvonka#success"
           data-goal-vk-click="click_order_a_call"
           data-goal-vk-submit="success_of_the_call_order"
        >
          <span class="cart-result__help-icon">
            <QuestionIcon/>
          </span>
          <span class="cart-result__help-title">
            Нужна помощь с заказом
          </span>
        </a>
      </div>
    </div>

    <popup
        :opened="isModalOpened('fast_order')"
        :is-processing="isProcessing('fast_order')"
        @close="closeModal('fast_order')"
    >
      <TheCartOrderFast/>
    </popup>
  </div>
</template>

<script>
import QuestionIcon from "../icons/QuestionIcon.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

import Popup from '../common/Popup.vue';
import TheCartCredit from "./TheCartCredit.vue";
import modalMixin from '../../mixins/modal';
import TheCartOrderFast from "./TheCartOrderFast.vue";
import InputArrowRight from "../icons/InputArrowRight.vue";
import InputSuccess from "../icons/InputSuccess.vue";
import HintArrow from "../icons/HintArrow.vue";

export default {
  name: "TheCartResult",
  components: {
    TheCartCredit,
    TheCartOrderFast,
    QuestionIcon,
    Popup,
    InputArrowRight,
    InputSuccess,
    HintArrow
  },
  computed: {
    ...mapState('cart', {
      'checkout': 'checkout',
      'creditData': 'creditData',
      'isPromoApply': 'isPromoApply',
    }),
    ...mapGetters('cart', {
      'totalCount': 'totalCount',
      'total': 'total',
      'deliveryPrice': 'deliveryPrice',
      'itemsPrice': 'itemsPrice',
      'fullPrice': 'fullPrice',
      'getPaymentData': 'getPaymentData',
      'promocodeHasErrors': 'promocodeHasErrors',
    }),
    ...mapGetters('order', {
      'isDelivery': 'isDelivery',
    }),

    resultPrice() {
      let total = this.itemsPrice;
      if (this.isDelivery) {
        total += this.deliveryPrice
      }

      return total;
    },
    fullPrice() {
      let total = this.fullPrice;
      if (this.isDelivery) {
        total += this.deliveryPrice
      }

      return total;
    }
  },
  methods: {
    ...mapActions({
      'applyPromo': 'cart/applyPromo',
    }),
    ...mapMutations({
      'setPromocodeErrors': 'cart/setPromocodeErrors',
    }),
    formatPrice: function(rawValue) {
      const value = parseInt(rawValue, 10);
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    openFastOrder() {
      this.openModal('fast_order');
    },
    promo() {
      const productIds = this.getProductIds();
      this.setPromocodeErrors({});
      const promoCode = document.querySelector('[data-promo-input]').value;
      this.applyPromo({code: promoCode, productIds: productIds});
      this.activeButton();
    },
    activeButton() {
      const button = document.querySelector('[data-promo-button]');
      const hint = document.querySelector('[data-promo-hint]');
      setTimeout(() => {
        if (!this.promocodeHasErrors) {
          button.classList.add('_active');
          hint.classList.add('_active');
        }
      }, 500)
    },
    getProductIds() {
      const products = this.checkout.items;
      const productIds = [];

      products.forEach((product) => {
        productIds.push(product.buyable.product.id);
      })

      return productIds
    }
  },
  mixins: [
    modalMixin,
  ],
}
</script>

<style scoped>
  .cart-result{
    tinkoff-create-button{
      display: flex;
    }
  }
</style>