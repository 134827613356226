<template>
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 6.00049H10.7419L13.4839 14.0005H22.2581L25 7.14335" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="15.5" cy="17.5005" r="1.5" fill="white"/>
    <circle cx="20.5" cy="17.5005" r="1.5" fill="white"/>
    <line x1="18" y1="5.00049" x2="18" y2="9.00049" stroke="white" stroke-width="2" stroke-linecap="round"/>
    <line x1="16" y1="7.00049" x2="20" y2="7.00049" stroke="white" stroke-width="2" stroke-linecap="round"/>
    <circle cx="17" cy="17" r="17" fill="#FF5924"/>
    <path d="M8 12H10.7419L13.4839 20H22.2581L25 13.1429" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="15.5" cy="23.5" r="1.5" fill="white"/>
    <circle cx="20.5" cy="23.5" r="1.5" fill="white"/>
    <line x1="18" y1="11" x2="18" y2="15" stroke="white" stroke-width="2" stroke-linecap="round"/>
    <line x1="16" y1="13" x2="20" y2="13" stroke="white" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: "CartAdd"
}
</script>
