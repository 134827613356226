import { onDomReady } from '../../components/dynamic/observer';
class StickyHeadBottom {
    constructor(selector) {
        this.stickyMenu = selector.querySelector('[data-drop-sticky]');
        if (this.stickyMenu) {
            this.enterSticky = selector.querySelector('[data-drop-sticky-enter]');
            this.positionSticky = this.getDistanceFromTop(this.enterSticky)
            this.eventListeners();
        }
    }

    getDistanceFromTop(element) {
        const rect = element.getBoundingClientRect();
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        return rect.bottom + scrollTop;
    }

    addSticky(){
        const header = document.querySelector('[data-drop-sticky-header]')
        const headerHeight = header.offsetHeight;

        window.addEventListener('scroll', () => {

            if(window.scrollY >= this.positionSticky){
                this.stickyMenu.style.top = `${headerHeight - 2}px` ;

            }

            if(window.scrollY <= this.positionSticky){
                this.stickyMenu.style.top = -35+'px';
            }
        })
    }

    eventListeners() {

        this.addSticky()

        window.addEventListener('resize', () => {
            location.reload()
        })

    }
}

onDomReady(() => {
    const catalogSlickSelector = document.querySelector('[data-drop-sticky-container]');
    if(catalogSlickSelector){
        new StickyHeadBottom(catalogSlickSelector);
    }
})