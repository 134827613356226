import PriceManualService from "../../../services/priceManual.service";

const PriceManualItems = {
  namespaced: true,
  state: {
    total: 0,
    tyres: [],
    successAddMessage: 'Цена сохранена, изменения будут применены при следующей синхронизации',
    successRemoveMessage: 'Цена удалена, изменения будут применены при следующей синхронизации'

  },
  getters: {},
  mutations: {
    setTyres(state, data) {
      state.tyres = data;
    },
    setCompetitorPriceChecked(state, data) {
      const competitorPrice = state.tyres
        .find((item) => item.id === data.tyreId).competitorPrices
        .find((price) => price.competitorId === data.competitorId);

      competitorPrice.checked = data.value;
    },
    setTotal(state, data) {
      if (data != null) {
        state.total = data;
      }
    },
    updateCompetitorItemPrices(state, data) {
      state.tyres.find((item) => item.id === data.tyreId).competitorPrices.push(data.newItem);
    }
  },
  actions: {
    async fetchTyres({ state, dispatch, commit, rootState }) {
      try {
        const result = await PriceManualService.fetchTyres(rootState.priceManualFilter.settings);
        commit('setTyres', result.data.tyreItems);
        commit('setTotal', result.data.totalCount);
      } catch (error) {
        console.log(error);
      }
    },
    async debounceFetchTyres({ state, commit, dispatch, rootState }) {
      try {
        this.debounceOrder = this.debounceOrder ? this.debounceOrder + 1 : 1;
        setTimeout(async () => {
          this.debounceOrder = this.debounceOrder - 1;
          if (!this.debounceOrder) {
            commit('priceManualFilter/setPage', rootState.priceManualFilter.settings.page + 1, {root: true});
            const result = await PriceManualService.fetchTyres(rootState.priceManualFilter.settings);

            if (result.data.tyreItems.length < 50) {
              commit('priceManualFilter/setTrueIsLastPage', {}, {root: true});
            }

            commit('setTyres', state.tyres.concat(result.data.tyreItems));
          }
        }, 50);
      } catch (e)  {
        console.log(e);
      }
    },
    async createPrice({ state, dispatch, commit }, data) {
      try {
        const result = await PriceManualService.createPrice(data);
        if (result.data.status === 'error') {
          alert('Произошла ошибка при создании цены, попробуйте еще раз.');
          console.log(result.data.message);
        } else {
          dispatch('notification/showSuccessNotification', state.successAddMessage, {root:true});
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    async removePrice({ state, dispatch, commit }, data) {
      try {
        const result = await PriceManualService.removePrice(data);
        if (result.data.status === 'error') {
          alert('Произошла ошибка при удалении цены, попробуйте еще раз.');
          console.log(result.data.message);
        } else {
          dispatch('notification/showSuccessNotification', state.successRemoveMessage, {root:true});
        }
      } catch (error) {
        console.log('error', error);
      }
    },
  },
}

export default PriceManualItems;