import adminNotification from './schedule-admin/notification.module';
import service from "./schedule-admin/service.module";
import date from "./schedule-admin/date.module"
import graph from "./schedule-admin/graph.module";
import ScheduleService from "../../services/schedule.service";

const scheduleAdmin = {
  namespaced: true,
  state: {
    carTypes: []
  },
  modules: {
    adminNotification,
    service,
    date,
    graph
  },
  getters: {
    isAdminLoading(state) {
      let loading = false;
      for (const key in state) {
        if (state.hasOwnProperty(key) && state[key].hasOwnProperty('loading')) {
          loading = loading || state[key].loading;
        }
      }
      return loading;
    },
  },
  actions: {
    async loadCarTypes({ commit }) {
      let carTypes = await ScheduleService.getCarTypes();
      commit('setCarTypes', carTypes.data);
    },
  },
  mutations: {
    setCarTypes(state, carTypes) {
      state.carTypes = carTypes;
    },
  }
}

export default scheduleAdmin;